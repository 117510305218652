import React from 'react';
import './StartMessage.css'
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const StartMessage = (props) => {
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  const {t} = useTranslation();
  return <div className={val_isOrange === 1 ? 'orange-header StartMessage StartMessage-orange' : 'StartMessage'} id={'StartMessage'}>
  <span className={val_isOrange === 1 ? 'orange-header bigText' : 'bigText'} style ={{color: props.rdvState.company?.color_scheme.secondary_color}}>{t('welcome_to')} <strong>{props.rdvState.company?.company}</strong></span><br/>
    <span className={val_isOrange === 1 ? 'orange-header' : ''} style ={{color: props.rdvState.company?.color_scheme.secondary_color}}>{t('startMsg')}</span>
  </div>;
};

export default connect(mapStateToProps)(StartMessage);
