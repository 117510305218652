import React, {useEffect} from 'react';
import './MainSection.css'
import bg from '../../assets/background.jpg'
import {Route, Router, Switch} from "react-router-dom";
import StartScreen from "../StartScreen";
import RdvStartScreen from "../RdvStartScreen";
import DownScreen from "../DownScreen";
import RedirectService from "../RedirectService";
import {Container} from "react-bootstrap";
import OperationSelection from "../../stepsComponents/OperationSelection";
import ServiceSelection from "../../stepsComponents/ServiceSelection";
import RdvServiceSelection from "../../stepsComponents/RdvServiceSelection";
import InfosForm from "../../stepsComponents/InfosForm";
import RdvGroupInfosForm from "../../stepsComponents/RdvGroupInfosForm";
import Badlink from "../../stepsComponents/Badlink";
import Failure from "../../stepsComponents/Failure";
import Success from "../../stepsComponents/Success";
import RdvInfosForm from "../../stepsComponents/RdvInfosForm";
import ConfirmationScreen from "../../stepsComponents/ConfirmationScreen";
import SentScreen from "../../stepsComponents/SentScreen";
import AgentsScreen from "../../stepsComponents/AgentsScreen";
import Availability from "../../stepsComponents/Availability";
import RdvGroupAvailability from "../../stepsComponents/RdvGroupAvailability";
import RdvAvailability from "../../stepsComponents/RdvAvailability";
import RdvData from "../../stepsComponents/RdvData";
import RdvExist from "../../stepsComponents/RdvExist";
import RdvExistCheck from '../../stepsComponents/RdvExistCheck';
import RdvLoad from "../../stepsComponents/RdvLoad";
import RdvFetchData from "../../stepsComponents/RdvFetchData";
import {createBrowserHistory} from "history";
import {connect} from "react-redux";

import {useTranslation} from "react-i18next";
import i18next from "i18next";


const history = createBrowserHistory();
const mapStateToProps = (state) => {
  return {
      rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const MainSection = (props) => {
  let setBg = bg;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    let pathName = window.location.pathname.split('/')[2];
    if (!['', 'operation'].includes(pathName)) {
      setBg = null;
    } else setBg = bg
  })


  const {t, i18n} = useTranslation();

  useEffect(() => {
    let apikey = window.location.pathname.split('/')[1];
    // console.log("Old lang", localStorage.getItem("rdv_lang"));
    if (!localStorage.getItem("rdv_lang")){
      console.log("Language", props?.rdvState?.company?.Language);
      let is_ambassade_apikey = apikey === "d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" || apikey === "cUJYR0dITTFYZUNVSHpLV3U2UzFOQT09"
      const lang = is_ambassade_apikey ? 'fr' : props?.rdvState?.company?.Language ? props?.rdvState?.company?.Language : 'en';
      localStorage.setItem("rdv_lang", is_ambassade_apikey ? 'fr' : lang);
      i18n.changeLanguage(lang)
    }

// console.log("New lang", localStorage.getItem("rdv_lang"));
}, [props?.rdvState?.company?.Language]);


  let setBGOrange = null;
  useEffect(() => {
    //console.log(props.rdvState.company?.color_scheme.isOrange)
  }, [props.rdvState.company?.color_scheme.isOrange])


  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

  var currentLocation = window.location.pathname;
  var parts = currentLocation.split('/');
  var lastSegment = parts.pop() || parts.pop(); 

  if(val_isOrange == 1){
  var classe_names = 'MainSection deleteMarginTop ';
  }
  
  if(val_isOrange == 1 && lastSegment == 'operation'){
    /*classe_names = classe_names + 'brGrey';*/
  }


  //<DownScreen/>

  return <div className={val_isOrange == 1 ? classe_names : 'MainSection'} id={'MainSection'}
  
  style={val_isOrange === 1 ? {backgroundColor: '#FFF'} : {backgroundColor: '#F4F5F6', backgroundImage: 'url(' + setBg + ')'}}
  >
    <Container className={val_isOrange == 1 ? 'MainSection-orange' : null}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <Route exact path="/backdoor">
            backdoor
          </Route>
          <Route exact path="/:apiKey/rdv-exist/:id">
            <RdvExist/>
          </Route>
          <Route exact path="/:apiKey/rdv-exist-check">
            <RdvExistCheck/>
          </Route>
          <Route exact path="/:apiKey/rdv-start">
            <RdvStartScreen/>
          </Route>
          <Route exact path="/:apiKey/badlink">
            <Badlink/>
          </Route>
          <Route exact path="/:apiKey/failure">
            <Failure/>
          </Route>
          <Route exact path="/:apiKey/success">
            <Success/>
          </Route>
          <Route exact path="/:apiKey/rdv-load">
            <RdvLoad/>
          </Route>
          <Route exact path="/:apiKey/rdv-availability">
            <RdvAvailability/>
          </Route>
          <Route exact path="/:apiKey/rdv-group-availability">
            <RdvGroupAvailability/>
          </Route>
          <Route exact path="/:apiKey/rdv-fetch/:id">
            <RdvFetchData/>
          </Route>
          <Route exact path="/:apiKey/rdv/:id">
            <RdvData/>
          </Route>
          <Route exact path="/:apiKey/availability">
            <Availability/>
          </Route>
          <Route exact path="/:apiKey/informations">
            <InfosForm/>
          </Route>
          <Route exact path="/:apiKey/rdv-group-informations">
            <RdvGroupInfosForm/>
          </Route>
          <Route exact path="/:apiKey/rdv-informations">
            <RdvInfosForm/>
          </Route>
          <Route exact path="/:apiKey/agents">
            <AgentsScreen/>
          </Route>
          <Route exact path="/:apiKey/success">
            <ConfirmationScreen/>
          </Route>
          <Route exact path="/:apiKey/sent">
            <SentScreen/>
          </Route>
          <Route exact path="/:apiKey/service">
            <ServiceSelection/>
          </Route>
          <Route exact path="/:apiKey/rdv-service">
            <RdvServiceSelection/>
          </Route>
          <Route exact path="/:apiKey/operation">
            <OperationSelection/>
          </Route>
          <Route exact path="/:apiKey/">
            <StartScreen/>
          </Route>
          <Route exact path="/:apiKey/:operation/:officeId">
            <RedirectService/>
          </Route>
          <Route exact path="/noroute">
            <div className={'noroute'}>
              <h1>404</h1>
            </div>
          </Route>
          <Route exact path="*">
            <div className={'noroute'}>
              <h1>404</h1>
            </div>
          </Route>
        </Switch>
      </Router>
    </Container>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
