import React, {useEffect, useState} from 'react';
import './RdvGroupAvailability.css'
import {connect, useDispatch} from "react-redux";
import {Col, Row} from "react-bootstrap";
//import backIcon from "../../assets/back.svg";
import {useHistory, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrwSvg from '../../assets/next.svg';
import arrwBlackSvg from '../../assets/next_black.svg'
import RdvGroupTimeSlot from "../RdvGroupTimeSlot";
import DaySlot from "../DaySlot";
import * as rdvActions from "../../actions";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAvailabilities: (b, s, a, n) => dispatch(rdvActions.getAvailabilities(b, s, a, n))
    }
}

const RdvGroupAvailability = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();
    const {apiKey} = useParams();
    const goBack = () => {
        if(props.rdvState.companyCanByassJoinQueuePage) {
            dispatch({type: 'WAITING_AVAILABILITY', payload: false});
            dispatch({type: 'AVAILABILITIES_FETCH', payload: false});
        }
        if (props.rdvState.company?.AllowSelectAgents == 0){
            history.push('/' + sessionStorage.getItem('rdv_apikey') + "/rdv-service")
        }
        else{
            history.push('/' + sessionStorage.getItem('rdv_apikey') + "/agents")
        }
    }
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const [hideRdvGroupAvailabilityEmptyMsg, setHideRdvGroupAvailabilityEmptyMsg] = useState(true);

    const [disabledPreviousBtn, setDisabledPreviousBtn] = useState(false);
    const [disabledNextBtn, setDisabledNextBtn] = useState(false);

    const [currentSlideIndex, setCurrentSlideIndex] = useState(null);
    const slideSettings = {
        beforeChange: (oldIndex, newIndex) => {
            let lastSlidesIndex = props.rdvState.availabilities.length - 3

            if( props.rdvState.companyCanByassJoinQueuePage ) {
                if(newIndex == 0) {
                    setDisabledPreviousBtn(true)
                    setDisabledNextBtn(false)
                }
    
                if(newIndex == lastSlidesIndex) {
                    setDisabledNextBtn(true)
                    setDisabledPreviousBtn(false)
                }
    
                if(newIndex != 0 && newIndex < lastSlidesIndex) {
                    setDisabledNextBtn(false)
                    setDisabledPreviousBtn(false)
                }
            }

            setCurrentSlideIndex(newIndex);
        },
    };


    const prevSlide = () => {
        nav1.slickPrev();
        /*let eD = startDate;
        setEnddate(eD)
        let sD = moment(startDate,'YYYY-MM-DD H-m-s').add(-4,'days').format('YYYY-MM-DD H-m-s')
        setStartdate(sD)
        console.log(startDate)
        console.log(endDate)*/
    }
    const nextSlide = () => {
        nav1.slickNext()
        /*let sD = endDate;
        setStartdate(sD)
        let eD = moment(startDate,'YYYY-MM-DD H-m-s').add(4,'days').format('YYYY-MM-DD H-m-s')
        setEnddate(eD)
        console.log(startDate)
        console.log(endDate)*/
    }
    let arrwUse = arrwSvg;
    useEffect(() => {
        if (props.rdvState.operation === 'appointment') {
            dispatch({type: 'IS_LOADING', payload: true})
            var agent = props.rdvState?.agent == null || props.rdvState?.agent == undefined ? {agent : "0"} : props.rdvState?.agent;
            props.getAvailabilities(props.rdvState.branch, props.rdvState.service, agent, props.rdvState.userNumber)
        } else if (props.rdvState.operation === 'currentqueue' && props.rdvState.service !== null) {
            dispatch({type: 'IS_LOADING', payload: true})
        } else goBack()
        
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        arrwUse = props.rdvState.company?.color_scheme.secondary_color ? arrwBlackSvg : arrwSvg;
    }, [props.rdvState.company?.color_scheme.secondary_color])
    arrwUse = props.rdvState.company?.color_scheme.secondary_color ? arrwBlackSvg : arrwSvg;

    useEffect(() => {
        if (props.rdvState.availabilitiesRequestOk == true && props.rdvState.availabilities.length == 0) {
            setHideRdvGroupAvailabilityEmptyMsg(false)
            setDisabledPreviousBtn(true)
            setDisabledNextBtn(true)
        }

        if (props.rdvState.availabilitiesRequestOk == true && props.rdvState.availabilities.length == 1) {
            setDisabledPreviousBtn(true)
            setDisabledNextBtn(true)
        }

        if (props.rdvState.availabilitiesRequestOk == true && props.rdvState.availabilities.length > 0 && props.rdvState.companyCanByassJoinQueuePage) {
            setDisabledPreviousBtn(true)
        }

    }, [props.rdvState.availabilitiesRequestOk, props.rdvState.availabilities])


    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
   const BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" 
    stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)
  var NextIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162"
                               viewBox="0 0 16 12.162">
    <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
      <path id="Path_891" data-name="Path 891" d="M5,0,0,4.674,5,9.335" transform="translate(14 9.335) rotate(180)" fill="none" 
      stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(13.809 4.774) rotate(180)" fill="none"
            stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>)

const ButtonBackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" 
    stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)






    return <div className={'RdvGroupAvailability'} id={'RdvGroupAvailability'}>




        <Row>
            <Col className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>

                        <button hidden={props.rdvState?.back_button == 1 } className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><ButtonBackIcon/>&nbsp;{t('back')}
                        </button>
            </Col>
        </Row>




        <Row className={'serviceBox'}>
            <Col>
                <Row>
                    <Col sm={7} className={'pt-4'}>
                        { props.rdvState?.hadOldRdv && <span className={val_isOrange === 1 ? 'orange-header clickMessage' : 'clickMessage'}>{t('hello')} {props.rdvState.rdvData?.firstname} {props.rdvState.rdvData?.lastname}</span>}
                        { props.rdvState?.hadOldRdv && <br/>}
                        <span className={val_isOrange === 1 ? 'orange-header clickMessage' : 'clickMessage'}>{t('our_avails')} {props.rdvState.service?.translate ? props.rdvState.service?.translate[i18next.language] : props.rdvState.service?.name ? props.rdvState.service?.name[i18next.language] : ''}</span><br/>
                        <span className={val_isOrange === 1 ? 'orange-body subclickMessage' : 'subclickMessage'}>{t('pick_date_time')}</span>
                    </Col>
                </Row>
                <Row>
                    <Col className={'p-3'}>
                        <Row>



                            <Col sm={3}>
                                <div className={'mob d-flex  flex-wrap justify-content-between d-sm-none d-md-none d-lg-none'}>
                            {/*}{*/}
                            <div className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1' : 'SlideArrows mb-3'} ${disabledPreviousBtn == true ? 'disabled_btn' : ''}`}
                                        style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                        onClick={prevSlide}
                                    ><BackIcon/>  {t('previous')}
                                    </div>
                                    <div
                                        style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                        className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1' : 'SlideArrows'} ${disabledNextBtn == true ? 'disabled_btn' : ''}`}
                                        onClick={nextSlide}
                                    >&nbsp;&nbsp;{t('next')} <NextIcon/></div>
                            
                                </div>
                                    
                                <div
                                    style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                    className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1 hideDesktopbtn' : 'SlideArrows hideDesktopbtn'} ${disabledPreviousBtn == true ? 'disabled_btn' : ''}`}
                                    onClick={prevSlide}
                                ><BackIcon/>   {t('previous')}
                                </div>
                            </Col>

                            <Col sm={6}></Col>

                            <Col sm={3}>
                                <div
                                    style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                    className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1 hideDesktopbtn' : 'SlideArrows hideDesktopbtn'} ${disabledNextBtn == true ? 'disabled_btn' : ''}`}
                                    onClick={nextSlide}
                                >{t('next')} <NextIcon/></div>
                            </Col>
                        </Row>


                        <Row hidden={hideRdvGroupAvailabilityEmptyMsg} className='text-center'>
                            <Col sm={11} className='mt-5'>
                                <h5>{t('availabilities_empty_msg')}</h5>
                            </Col>
                        </Row>


                        <br/>
                        <Row>
                            <Col sm={1} ></Col>
                            <Col sm={10} className={val_isOrange === 1 ? 'orange-plage marge':'marge'} style={{maxWidth:'60%',marginLeft:'auto',marginRight:'auto'}} >
                                <Row>
                                    <Col>
                                        <Slider
                                            {...slideSettings}
                                            asNavFor={nav2}
                                            focusOnSelect={false}
                                            ref={slider => setNav1(slider)}
                                            infinite={false}
                                            slidesToShow={3}
                                            slidesToScroll={1}
                                            responsive={[{
                                                breakpoint: 1024,
                                                settings: {
                                                    slidesToShow: 3,
                                                    slidesToScroll: 3,
                                                    infinite: false,
                                                }
                                            },
                                                {
                                                    breakpoint: 768,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 2,
                                                        initialSlide: 2,
                                                        infinite: false,
                                                    }
                                                },
                                                {
                                                    breakpoint: 480,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        infinite: false,
                                                    }
                                                }]}
                                        >
                                            {props.rdvState.availabilities?.map((a, aa) => (
                                                <div key={aa}>
                                                    <DaySlot day={a}/>
                                                </div>
                                            ))}

                                        </Slider>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Slider
                                            {...slideSettings}
                                            asNavFor={nav1}
                                            ref={slider => setNav2(slider)}
                                            focusOnSelect={false}
                                            infinite={false}
                                            slidesToShow={3}
                                            slidesToScroll={1}
                                            responsive={[{
                                                breakpoint: 1024,
                                                settings: {
                                                    slidesToShow: 3,
                                                    slidesToScroll: 3,
                                                    infinite: false,
                                                }
                                            },
                                                {
                                                    breakpoint: 768,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 2,
                                                        initialSlide: 2,
                                                        infinite: false,
                                                    }
                                                },
                                                {
                                                    breakpoint: 480,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        infinite: false,
                                                    }
                                                }]}
                                        >
                                            {props.rdvState.availabilities?.map((a, aa) => (
                                                <div key={aa} className={'daySlots'} style={{borderRadius: val_isOrange === 1 ? 0:null}}>
                                                    {a.hours?.map((s, ss) => (<RdvGroupTimeSlot key={ss} day={a} slot={s}/>))}
                                                </div>
                                            ))}
                                        </Slider>
                                    </Col>
                                </Row>
                            </Col>
                        
                            <Col sm={1} ></Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvGroupAvailability);
