import React, {useEffect} from 'react';
import './TopNavSection.css'
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import en from '../../assets/uk.svg'
import fr from '../../assets/fr.svg'
import call from '../../assets/call.svg'
import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import {connect, useDispatch} from "react-redux";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

let appLogo;
let primaryColor;
const TopNavSection = (props) => {
  let dispatch = useDispatch();

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem("rdv_lang", lang);
    dispatch({type: 'SET_CURRENT_LANG', payload: lang});
  }
  useEffect(() => {
    // appLogo = props.rdvState.company ? props.rdvState.company.companylogo : '/favicon.png'
    appLogo = props.rdvState.company ? props?.rdvState?.company?.companylogo : null;
    primaryColor = props.rdvState.company?.color_scheme.primary_color ? props.rdvState.company?.color_scheme.primary_color : "#1689E1"
  })


  useEffect(() => {
    console.log(props?.rdvState?.company?.companylogo);
    appLogo = props.rdvState.company ? props?.rdvState?.company?.companylogo : null;
  }, [props?.rdvState?.company?.companylogo]);

  const {t, i18n} = useTranslation();

  let is_ambassade_apikey = props.rdvState.company?.apikey === "d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" || props.rdvState.company?.apikey === "cUJYR0dITTFYZUNVSHpLV3U2UzFOQT09"

  const defaultLang = i18next.language === 'fr' ? (
      <span className={'ml-3'}><img alt={''} src={fr}className={'mr-2'}/> Français</span>) : i18next.language === 'en' ? (
      <span className={'ml-3'}><img alt={''} src={en} className={'mr-2'}/> English</span>) : '';
  const defaultLangSmall = i18next.language === 'fr' ? (
      <span className={'ml-3'}><img alt={''} src={fr} className={'mr-2'}/></span>) : i18next.language === 'en' ? (
      <span className={'ml-3'}><img alt={''} src={en} className={'mr-2'}/></span>) : '';
  const CallIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" className={'phoneIcon'} width="9.612" height="9.632" viewBox="0 0 9.612 9.632">
  <g id="communications" transform="translate(-0.539 0)">
      <g id="Group_3483" data-name="Group 3483" transform="translate(0.539 0)">
          <path id="Path_4096" data-name="Path 4096"
                d="M9.9,7.069,8.555,5.725a.894.894,0,0,0-1.488.336.915.915,0,0,1-1.056.576,4.145,4.145,0,0,1-2.5-2.5A.87.87,0,0,1,4.09,3.084.894.894,0,0,0,4.426,1.6L3.082.252a.959.959,0,0,0-1.3,0l-.912.912c-.912.96.1,3.5,2.352,5.761s4.8,3.313,5.761,2.352L9.9,8.365A.959.959,0,0,0,9.9,7.069Z"
                transform="translate(-0.539 0)" fill={disableLink_sonatel? '#666666' : props.rdvState.company?.color_scheme.primary_color ? props.rdvState.company?.color_scheme.isOrange === 1 ? 'white' : props.rdvState.company?.color_scheme.primary_color : "#0089e1" }/>
      </g>
  </g>
</svg>)
let OLUX_apikey = "OVEvL2R1eTJqREpWMisrNmFqdDlOdz09"

let disableLink = props.rdvState.company?.apikey === "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09";
let disableLink_sonatel = props.rdvState.company?.apikey === "amdGdnJsTnRUbVh5aERlWUFLWFFnQT09";
let telLinkClass = props.rdvState.operation ? 'navCall vis' : 'navCall';
telLinkClass = telLinkClass + (disableLink? ' disable-link-1':null)

let telLinkClass_1 = props.rdvState.company?.color_scheme.isOrange === 1 ? 'navCallOrange' : 'navCall';
telLinkClass_1 = telLinkClass_1 + (disableLink? ' disable-link-1': disableLink_sonatel? ' disable-link-2': null)

let dark_header = props.rdvState.company?.apikey === "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" || props.rdvState.company?.apikey === "amdGdnJsTnRUbVh5aERlWUFLWFFnQT09";
//let remove_navNewRdv = props.rdvState.company?.apikey !== "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09";

  return <div className={'TopNavSection'} id={'TopNavSection'}>
    <Navbar className={props.rdvState.company?.color_scheme.isOrange == 1 || dark_header ? 'nav-dark' : 'nav-light'} expand="lg" fixed={'top'} style={{backgroundColor: 'black'}}>
      <Container>
        {(props.rdvState.companyCanByassJoinQueuePage) ? <Navbar.Brand className="ambassadeLogo" href={"/" + sessionStorage.getItem('rdv_apikey')}>
          {appLogo && <img className="ambassadeImg" src={appLogo} alt={''}/>}
        </Navbar.Brand> : (<Navbar.Brand href={"/" + props.rdvState.company?.apikey ? props.rdvState.company?.apikey : sessionStorage.getItem('rdv_apikey')}>
          {appLogo && <img src={appLogo} alt={''} height={49} width={49}/>}
        </Navbar.Brand>)}
        
        <div className={'d-flex align-items-center d-nd-block respTop d-lg-none'}>

          {/* start Mobile */}
          {(props.rdvState.company?.apikey !==  OLUX_apikey && !is_ambassade_apikey) &&
          <Nav.Link hidden={!props.rdvState.company?.Phone || props.rdvState.company?.apikey === OLUX_apikey || is_ambassade_apikey} href={(disableLink || disableLink_sonatel)? "#" : "tel://" + props.rdvState.company?.Phone}

                    className={telLinkClass} style={(disableLink || disableLink_sonatel)? {cusor:'not-allowed'}:null}><CallIcon />
            <span style={!disableLink_sonatel? {color: primaryColor, borderColor: primaryColor}:{color: '#666666'}}>{t('call_line')}</span>
          </Nav.Link>}
          {/* end Mobile */}

          {props.rdvState.operation && props.rdvState.company?.color_scheme.isOrange === 0 && !is_ambassade_apikey && !dark_header ? (
              <Nav.Link href={'/' + sessionStorage.getItem('rdv_apikey') + "/operation"}
              className={'navNewRdv'}>{t('new_reservation')}</Nav.Link>) : ('')}
          <NavDropdown title={defaultLangSmall} id={"lang-dropdown"} className={'lang-dropdown'}>
          <NavDropdown.Item onClick={() => {
              handleLangChange('fr')
            }}>
              <span><img src={fr} alt={''} height={13} width={13} className={'mr-2'}/></span>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => {
              handleLangChange('en')
            }}>
              <span><img src={en} alt={''} height={13} width={13} className={'mr-2'}/></span>
            </NavDropdown.Item>
            
          </NavDropdown>
        </div>
        {/*<Navbar.Toggle aria-controls="basic-navbar-nav" >{defaultLangSmall}</Navbar.Toggle>*/}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={"ml-auto align-items-center"}>

            {/* start Desktop */}
            <Nav.Link hidden={!props.rdvState.company?.Phone || props.rdvState.company?.apikey === OLUX_apikey  || is_ambassade_apikey} href={"tel://" + props.rdvState.company?.Phone}
                      className={telLinkClass_1} style={{color: primaryColor, cusor: disableLink? 'not-allowed':null}}><CallIcon /> <span className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''}> {t('call_line')}</span>
            </Nav.Link>
            {/* end Desktop */}            
            {props.rdvState.operation && props.rdvState.company?.color_scheme.isOrange === 0 && !is_ambassade_apikey && !dark_header ? (
                <Nav.Link  href={'/' + props.rdvState.company?.apikey + "/operation"}
                          className={'navNewRdv'}>{t('new_reservation')}</Nav.Link>) : ('')}
            <NavDropdown title={defaultLang} id={"lang-dropdown"} className={'lang-dropdown'}>
              <NavDropdown.Item onClick={() => {
                handleLangChange('en')
              }}>
                <span className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''}><img src={en} alt={''} height={13} width={13} className={'mr-2'}/> ENGLISH</span>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                handleLangChange('fr')
              }}>
                <span className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''}><img src={fr} alt={''} height={13} width={13} className={'mr-2'}/> FRANÇAIS</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>;

};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavSection);
