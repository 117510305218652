import React, {useEffect} from 'react';
import './RdvData.css'
import {useParams} from "react-router-dom";
import confirmSvg from "../../assets/confirm.svg";
import {Col, Row} from "react-bootstrap";
import NameInitials from "../NameInitials";
import serviceSvg from "../../assets/service.svg";
import dateSvg from "../../assets/date.svg";
import timeSvg from "../../assets/time.svg";
import branchSvg from "../../assets/branch.svg";
import locationSvg from "../../assets/slocation.svg";
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import * as rdvActions from "../../actions";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRdvData: e => dispatch(rdvActions.getRdvData(e))
  }
}
const RdvData = (props) => {
  let {id} = useParams();
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const {apiKey} = useParams();
  const goHome = () => {
    //history.push('/')
    window.location.href = '/' + props.rdvState.company?.apikey
  }
  useEffect(() => {
    if (id) {
      dispatch({type: 'IS_LOADING', payload: true})
      dispatch({type: 'GET_RDV_DATA', payload: true})
      props.getRdvData(id)
    } else goHome()

    window.scrollTo(0, 0)
  }, [])

  return <div className={'RdvData'} id={'RdvData'}>
    <div hidden={props.rdvState.rdvData === null}>
      <div className={'text-center'}>
        <img src={confirmSvg} align={''}/>
      </div>
      <div className={'confirmLabel text-center m-4'}>{t('reservation_status')} {props.rdvState.rdvData?.status}</div>
      <div></div>
      <Row className={'confirmLocation'}>
        <Col xs={12}>
          <div className={'summary'}>
            {t('summary')}
          </div>
        </Col>
        <Col md={6} hidden={props.rdvState.company?.AllowSelectAgents == 0} className={'agencyImage mb-3'}>
          <div className={'d-flex mb-2'}>
            <div className={'pr-3'}>
              <NameInitials size={32} fullname={props.rdvState.rdvData?.user_fullname} data={null}/>
            </div>
            <div>
              <div className={'confirmText'}>{t('agent')}</div>
              <div className={'confirmValue'}>
                {props.rdvState.rdvData?.user_fullname}
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className={'agencyImage mb-3'}>
          <div className={'d-flex mb-2'}>
            <div className={'pr-3'}><img src={serviceSvg} alt={''}/></div>
            <div>
              <div className={'confirmText'}>{t('service')}</div>
              <div className={'confirmValue'}>
                {props.rdvState.rdvData?.service_name}
              </div>
            </div>
          </div>
        </Col>

        <Col md={6} className={'agencyImage mb-3'}>
          <div className={'d-flex mb-2'}>
            <div className={'pr-3'}><img src={dateSvg} alt={''}/></div>
            <div>
              <div className={'confirmText'}>{t('date')}</div>
              <div className={'confirmValue'}>
                {props.rdvState.rdvData?.bookdate}
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className={'agencyImage mb-3'}>
          <div className={'d-flex mb-2'}>
            <div className={'pr-3'}><img src={timeSvg} alt={''}/></div>
            <div>
              <div className={'confirmText'}>{t('time')}</div>
              <div className={'confirmValue'}>
                {props.rdvState.rdvData?.bookdate}
              </div>
            </div>
          </div>
        </Col>

        <Col md={6} className={'agencyImage mb-3'}>
          <div className={'d-flex mb-2'}>
            <div className={'pr-3'}><img src={branchSvg} alt={''}/></div>
            <div>
              <div className={'confirmText'}>{t('branch')}</div>
              <div className={'confirmValue'}>
                {props.rdvState.rdvData?.office_name}
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className={'agencyImage mb-3'}>
          <div className={'d-flex mb-2'}>
            <div className={'pr-3'}><img src={locationSvg} alt={''}/></div>
            <div>
              <div className={'confirmText'}>{t('address')}</div>
              <div className={'confirmValue'}>
                {props.rdvState.branch?.address}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={'text-center'}>
        <button style={{color: props.rdvState.company?.color_scheme.secondary_color, backgroundColor: props.rdvState.company?.color_scheme.primary_color, borderColor: props.rdvState.company?.color_scheme.primary_color}} className={'buttonActive next'} onClick={goHome}>{t('return_home')}</button>
      </div>

    </div>

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvData);
