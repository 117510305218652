import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import * as rdvActions from "../../actions";
import './RdvExist.css';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserRdvData: e => dispatch(rdvActions.getUserRdvData(e))
  }
}

const RdvExist = (props) => {
  const [userRdvData, setUserRdvData] = useState(false);

  let history = useHistory();
  let dispatch = useDispatch();
  const {apiKey, id} = useParams();
  const externalRdvKey = id

  const goBack = () => {
    history.push('/' + apiKey)
  }

  useEffect(() => {
    dispatch({type: 'IS_LOADING', payload: true});
    sessionStorage.setItem('rdv_apikey', apiKey)
    //dispatch(rdvActions.getCompany(apiKey))
    props.getUserRdvData(externalRdvKey)
    setUserRdvData(true)
    //localStorage.setItem('rdv_prev_op', operation);
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    if(props.rdvState.branch !== null && props.rdvState.service !== null && props.rdvState.company !== null) {
      dispatch({type: 'HAD_OLD_RDV', payload: true});
      dispatch({type: 'SET_EXTERNAL_RDV_KEY', payload: externalRdvKey});
      dispatch({type: 'WAITING_AVAILABILITY', payload: true});
      history.push('/' + apiKey + '/availability')
    }
  }, [props.rdvState.branch, props.rdvState.service, props.rdvState.company]);

  return <></>


};

export default connect(mapStateToProps, mapDispatchToProps)(RdvExist);
