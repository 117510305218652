import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import * as rdvActions from "../../actions";
import './Badlink.css';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const Badlink = (props) => {

  let history = useHistory();
  let dispatch = useDispatch();
  const {apiKey} = useParams();

  useEffect(() => {
    dispatch({type: 'SET_PAYMENT_BADLINK', payload: true});
    dispatch({type: 'IS_LOADING', payload: true});
  }, [])


  useEffect(() => {
    if(props.rdvState.paymentBadlink) {
      history.push('/' + apiKey) //+ '/rdv-start'
    }
  }, [props.rdvState.paymentBadlink]);

  return <></>


};

export default connect(mapStateToProps, mapDispatchToProps)(Badlink);
