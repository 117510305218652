//import React from 'react';
import React, {useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import LoadingAnimation from "./components/LoadingAnimation";
import AppShell from "./components/AppShell";
import {connect} from "react-redux";
import 'moment/locale/fr'
import 'moment/locale/en-gb'

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}


function App(props) {
    return (
        <div className={'App'}>
            <AppShell/>
            <LoadingAnimation isLoading={props.rdvState.isLoading}/>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
