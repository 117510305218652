import React from 'react';
import './BranchService.css'
import clockSvg from '../../assets/clock.svg'
import {useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const BranchService = (props) => {
  const {t} = useTranslation();
  let history = useHistory();
  let dispatch = useDispatch();
  const handleSelectService = () => {
    dispatch({type: 'SET_SERVICE', payload: props.service})
    if (props.rdvState.operation === "appointment") {
      if (props.rdvState.company?.AllowSelectAgents != 0){
        history.push('/' + props.rdvState.company?.apikey + '/agents')
      }else{
        if(props.rdvState.companyCanByassJoinQueuePage) {
          dispatch({type: 'WAITING_AVAILABILITY', payload: true});
        }
        history.push('/' + props.rdvState.company?.apikey + '/availability')
      }
    } else history.push('/' + props.rdvState.company?.apikey + '/informations')

  }
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  const StarIcon = (p) => (<svg xmlns="http://www.w3.org/2000/svg" width="41" height="42" viewBox="0 0 41 42">
    <g id="Group_3442" data-name="Group 3442" transform="translate(-0.189)">
      <ellipse id="Ellipse_350" data-name="Ellipse 350" cx="20.5" cy="21" rx="20.5" ry="21" transform="translate(0.189)"
               fill={val_isOrange === 1 ? props.rdvState.company?.color_scheme.primary_color : props.service.colorcode + '1A'}/>
      <path id="star"
            d="M14.693,5.881a.784.784,0,0,0-.673-.543l-4.253-.39L8.086.976a.78.78,0,0,0-1.441,0L4.965,4.948l-4.253.39a.786.786,0,0,0-.673.543.8.8,0,0,0,.228.84L3.481,9.566,2.533,13.78a.794.794,0,0,0,.3.816.775.775,0,0,0,.861.038l3.667-2.213,3.666,2.213a.777.777,0,0,0,.861-.038.794.794,0,0,0,.3-.816L11.25,9.566l3.215-2.845A.8.8,0,0,0,14.693,5.881Zm-7.257,6.5"
            transform="translate(13.201 13.215)" fill={val_isOrange === 1 ? '#FFF' : props.service.colorcode}/>
    </g>
  </svg>)
  return <div onClick={handleSelectService} className={val_isOrange === 1 ? 'orange-body BranchServiceOrange' : 'BranchService'} id={'BranchService'} style={val_isOrange === 1 ? {backgroundColor: '#FFF', border: '1px solid #000'} : {
    background: props.service.colorcode + '1A 0% 0% no-repeat padding-box',
    border: '1px solid ' + props.service.colorcode
  }}>



    <div className={props.rdvState.companyCanByassJoinQueuePage ? 'serviceAmbassade' : val_isOrange === 1 ? 'orange-body serviceName' : 'serviceName'}>
      {props.service?.translate ? props.service?.translate[i18next.language] : props.service?.name ? props.service?.name[i18next.language] : ''}
    </div>
    <div className='serviceAmbassade' hidden={!props.rdvState.companyCanByassJoinQueuePage} ><p>{t('service_ambassade_text')}</p></div>
    <div hidden={props.rdvState.operation === 'appointment'}>
      <div className={'d-flex align-items-center justify-content-between'}>
        <div className={val_isOrange === 1 ? 'orange-body queue' : 'queue'}>{t('waiting_people')} {props.service.WaitingPeople}</div>
        <div className={val_isOrange === 1 ? 'orange-body minute' : 'minute'}><img src={clockSvg} alt={""}/>{props.service.WaitingTime} {t('min')}</div>
      </div>
    </div>

  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchService);
