import React, {useEffect} from 'react';
import './NameInitials.css'

/*
const mapStateToProps = (state) => {
  return {
      rdvState: state
  }
}*/

const NameInitials = (props) => {

  //console.log(props.rdvState.company?.color_scheme.isOrange)


  //var val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  let val_isOrange = 1;
  return <div className={'NameInitials'} id={'NameInitials'}>
    {props.data !== null ? (
        <div className={val_isOrange === 1 ? 'orange-initialsCircle initialsCircle' : 'initialsCircle'}
             style={{width: props.size ? props.size : 75, height: props.size ? props.size : 75}}>
          <span>{props.data.firstname[0].toUpperCase()}{props.data.lastname[0].toUpperCase()}</span>
        </div>
    ) : (
        <div className={val_isOrange === 1 ? 'orange-initialsCircle initialsCircle' : 'initialsCircle'}
             style={{width: props.size ? props.size : 75, height: props.size ? props.size : 75}}>
          <span>{props.fullname ? props.fullname[0].toUpperCase() + '' + props.fullname.split(' ')[1][0].toUpperCase() : ''}</span>
        </div>
    )}


  </div>;
};

export default NameInitials;
