import React, {useEffect, useState} from 'react';
import './RdvAvailability.css'
import {connect, useDispatch} from "react-redux";
import {Col, Row, Button, Badge} from "react-bootstrap";
import CloseButton from 'react-bootstrap/CloseButton';
//import backIcon from "../../assets/back.svg";
import {useHistory, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrwSvg from '../../assets/next.svg';
import arrwBlackSvg from '../../assets/next_black.svg'
import RdvTimeSlot from "../RdvTimeSlot";
import DaySlot from "../DaySlot";
import * as rdvActions from "../../actions";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAvailabilities: (b, s, a) => dispatch(rdvActions.getAvailabilities(b, s, a))
    }
}

const RdvAvailability = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();
    const {apiKey} = useParams();
    const goBack = () => {
        if(props.rdvState.companyCanByassJoinQueuePage) {
            dispatch({type: 'WAITING_AVAILABILITY', payload: false});
            dispatch({type: 'AVAILABILITIES_FETCH', payload: false});
            dispatch({type: 'SET_TIME_SLOTS_TAKE', payload: []})
            dispatch({type: 'SET_AVAILABILITIES', payload: []})
            dispatch({type: 'SET_SLOTS_FULLDATE', payload: []});
            dispatch({type: 'SET_TIME_SLOT_TAKE_COUNT', payload: 0})
            dispatch({type: 'SET_CAN_TAKE_TIME_SLOT', payload: true})
        }
        if (props.rdvState.company?.AllowSelectAgents == 0){
            history.push('/' + sessionStorage.getItem('rdv_apikey') + "/rdv-service")
        }
        else{
            history.push('/' + sessionStorage.getItem('rdv_apikey') + "/agents")
        }
    }

    const [timeSlotIndex, setTimeSlotIndex] = useState(0);
    const [canTakeTimeSlot, setCanTakeTimeSlot] = useState(true);
    const [timeSlotsTake, setTimeSlotsTake] = useState(props.rdvState.availabilitiesSelected);

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const [hideRdvAvailabilityEmptyMsg, setHideRdvAvailabilityEmptyMsg] = useState(true);

    const [disabledPreviousBtn, setDisabledPreviousBtn] = useState(false);
    const [disabledNextBtn, setDisabledNextBtn] = useState(false);

    const [currentSlideIndex, setCurrentSlideIndex] = useState(null);

    const compareDates = (date1, date2) => {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        
        if (d1 < d2) {
            return -1; 
        } else if (d1 > d2) {
            return 1;
        } else {
            return 0;
        }
    };

    const handleSubmitSlotsBtn = () => {

        const slots_fulldates = []

        props.rdvState.availabilitiesSelected.forEach(function(item) {
            let fulldate = item.fulldate + ':00'
            slots_fulldates.push(fulldate)
        })

        slots_fulldates.sort(compareDates);

        dispatch({type: 'SET_SLOTS_FULLDATE', payload: slots_fulldates});

        if(props.rdvState.companyCanByassJoinQueuePage) {
          dispatch({type: 'WAITING_AVAILABILITY', payload: false});
          dispatch({type: 'AVAILABILITIES_FETCH', payload: false});
        }
        history.push('/' + props.rdvState.company?.apikey + '/rdv-informations')
    }

    const slideSettings = {
        beforeChange: (oldIndex, newIndex) => {
            let lastSlidesIndex = props.rdvState.availabilities.length - 3

            if( props.rdvState.companyCanByassJoinQueuePage ) {
                if(newIndex == 0) {
                    setDisabledPreviousBtn(true)
                    setDisabledNextBtn(false)
                }
    
                if(newIndex == lastSlidesIndex) {
                    setDisabledNextBtn(true)
                    setDisabledPreviousBtn(false)
                }
    
                if(newIndex != 0 && newIndex < lastSlidesIndex) {
                    setDisabledNextBtn(false)
                    setDisabledPreviousBtn(false)
                }
            }

            setCurrentSlideIndex(newIndex);
        },
    };

    const findAvailabilitySlot = (dateData, hourData) => {
        let date_index = null
        let hour_index = null
        const date_slot = props.rdvState.availabilities.filter((d, i) => {
          if(d.date == dateData) {
            date_index = i
          }
          return d.date == dateData
        })
    
        const hour_slot = props.rdvState.availabilities[date_index].hours.filter((s, i) => {
          if(s.slot == hourData) {
            hour_index = i
          }
          return s.slot == hourData
        })
    
        return {
          'date': date_slot[0],
          'hour': hour_slot[0],
          'date_index': date_index,
          'hour_index': hour_index,
        }
      };


    const handleRemoveSlotBtn = (slotItem) => {
        const slotKey = slotItem.key
        const newTimeSlotsTake = timeSlotsTake.filter(slot => slot.key !== slotKey)
        const timeSlotTakeCount = newTimeSlotsTake.length

        setTimeSlotsTake(newTimeSlotsTake)
        dispatch({type: 'SET_TIME_SLOTS_TAKE', payload: newTimeSlotsTake})
        dispatch({type: 'SET_TIME_SLOT_TAKE_COUNT', payload: timeSlotTakeCount})

        dispatch({type: 'SET_CAN_TAKE_TIME_SLOT', payload: true})
        //setCanTakeTimeSlot(true)

        const availabilitySlot = findAvailabilitySlot(slotItem.date, slotItem.hour)

        console.log(availabilitySlot)

        let availabilities = props.rdvState.availabilities
        console.log(availabilities)
        const slot_available_number = availabilities[availabilitySlot.date_index].hours[availabilitySlot.hour_index].number

        availabilities[availabilitySlot.date_index].hours[availabilitySlot.hour_index].number = slot_available_number + 1
        dispatch({type: 'SET_AVAILABILITIES', payload: availabilities})
    }

    const prevSlide = () => {
        nav1.slickPrev();
        /*let eD = startDate;
        setEnddate(eD)
        let sD = moment(startDate,'YYYY-MM-DD H-m-s').add(-4,'days').format('YYYY-MM-DD H-m-s')
        setStartdate(sD)
        console.log(startDate)
        console.log(endDate)*/
    }
    const nextSlide = () => {
        nav1.slickNext()
        /*let sD = endDate;
        setStartdate(sD)
        let eD = moment(startDate,'YYYY-MM-DD H-m-s').add(4,'days').format('YYYY-MM-DD H-m-s')
        setEnddate(eD)
        console.log(startDate)
        console.log(endDate)*/
    }
    let arrwUse = arrwSvg;
    useEffect(() => {
        if (props.rdvState.operation === 'appointment') {
            dispatch({type: 'IS_LOADING', payload: true})
            var agent = props.rdvState?.agent == null || props.rdvState?.agent == undefined ? {agent : "0"} : props.rdvState?.agent;
            props.getAvailabilities(props.rdvState.branch, props.rdvState.service, agent)
        } else if (props.rdvState.operation === 'currentqueue' && props.rdvState.service !== null) {
            dispatch({type: 'IS_LOADING', payload: true})
        } else goBack()
        
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        arrwUse = props.rdvState.company?.color_scheme.secondary_color ? arrwBlackSvg : arrwSvg;
    }, [props.rdvState.company?.color_scheme.secondary_color])
    arrwUse = props.rdvState.company?.color_scheme.secondary_color ? arrwBlackSvg : arrwSvg;

    useEffect(() => {
        if (props.rdvState.availabilitiesRequestOk == true && props.rdvState.availabilities.length == 0) {
            setHideRdvAvailabilityEmptyMsg(false)
            setDisabledPreviousBtn(true)
            setDisabledNextBtn(true)
        }

        if (props.rdvState.availabilitiesRequestOk == true && props.rdvState.availabilities.length == 1) {
            setDisabledPreviousBtn(true)
            setDisabledNextBtn(true)
        }

        if (props.rdvState.availabilitiesRequestOk == true && props.rdvState.availabilities.length > 0 && props.rdvState.companyCanByassJoinQueuePage) {
            setDisabledPreviousBtn(true)
        }

    }, [props.rdvState.availabilitiesRequestOk, props.rdvState.availabilities])


    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && props.rdvState.availabilitiesSelected.length && canTakeTimeSlot) {
            setTimeSlotsTake(props.rdvState.availabilitiesSelected)
        }
    }, [props.rdvState.availabilitiesSelected])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage) {
            setCanTakeTimeSlot(props.rdvState.canTakeTimeSlot)
        }
    }, [props.rdvState.canTakeTimeSlot])


    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
   const BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" 
    stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)
  var NextIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162"
                               viewBox="0 0 16 12.162">
    <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
      <path id="Path_891" data-name="Path 891" d="M5,0,0,4.674,5,9.335" transform="translate(14 9.335) rotate(180)" fill="none" 
      stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(13.809 4.774) rotate(180)" fill="none"
            stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>)

const ButtonBackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" 
    stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)






    return <div className={'RdvAvailability'} id={'RdvAvailability'}>




        <Row>
            <Col className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>

                        <button hidden={props.rdvState?.back_button == 1 } className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><ButtonBackIcon/>&nbsp;{t('back')}
                        </button>
            </Col>
        </Row>




        <Row className={'serviceBox'}>
            <Col>
                <Row>
                    <Col sm={7} className={'pt-4'}>
                        { props.rdvState?.hadOldRdv && <span className={val_isOrange === 1 ? 'orange-header clickMessage' : 'clickMessage'}>{t('hello')} {props.rdvState.rdvData?.firstname} {props.rdvState.rdvData?.lastname}</span>}
                        { props.rdvState?.hadOldRdv && <br/>}
                        <span className={val_isOrange === 1 ? 'orange-header clickMessage' : 'clickMessage'}>{t('our_avails')} {props.rdvState.service?.translate ? props.rdvState.service?.translate[i18next.language] : props.rdvState.service?.name ? props.rdvState.service?.name[i18next.language] : ''}</span><br/>
                        <span className={val_isOrange === 1 ? 'orange-header clickMessage' : 'clickMessage'}>{t('slot_selected')} {props.rdvState.timeSlotTakeCount} / {props.rdvState.userNumber}</span><br/>
                        <span hidden={canTakeTimeSlot} className={val_isOrange === 1 ? 'orange-header clickMessage' : 'subclickMessage text-danger'}>{t('pick_date_time_confirm')}</span><br/>

                        {timeSlotsTake.map((t, i) => (
                            <Button size="sm" className='buttonActiveRdv mt-3 mr-3 btnCenter' onClick={() => handleRemoveSlotBtn(t)}>
                                <span>{t.fulldate}</span> <CloseButton/>
                            </Button>
                        ))}

                        <br/>
                        <Button className='buttonActiveRdv my-3'
                        style={{display: !canTakeTimeSlot ? 'block' : 'none'}}
                        onClick={handleSubmitSlotsBtn}>
                            {t('next_step')}
                        </Button>
                        
                    </Col>
                </Row>
                <Row style={{display: canTakeTimeSlot ? 'block' : 'none'}}>
                    <Col className={'p-3'}>
                        <Row>



                            <Col sm={3}>
                                <div className={'mob d-flex  flex-wrap justify-content-between d-sm-none d-md-none d-lg-none'}>
                            {/*}{*/}
                            <div className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1' : 'SlideArrows mb-3'} ${disabledPreviousBtn == true ? 'disabled_btn' : ''}`}
                                        style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                        onClick={prevSlide}
                                    ><BackIcon/>  {t('previous')}
                                    </div>
                                    <div
                                        style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                        className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1' : 'SlideArrows'} ${disabledNextBtn == true ? 'disabled_btn' : ''}`}
                                        onClick={nextSlide}
                                    >&nbsp;&nbsp;{t('next')} <NextIcon/></div>
                            
                                </div>
                                    
                                <div
                                    style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                    className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1 hideDesktopbtn' : 'SlideArrows hideDesktopbtn'} ${disabledPreviousBtn == true ? 'disabled_btn' : ''}`}
                                    onClick={prevSlide}
                                ><BackIcon/>   {t('previous')}
                                </div>
                            </Col>

                            <Col sm={6}></Col>

                            <Col sm={3}>
                                <div
                                    style={{backgroundColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color }}
                                    className={`${val_isOrange === 1 ? 'orange-body orange-btn-white1 hideDesktopbtn' : 'SlideArrows hideDesktopbtn'} ${disabledNextBtn == true ? 'disabled_btn' : ''}`}
                                    onClick={nextSlide}
                                >{t('next')} <NextIcon/></div>
                            </Col>
                        </Row>


                        <Row hidden={hideRdvAvailabilityEmptyMsg} className='text-center'>
                            <Col sm={11} className='mt-5'>
                                <h5>{t('availabilities_empty_msg')}</h5>
                            </Col>
                        </Row>


                        <br/>
                        <Row>
                            <Col sm={1} ></Col>
                            <Col sm={10} className={val_isOrange === 1 ? 'orange-plage marge':'marge'} style={{maxWidth:'60%',marginLeft:'auto',marginRight:'auto'}} >
                                <Row>
                                    <Col>
                                        <Slider
                                            {...slideSettings}
                                            asNavFor={nav2}
                                            focusOnSelect={false}
                                            ref={slider => setNav1(slider)}
                                            infinite={false}
                                            slidesToShow={3}
                                            slidesToScroll={1}
                                            responsive={[{
                                                breakpoint: 1024,
                                                settings: {
                                                    slidesToShow: 3,
                                                    slidesToScroll: 3,
                                                    infinite: false,
                                                }
                                            },
                                                {
                                                    breakpoint: 768,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 2,
                                                        initialSlide: 2,
                                                        infinite: false,
                                                    }
                                                },
                                                {
                                                    breakpoint: 480,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        infinite: false,
                                                    }
                                                }]}
                                        >
                                            {props.rdvState.availabilities?.map((a, aa) => (
                                                <div key={aa}>
                                                    <DaySlot day={a}/>
                                                </div>
                                            ))}

                                        </Slider>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Slider
                                            {...slideSettings}
                                            asNavFor={nav1}
                                            ref={slider => setNav2(slider)}
                                            focusOnSelect={false}
                                            infinite={false}
                                            slidesToShow={3}
                                            slidesToScroll={1}
                                            responsive={[{
                                                breakpoint: 1024,
                                                settings: {
                                                    slidesToShow: 3,
                                                    slidesToScroll: 3,
                                                    infinite: false,
                                                }
                                            },
                                                {
                                                    breakpoint: 768,
                                                    settings: {
                                                        slidesToShow: 2,
                                                        slidesToScroll: 2,
                                                        initialSlide: 2,
                                                        infinite: false,
                                                    }
                                                },
                                                {
                                                    breakpoint: 480,
                                                    settings: {
                                                        slidesToShow: 1,
                                                        slidesToScroll: 1,
                                                        infinite: false,
                                                    }
                                                }]}
                                        >
                                            
                                            {props.rdvState.availabilities?.map((a, aa) => {
                                                const t = 0
                                                return (
                                                    <div key={aa} className={'daySlots'} style={{borderRadius: val_isOrange === 1 ? 0:null}}>
                                                        {a.hours?.map((s, ss) => (<RdvTimeSlot countIndex={t + 1}  key={ss} day={a} slot={s}/>))}
                                                    </div>
                                                )
                                            }
                                            )}
                                        </Slider>
                                    </Col>
                                </Row>
                            </Col>
                        
                            <Col sm={1} ></Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvAvailability);
