import React from 'react';
import './OperationSelector.css'
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBranches: e => dispatch(rdvActions.getBranches(e))
  }
}
const OperationSelector = (props) => {
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  let dispatch = useDispatch();
  const selected = props.rdvState.operation ? props.rdvState.operation : localStorage.getItem('rdv_prev_op') ? localStorage.getItem('rdv_prev_op') : null
  




  var SelectedIcon = (p) => (
    <svg style={{marginLeft: 15}} xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <g id="Group_1868" data-name="Group 1868" transform="translate(-966 -320)">
        <g id="Ellipse_115" data-name="Ellipse 115" transform="translate(966 320)" fill="none" stroke="#e4e4e4"
           strokeWidth="1">
          <circle cx="11.5" cy="11.5" r="11.5" stroke="none"/>
          <circle cx="11.5" cy="11.5" r="11" fill="none"/>
        </g>
        <g id="Group_3496" data-name="Group 3496">
          <circle id="Ellipse_115-2" data-name="Ellipse 115" cx="11.5" cy="11.5" r="11.5"
                  transform="translate(966 320)" fill="#fff" stroke={p.stroke}/>
          <path id="Path_2890" data-name="Path 2890" d="M0,2.054,2.745,8.132,14.244,0"
                transform="translate(970.588 328.236) rotate(-7)" fill="none" stroke={p.color} strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </g>
    </svg>
)



  if(val_isOrange == 1){
    //console.log('val_isOrange sdeze')
    SelectedIcon = (p) => (<svg id="SelectedIcon" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
  <path id="SelectedIcon-path" d="M15,1.3A1.291,1.291,0,0,0,12.823.352h0L5.172,7.8,2.652,4.993h0A1.287,1.287,0,0,0,.9,5.061l-.517.52a1.3,1.3,0,0,0-.1,1.731h0l4.138,5.2h0a1.288,1.288,0,0,0,2.019,0h0l8.276-10.4h0A1.3,1.3,0,0,0,15,1.3Z" transform="translate(2.5 3.5)"/>
</svg>
    )
}

  const handleClick = () => {
    dispatch({type: 'IS_LOADING', payload: true});
    dispatch({type: 'SET_OPERATION', payload: props.label});
    localStorage.setItem('rdv_prev_op', props.label)
    props.getBranches(props.label)
  }

/*

      <span className={val_isOrange === 1 ? 'orange-header' : ''} style={{color: props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : ''}}>{props.text}</span>
      */

  return <div className={val_isOrange === 1 ? 'OperationSelectorOrange' : 'OperationSelector'} id={'OperationSelector'} style={props?.style}>
    <a className={selected === props.label ? 'Selected' : ''} style={{backgroundColor: selected === props.label ? props.rdvState.company?.color_scheme.primary_color : ''}} onClick={handleClick}>
      

      <span hidden={val_isOrange !== 1}>
      {selected === props.label ? (<SelectedIcon color={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#1689e1'}/>) : null}
          </span>

      <span>{props.text}</span>
      <span hidden={val_isOrange === 1}>
      {selected === props.label ? (<SelectedIcon color={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#1689e1'}/>) : (
          <SelectedIcon color={'white'} stroke={'#E4E4E4'}/>)}
          </span>
    </a>
    {' '}
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationSelector);
