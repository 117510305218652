import React from 'react';
import './TimeSlot.css'
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const TimeSlot = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();

  const handleSelect = () => {
    dispatch({type: 'SET_SLOT', payload: props.slot})
    dispatch({type: 'SET_DATE', payload: props.day.date})
    if(props.rdvState.companyCanByassJoinQueuePage) {
      dispatch({type: 'WAITING_AVAILABILITY', payload: false});
      dispatch({type: 'AVAILABILITIES_FETCH', payload: false});
    }
    history.push('/' + props.rdvState.company?.apikey + '/informations')
  }
  return <div style={{backgroundColor: props.rdvState.company?.color_scheme.tile_color}} className={'TimeSlot'} id={'TimeSlot'} onClick={handleSelect}>
    <span className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''} style={{color: props.rdvState.company?.color_scheme.secondary_color}}>{props.slot}</span>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);
