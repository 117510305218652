import React, {useEffect, useState} from 'react';
import './RdvTimeSlot.css'
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const RdvTimeSlot = (props) => {
  const {t} = useTranslation();
  let history = useHistory();
  let dispatch = useDispatch();

  const [availabilitySlot, setAvailabilitySlot] = useState({
    'date': null,
    'hour': null,
    'date_index': 0,
    'hour_index': 0,
  });
  
  useEffect(() => {
    const availabilitySlotFound = findAvailabilitySlot()
    setAvailabilitySlot(availabilitySlotFound)
  }, [])

  const findAvailabilitySlot = () => {
    let date_index = null
    let hour_index = null
    const date_slot = props.rdvState.availabilities.filter((d, i) => {
      if(d.date == props.day.date) {
        date_index = i
      }
      return d.date == props.day.date
    })

    const hour_slot = props.rdvState.availabilities[date_index].hours.filter((s, i) => {
      if(s.slot == props.slot.slot) {
        hour_index = i
      }
      return s.slot == props.slot.slot
    })

    return {
      'date': date_slot[0],
      'hour': hour_slot[0],
      'date_index': date_index,
      'hour_index': hour_index,
    }
  };

  const handleSelect = () => {
    if(props.slot.number > 0) {
      const availabilitySlotFound = findAvailabilitySlot()
    setAvailabilitySlot(availabilitySlotFound)
    //dispatch({type: 'SET_SLOT', payload: props.slot})
    //dispatch({type: 'SET_DATE', payload: props.day.date})
    //if(props.rdvState.companyCanByassJoinQueuePage) {
    //  dispatch({type: 'WAITING_AVAILABILITY', payload: false});
    //  dispatch({type: 'AVAILABILITIES_FETCH', payload: false});
    //}
    //history.push('/' + props.rdvState.company?.apikey + '/informations')

    const slots = props.rdvState.availabilitiesSelected

    const data = {
      'fulldate': props.day.date + ' ' + props.slot.slot, 
      'date': props.day.date,
      'hour': props.slot.slot,
      'date_index': availabilitySlot.date_index,
      'hour_index': availabilitySlot.hour_index,
      'key': slots.length
    }

    const timeSlotTakeCount = props.rdvState.timeSlotTakeCount

    if(timeSlotTakeCount < props.rdvState.userNumber) {
      slots.push(data)
      dispatch({type: 'SET_TIME_SLOTS_TAKE', payload: slots})
      dispatch({type: 'SET_TIME_SLOT_TAKE_COUNT', payload: timeSlotTakeCount + 1})

      let availabilities = props.rdvState.availabilities
      const slot_available_number = availabilities[availabilitySlot.date_index].hours[availabilitySlot.hour_index].number

      availabilities[availabilitySlot.date_index].hours[availabilitySlot.hour_index].number = slot_available_number - 1
      dispatch({type: 'SET_AVAILABILITIES', payload: availabilities})
    }

    if(timeSlotTakeCount + 1 >= props.rdvState.userNumber) {
      dispatch({type: 'SET_CAN_TAKE_TIME_SLOT', payload: false})
    }
    }
    
  }
  return <div style={{backgroundColor: props.rdvState.company?.color_scheme.tile_color}} className={`RdvTimeSlot`} id={'RdvTimeSlot'} onClick={handleSelect}>
    <span className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''} style={{color: props.rdvState.company?.color_scheme.secondary_color}}>{props.rdvState.availabilities[availabilitySlot.date_index].hours[availabilitySlot.hour_index].slot}</span>
    <p className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''} style={{color: props.rdvState.company?.color_scheme.secondary_color, marginBottom: 0}}>{t('slot_available')} {props.rdvState.availabilities[availabilitySlot.date_index].hours[availabilitySlot.hour_index].number}</p>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvTimeSlot);
