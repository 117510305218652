import React, {useEffect, useState} from 'react';
import './RedirectService.css'
import {useParams, useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";
import bg from '../../assets/background.jpg'

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBranches: e => dispatch(rdvActions.getBranches(e))
    }
}
const RedirectService = (props) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const {operation, apiKey, officeId} = useParams();
    const [company, setCompany] = useState();
    const [branches, setBranches] = useState();
    const [branch, setBranch] = useState();
    useEffect(() => {
        sessionStorage.setItem('rdv_apikey', apiKey)
        dispatch({type: 'SET_OPERATION', payload: operation});
        localStorage.setItem('rdv_prev_op', operation);
    }, []);
    
    useEffect(() => {
        if (branches == true){
            var branchList = props.rdvState.branches;
            var currentBranch = branchList.filter(function(branch) {
                if (branch.office == undefined){
                    return branch.id == officeId;
                }
                else{
                    return branch.office == officeId;
                }
            });
            localStorage.setItem('rdv_prev_brach', JSON.stringify({o: operation, b: currentBranch[0]}))
            dispatch({type: 'SET_BRANCH', payload: currentBranch[0]})
        }
    }, [branches]);

    useEffect(() => {
        if (branch == true){
            dispatch({type: 'SET_NO_BACK_BUTTON', payload: 1})
            history.push('/' + apiKey + "/service");
        }
    }, [branch]);

    useEffect(() => {
        if (company == true){
            dispatch({type: 'IS_LOADING', payload: true});
            props.getBranches(operation);
        }
    }, [company]);

    useEffect(() => {
        if(props.rdvState.company){
            setCompany(true);
        }
        if (props.rdvState.branches && props.rdvState.branches.length > 0){
            setBranches(true);
        }
        if(props.rdvState.branch){
            setBranch(true);
        }
    });
    
    return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectService);
