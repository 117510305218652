import React from 'react';
import './ServiceClosed.css'
import {Table} from "react-bootstrap";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
const ServiceClosed = (props) => {
    const {t} = useTranslation();
    return <div className={'ServiceClosed'} id={'ServiceClosed'}>
        <div className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body text-danger-orange text-center p-2' : 'text-danger text-center p-2'}>{t('cant_join')}</div>
        <Table striped bordered hover className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''}>
            <thead>
            <tr>
                <th>{t('day_of_week')}</th>
                <th>{t('open_h')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{t('Monday')}</td>
                <td>{props.rdvState.serviceClosed?.Monday ? props.rdvState.serviceClosed?.Monday : t('not_open_bookin')}</td>
            </tr>
            <tr>
                <td>{t('Tuesday')}</td>
                <td>{props.rdvState.serviceClosed?.Tuesday ? props.rdvState.serviceClosed?.Tuesday : t('not_open_bookin')}</td>
            </tr>
            <tr>
                <td>{t('Wednesday')}</td>
                <td>{props.rdvState.serviceClosed?.Wednesday ? props.rdvState.serviceClosed?.Wednesday : t('not_open_bookin')}</td>
            </tr>
            <tr>
                <td>{t('Thursday')}</td>
                <td>{props.rdvState.serviceClosed?.Thursday ? props.rdvState.serviceClosed?.Thursday : t('not_open_bookin')}</td>
            </tr>
            <tr>
                <td>{t('Friday')}</td>
                <td>{props.rdvState.serviceClosed?.Friday ? props.rdvState.serviceClosed?.Friday : t('not_open_bookin')}</td>
            </tr>
            <tr>
                <td>{t('Saturday')}</td>
                <td>{props.rdvState.serviceClosed?.Saturday ? props.rdvState.serviceClosed?.Saturday : t('not_open_bookin')}</td>
            </tr>
            <tr>
                <td>{t('Sunday')}</td>
                <td>{props.rdvState.serviceClosed?.Sunday ? props.rdvState.serviceClosed?.Sunday : t('not_open_bookin')}</td>
            </tr>
            </tbody>
        </Table>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceClosed);