import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import * as rdvActions from "../../actions";
import './Failure.css';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const Failure = (props) => {

  let history = useHistory();
  let dispatch = useDispatch();
  const {apiKey} = useParams();

  useEffect(() => {
    dispatch({type: 'SET_PAYMENT_FAILED', payload: true});
    dispatch({type: 'SET_PAYMENT_SUCCESS', payload: false});
    dispatch({type: 'IS_LOADING', payload: true});
  }, [])


  useEffect(() => {
    if(props.rdvState.paymentFailed && !props.rdvState.paymentSuccess) {
      history.push('/' + apiKey + '/rdv-start')
    }
  }, [props.rdvState.paymentFailed, props.rdvState.paymentSuccess]);

  return <></>


};

export default connect(mapStateToProps, mapDispatchToProps)(Failure);
