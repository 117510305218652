import {applyMiddleware, compose, createStore} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";

const composeEnhancers = composeWithDevTools({trace: true}); // <<< set options here

const store = createStore(
  rootReducer, /* preloadedState, */
  composeEnhancers( // <<< use it here
    applyMiddleware(thunk),
  )
);

/*const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    storeEnhancers(
        applyMiddleware(
            thunk
        )
    )
);*/

export default store;
