import React from 'react';
import './DaySlot.css'
import moment from 'moment';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const DaySlot = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'DaySlot'} id={'DaySlot'}>
    <div style={{borderColor:  props.rdvState.companyCanByassJoinQueuePage ? props.rdvState.company?.color_scheme.custom_color : props.rdvState.company?.color_scheme.secondary_color}} className={'circle'}>
      <div className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body day' : 'day'}  style={{color:  props.rdvState.companyCanByassJoinQueuePage ? props.rdvState.company?.color_scheme.custom_color : props.rdvState.company?.color_scheme.secondary_color}}>{t(moment(props.day.date).locale(i18n.language).format('dddd'))}</div>
      <div className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body date' : 'date'} style={{color:  props.rdvState.companyCanByassJoinQueuePage ? props.rdvState.company?.color_scheme.custom_color : props.rdvState.company?.color_scheme.secondary_color}}>{moment(props.day.date).locale(i18n.language).format("MMMM Do, YYYY")}</div>
    </div>
  </div>;
};

export default connect(mapStateToProps)(DaySlot);
