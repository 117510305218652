const initialState = {
    articles: [],
    remoteArticles: [],
    isLoading: false,
    company: null,
    apiKey: null,
    operation: null,
    branch: null,
    branches: [],
    service: null,
    serviceClosed: null,
    services: [],
    visitor: null,
    noAgent: true,
    agent: null,
    agents: [],
    countries: [],
    availabilities: [],
    date: null,
    slot: null,
    ticket: null,
    rdv: null,
    rdvData: null,
    externalRdvKey: null,
    hadOldRdv: false,
    orderNumberChecked: false,
    orderNumber: null,
    orderNumberUuid: null,
    orderNumberRequestOk: false,
    showOrderNumberModal: false,
    orderNumberNotFound: false,
    companyCanByassJoinQueuePage: false,
    isRdvLoad: false,
    hasRdvForThisSlotTime: false,
    waitingAvailability: false,
    orderNumberHasAlreadyRdv: false,
    availabilitiesRequestOk: false,
    showUserNumberModal: false,
    userNumber: null,
    showRavipModal: false,
    userHasRavip: null,
    ravipModalTitle: null,
    ravipModalLabel: null,
    ravipModalDescription: null,
    availabilitiesSelected: [],
    availabilitiesSelectedFulldate: [],
    timeSlotTakeCount: 0,
    timeSlotToTakeCount: 0,
    canTakeTimeSlot: true,
    timeSlots: [],
    userNumberLimit: 1,
    currentLang: null,
    currentLang: null,
    paymentUrl: null,
    paymentFailed: false,
    paymentSuccess: false,
    paymentBadlink: false,
    canTakeGroupAvailability: false,
    showTimeSlotOutdated: false,
    showTimeSlotBusy: false,
};

function rootReducer(state = initialState, action) {
    if (!state) {
        return null;
    }
    //console.log(action);
    switch (action.type) {
        case "DO_SOMETHING": {
            //console.log("state : ", { ...state });
            console.log(state);
            console.log(action);
            return {...state, something: action.payload};
        }
        case "ADD_ARTICLE": {
            return Object.assign({}, state, {
                articles: state.articles.concat(action.payload)
            });
        }
        case "DATA_LOADED": {
            return Object.assign({}, state, {
                remoteArticles: state.remoteArticles.concat(action.payload)
            });
        }
        case "SET_OPERATION": {
            return {...state, operation: action.payload};
        }
        case "IS_LOADING": {
            return {...state, isLoading: action.payload};
        }
        case "SET_BRANCH": {
            return {...state, branch: action.payload};
        }
        case "SET_BRANCHES": {
            return {...state, branches: action.payload};
        }
        case "SET_SERVICE": {
            return {...state, service: action.payload};
        }
        case "SET_SERVICES_CLOSED": {
            return {...state, serviceClosed: action.payload};
        }
        case "SET_SERVICES": {
            return {...state, services: action.payload};
        }
        case "SET_VISITOR": {
            return {...state, visitor: action.payload};
        }
        case "SET_AGENT": {
            return {...state, agent: action.payload};
        }
        case "SET_NO_AGENTS": {
            return {...state, noAgent: action.payload};
        }
        case "SET_AGENTS": {
            return {...state, agents: action.payload};
        }
        case "SET_SLOT": {
            return {...state, slot: action.payload};
        }
        case "SET_AVAILABILITIES": {
            return {...state, availabilities: action.payload};
        }
        case "SET_DATE": {
            return {...state, date: action.payload};
        }
        case "GET_TICKET": {
            return {...state, ticket: action.payload};
        }
        case "GET_RDV": {
            return {...state, rdv: action.payload};
        }
        case "SET_COMPANY": {
            return {...state, company: action.payload};
        }
        case "SET_NO_BACK_BUTTON":{
            return {...state, back_button: 1};
        }
        case "APIKEY": {
            return {...state, apiKey: action.payload};
        }
        case "GET_RDV_DATA": {
            return {...state, rdvData: action.payload};
        }
        case "SET_EXTERNAL_RDV_KEY": {
            return {...state, externalRdvKey: action.payload};
        }
        case "HAD_OLD_RDV": {
            return {...state, hadOldRdv: action.payload};
        }
        case "ORDER_NUMBER_CHECKED": {
            return {...state, orderNumberChecked: action.payload};
        }
        case "SET_ORDER_NUMBER": {
            return {...state, orderNumber: action.payload};
        }
        case "SET_ORDER_NUMBER_UUID": {
            return {...state, orderNumberUuid: action.payload};
        }
        case "CHECK_ORDER_NUMBER_REQUEST_OK": {
            return {...state, orderNumberRequestOk: action.payload};
        }
        case "SHOW_ORDER_NUMBER_MODAL": {
            return {...state, showOrderNumberModal: action.payload};
        }
        case "ORDER_NUMBER_NOT_FOUND": {
            return {...state, orderNumberNotFound: action.payload};
        }
        case "COMPANY_CAN_BYPASS_JOIN_QUEUE_PAGE": {
            return {...state, companyCanByassJoinQueuePage: action.payload};
        }
        case "IS_RDV_LOAD": {
            return {...state, isRdvLoad: action.payload};
        }
        case "EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT": {
            return {...state, hasRdvForThisSlotTime: action.payload};
        }
        case "SET_COUNTRIES": {
            return {...state, countries: action.payload};
        }
        case "WAITING_AVAILABILITY": {
            return {...state, waitingAvailability: action.payload};
        }
        case "ORDER_NUMBER_HAS_ALREADY_RDV": {
            return {...state, orderNumberHasAlreadyRdv: action.payload};
        }
        case "AVAILABILITIES_FETCH": {
            return {...state, availabilitiesRequestOk: action.payload};
        }
        case "SHOW_USER_NUMBER_MODAL": {
            return {...state, showUserNumberModal: action.payload};
        }
        case "SET_USER_NUMBER": {
            return {...state, userNumber: action.payload};
        }
        case "SET_USER_NUMBER_LIMIT": {
            return {...state, userNumberLimit: action.payload};
        }
        case "SHOW_RAVIP_MODAL": {
            return {...state, showRavipModal: action.payload};
        }
        case "SET_USER_HAS_RAVIP": {
            return {...state, userHasRavip: action.payload};
        }
        case "SET_RAVIP_MODAL_TITLE": {
            return {...state, ravipModalTitle: action.payload};
        }
        case "SET_RAVIP_MODAL_LABEL": {
            return {...state, ravipModalLabel: action.payload};
        }
        case "SET_RAVIP_MODAL_DESCRIPTION": {
            return {...state, ravipModalDescription: action.payload};
        }
        case "SET_TIME_SLOTS_TAKE": {
            return {...state, availabilitiesSelected: action.payload};
        }
        case "SET_TIME_SLOT_TAKE_COUNT": {
            return {...state, timeSlotTakeCount: action.payload};
        }
        case "SET_TIME_SLOT_TO_TAKE_COUNT": {
            return {...state, timeSlotToTakeCount: action.payload};
        }
        case "SET_CAN_TAKE_TIME_SLOT": {
            return {...state, canTakeTimeSlot: action.payload};
        }
        case "SET_TIME_SLOTS": {
            return {...state, timeSlots: action.payload};
        }
        case "SET_CURRENT_LANG": {
            return {...state, currentLang: action.payload};
        }
        case "SET_SLOTS_FULLDATE": {
            return {...state, availabilitiesSelectedFulldate: action.payload};
        }
        case "SET_PAYMENT_URL": {
            return {...state, paymentUrl: action.payload};
        }
        case "SET_PAYMENT_FAILED": {
            return {...state, paymentFailed: action.payload};
        }
        case "SET_PAYMENT_SUCCESS": {
            return {...state, paymentSuccess: action.payload};
        }
        case "SET_PAYMENT_BADLINK": {
            return {...state, paymentBadlink: action.payload};
        }
        case "SET_CAN_TAKE_GROUP_AVAILABILITY": {
            return {...state, canTakeGroupAvailability: action.payload};
        }
        case "SHOW_TIME_SLOT_OUTDATED": {
            return {...state, showTimeSlotOutdated: action.payload};
        }
        case "SHOW_TIME_SLOT_BUSY": {
            return {...state, showTimeSlotBusy: action.payload};
        }
        default:
            return state;
    }
}

export default rootReducer;
