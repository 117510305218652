import React, {useEffect, useState} from 'react';
import './ServiceSelection.css'
import {Col, Row} from "react-bootstrap";
import backIcon from "../../assets/back.svg";
import {useHistory, useParams} from "react-router-dom";
import BranchService from "../BranchService";
import BranchSelector from "../BranchSelector";
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";
import ServiceClosed from "../ServiceClosed";
import {useTranslation} from "react-i18next";
import NoAgent from "../NoAgent";
import i18next from "i18next";

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: e => dispatch(rdvActions.getServices(e),)
    }
}
const ServiceSelection = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();
    const {apiKey} = useParams();
    localStorage.removeItem('company_data_set_rdv_exist')
    const [canShowNoAgentPage, setCanShowNoAgentPage] = useState(false)
    const goBack = () => {
        if(props.rdvState.companyCanByassJoinQueuePage) {
            dispatch({type: 'WAITING_AVAILABILITY', payload: false});
            history.push('/' + apiKey +'/rdv-start')
        } else {
            history.push('/' + sessionStorage.getItem('rdv_apikey') + "/operation")
        }
    }

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && !props.rdvState.isRdvLoad) {
          history.push('/' + apiKey +'/rdv-start')
        }
      }, [props.rdvState.companyCanByassJoinQueuePage])

    useEffect(() => {
        if (props.rdvState.branch !== null) {
            dispatch({type: 'IS_LOADING', payload: true});
            props.getServices(props.rdvState.branch)
        } else goBack()

        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (props.rdvState.services == [] && props.rdvState.serviceClosed === null) {
            setCanShowNoAgentPage(true)
        }
      }, [props.rdvState.services])

    const compare = (a, b) => {
        if (a.translate) {
            if (a.translate[i18next.language] < b.translate[i18next.language]) {
                return -1;
            }
            if (a.translate[i18next.language] > b.translate[i18next.language]) {
                return 1;
            }
        }
        if (a.name) {
            if (a.name[i18next.language] < b.name[i18next.language]) {
                return -1;
            }
            if (a.name[i18next.language] > b.name[i18next.language]) {
                return 1;
            }
        }
        //return 0;
    }
    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;


//props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#808080'



const BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" 
    stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : props.rdvState.company?.apikey === "Z2tKcTlRYnNOMlNmY0M2VWM0TGNsZz09" ? '#808080': '#FFFFFF'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : props.rdvState.company?.apikey === "Z2tKcTlRYnNOMlNmY0M2VWM0TGNsZz09" ? '#808080': '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)


/*
    const BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} style={{marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" stroke={props.rdvState.company?.color_scheme.secondary_color ? '#FFF' : '#808080'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.company?.color_scheme.secondary_color ? '#FFF' : '#808080'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)

            <div className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
                <Row className={'r'}>
                    <Col sm={2} lg={2}>
                        <button hidden={props.rdvState?.back_button == 1 } className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/>{t('back')}
                        </button>
                    </Col>
                    <Col className={'p-2'}>
                        <span hidden={props.rdvState.operation === 'appointment'}
                              className={val_isOrange === 1 ? 'orange-body agenceNameOrange agenceName' : 'agenceName'}>{t('welcome_to')} <strong>{props.rdvState.branch?.Name}</strong></span>
                    </Col>
                </Row>
            </div>
            */
    return <div className={'ServiceSelection'} id={'ServiceSelection'}>
        <div>
            <div hidden={val_isOrange === 1 } className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
                <Row className={'r'}>
                    <Col sm={12} lg={2} md={2}>
                        <button hidden={props.rdvState?.back_button == 1 } className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/> &nbsp;{t('back')}
                        </button>
                    </Col>
                    <Col sm={12} lg={8} md={8} className={'p-2'}>
                        <span hidden={props.rdvState.operation === 'appointment'}
                              className={val_isOrange === 1 ? 'orange-body agenceNameOrange agenceName' : 'agenceName'}>{t('welcome_to')} <strong>{props.rdvState.branch?.Name}</strong></span>
                    </Col>
                </Row>
            </div>

    <Row hidden={val_isOrange !== 1 } className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
      <Col sm={2} lg={2} md={2}>
        <button className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/>&nbsp;{t('back')}</button>
      </Col>
                    <Col sm={12} lg={8} md={8} className={'p-2'}>
                        <span hidden={props.rdvState.operation === 'appointment'}
                              className={val_isOrange === 1 ? 'orange-body agenceNameOrange agenceName' : 'agenceName'}>{t('welcome_to')} <strong>{props.rdvState.branch?.Name}</strong></span>
                    </Col>
    </Row>







            <div className={'serviceBox align-items-center justify-content-between'}>
                <Row>
                    <Col sm={5} className={'pt-4'}>
                        <div hidden={props.rdvState.operation === 'appointment'}>
                            {props.rdvState.serviceClosed === null ? (
                                <span hidden={props.rdvState.services.length <= 0}
                                className={val_isOrange === 1 ? 'orange-body orange-black clickMessage' : 'clickMessage'}>{t('click_sevice')}</span>
                            ) : ('')}
                        </div>
                        <div hidden={props.rdvState.operation === 'currentqueue'}>
                            <span className={val_isOrange === 1 ? 'orange-body orange-black clickMessage' : 'clickMessage'}>{t('our_services')}</span>
                            <p className={val_isOrange === 1 ? 'orange-body orange-black appointReason' : 'appointReason'}>{t('our_services_msg')}</p>
                        </div>
                    </Col>
                    <Col></Col>
                    <Col sm={7} md={4}>
                        <div className={'ml-auto'}
                             hidden={props.rdvState.operation === 'appointment' || props.rdvState.services.length <= 0}>
                            {props.rdvState.serviceClosed === null ? (
                                <span className={val_isOrange === 1 ? 'orange-body orange-black classMessage' : 'classMessage'}>{t('queue_long_msg')}</span>
                            ) : (
                                <span className={val_isOrange === 1 ? 'orange-body orange-black classMessage' : 'classMessage'}>{t('please_try_branch')}</span>
                            )}

                            <BranchSelector fetchServices={props.getServices} customPlaceholder={t('select_another_branch')}/>
                        </div>
                        <div hidden={!props.rdvState.serviceClosed}>
                            <span className={val_isOrange === 1 ? 'orange-body orange-black classMessage' : 'classMessage'}>{t('please_try_branch')}</span>
                            <BranchSelector fetchServices={props.getServices} customPlaceholder={t('select_another_branch')}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <div className={val_isOrange === 1 ? 'serviceList serviceListOrange':'serviceList'}>
            <Row>
                {props.rdvState.services.sort(compare).map((s) => (
                    <Col key={s.id} sm={6} lg={4}><BranchService service={s}/></Col>))}
                {props.rdvState.serviceClosed !== null ? (<ServiceClosed/>) : ('')}
                {(canShowNoAgentPage) ? (<NoAgent/>) : ('')}
            </Row>
        </div>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSelection);
