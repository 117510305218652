import React from 'react';
import './RdvStartMessage.css'
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const RdvStartMessage = (props) => {
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  const {t} = useTranslation();
  const text_color = props.rdvState.companyCanByassJoinQueuePage ? props.rdvState.company?.color_scheme.custom_color : props.rdvState.company?.color_scheme.secondary_color
  return <div className={val_isOrange === 1 ? 'orange-header RdvStartMessage RdvStartMessage-orange' : 'RdvStartMessage'} id={'RdvStartMessage'}>
  <span className={val_isOrange === 1 ? 'orange-header bigText' : 'bigText'} style ={{color: text_color}}>{t('ambassade_welcome_text')}</span><br/>
    <span className={val_isOrange === 1 ? 'orange-header' : ''} style ={{color: text_color}}>{t('ambassade_description_text')}</span>
  </div>;
};

export default connect(mapStateToProps)(RdvStartMessage);
