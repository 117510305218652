import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import * as rdvActions from "../../actions";
import './RdvLoad.css';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserRdvLoadData: e => dispatch(rdvActions.getUserRdvLoadData())
  }
}

const RdvLoad = (props) => {

  let history = useHistory();
  let dispatch = useDispatch();
  const {apiKey} = useParams();

  const goBack = () => {
    history.push('/' + apiKey)
  }

  useEffect(() => {
    dispatch({type: 'IS_LOADING', payload: true});
    sessionStorage.setItem('rdv_apikey', apiKey)
    props.getUserRdvLoadData()
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    if(props.rdvState.branch !== null && props.rdvState.operation !== null && props.rdvState.company !== null ) {
      history.push('/' + apiKey + '/rdv-service')
    }
  }, [props.rdvState.branch, props.rdvState.operation]);

  return <></>


};

export default connect(mapStateToProps, mapDispatchToProps)(RdvLoad);
