import React, {useEffect} from 'react';
import './StartScreen.css'
import {Col, Row} from "react-bootstrap";
import CovidMessage from "../../stepsComponents/CovidMessage";
import StartMessage from "../../stepsComponents/StartMessage";
import StartScreenCard from "../../stepsComponents/StartScreenCard";
import joinSVG from '../../assets/join-the-current-queue.svg'
import bookSVG from '../../assets/book-an-appointment.svg'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch} from 'react-redux';
import * as rdvActions from "../../actions";


const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const StartScreen = (props) => {

    const {apiKey} = useParams();
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();
    let isMobile = window.innerWidth > 768 ? false:true;
    let setOrderOCI = props.rdvState.company?.apikey === "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" && isMobile;
    let classJoinCurrent = setOrderOCI == true ? 'order-last':'order-first';
    let classBookApp = setOrderOCI == true ? 'order-first':'order-last';
    let OLUX_apikey = "OVEvL2R1eTJqREpWMisrNmFqdDlOdz09"
    localStorage.removeItem('company_data_set_rdv_exist')
/*
    window.addEventListener('resize', () => {
        isMobile = window.innerWidth > 768 ? false:true
        console.log(isMobile);
        console.log('setOrderOCI ' + setOrderOCI)
    }, false);
    */


    useEffect(() => {
        //const route = window.location.pathname.split('/')
        if(apiKey === OLUX_apikey) {
            dispatch({type: 'SET_OPERATION', payload: "currentqueue"});
            localStorage.setItem('rdv_prev_op', "currentqueue")
            history.push('/' + OLUX_apikey + '/operation')
        }
        window.scrollTo(0, 0)
 
    }, [])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage == 1) {
          history.push('/' + apiKey +'/rdv-start')
        }
      }, [props.rdvState.companyCanByassJoinQueuePage])
    /*

    useEffect(() => {
        window.scrollTo(0, 0)



        setOrderOCI = props.rdvState.company?.apikey === "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" && isMobile;
        classJoinCurrent = setOrderOCI == true ? 'order-last':'order-first'
        classBookApp = setOrderOCI == true ? 'order-first':'order-last'


    }, [isMobile])
    console.log(setOrderOCI)
    console.log('classJoinCurrent ' + classJoinCurrent)
    console.log('classBookApp ' + classBookApp)
    
    */

let comp_name = decodeURIComponent(escape(props.rdvState.company?.company))
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
    return <div className={'StartScreen'} id={'StartScreen'}>
          <MetaTags>
          <title>{comp_name === 'undefined' ? 'RightQ RDV':comp_name}</title>
          <meta property="og:image" content='/favicon-og.png' />
          <meta property="og:type" content="website"></meta>
          <meta name="og:site_name" content={comp_name}></meta>
          <meta property="og:title" content={comp_name} />
          <meta name="twitter:title" content={comp_name}></meta>
          <meta property="twitter:title" content={comp_name}></meta>
          <meta name="description" content={t('og_description')} />
          <meta name="og:description" content={t('og_description')}></meta>
          </MetaTags>
        <Row>
            {/* <Col sm={12} lg={4} md={12} className={val_isOrange === 1 ? 'col-covid-message': null }>
                <StartMessage/>
            </Col> */}
            <Col sm={12} lg={2} md={12}></Col>
            <Col sm={12} lg={8} md={12}>
                {/* <div style={{maxWidth: 564, marginBottom: 35}} className={val_isOrange === 1 ? 'start-screen-message': null } >
                    <span style={{color: props.rdvState.company?.color_scheme.secondary_color}} className={val_isOrange == 1 ? 'ctaMessage-orange ctaMessage' : 'ctaMessage'}>{t('need_to_visit')}</span>
                </div> */}
                <StartMessage/>
                <div className={'row startScreenCards'}>
                    <Col xs={12} md={6} className={classJoinCurrent}>
                        <StartScreenCard img={joinSVG} text={t('join_msg')} btnText={t('join_current')}
                                         to={'currentqueue'}/>
                    </Col>
                    <Col xs={12} md={6} className={classBookApp}>
                        <StartScreenCard img={bookSVG} text={t('plan_msg')} btnText={t('book_app')} to={'appointment'}/>
                    </Col>
                </div>
            </Col>
        </Row>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(StartScreen);
