import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import './RdvExistCheck.css'
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from 'react-redux'

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const RdvExistCheck = (props) => {

  let history = useHistory();
  let dispatch = useDispatch();

  useEffect(() => {
    if(props.rdvState.orderNumberChecked && props.rdvState?.orderNumber !== null && props.rdvState?.orderNumberUuid !== null) {
      history.push('/' + props.rdvState.company?.apikey + '/rdv-exist/' + props.rdvState.orderNumberUuid);
      dispatch({type: 'CHECK_ORDER_NUMBER_REQUEST_OK', payload: false});
    } else {
      history.push('/' + props.rdvState.company?.apikey + '/rdv-start/');
    }
    
  }, [props.rdvState.company])

  return <></>;
};

export default connect(mapStateToProps)(RdvExistCheck);
