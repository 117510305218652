import React, {useEffect, useState} from 'react';
import './Success.css'
import confirmSvg from "../../assets/sent.svg";
import {Col, Row} from "react-bootstrap";
import timeSvg from "../../assets/time.svg";
import {useHistory, useParams} from "react-router-dom";
import serviceSvg from '../../assets/service.svg'
import branchSvg from '../../assets/branch.svg'
import locationSvg from '../../assets/slocation.svg'
import dateSvg from '../../assets/date.svg'
import {connect} from "react-redux";
import NameInitials from "../NameInitials";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import moment from "moment";
import { useLocation } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}


const Success = (props) => {
    const location = useLocation();

    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
    localStorage.removeItem('company_data_set_rdv_exist')

/*============================================================================================*/

  const ServiceIcon = (p) => (<svg className={val_isOrange === 1 ? 'orangeServiceIcon' : null} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="Group_3565" data-name="Group 3565" transform="translate(0 -0.001)">
        <circle id="Ellipse_27" data-name="Ellipse 27" cx="15" cy="15" r="14" transform="translate(0 0.001)"
                fill="#f5f5f5"/>
        <path id="star"
              d="M10.971,4.517a.585.585,0,0,0-.5-.405L7.293,3.82,6.038.855a.583.583,0,0,0-1.076,0L3.707,3.82.531,4.111a.587.587,0,0,0-.5.405A.6.6,0,0,0,.2,5.144L2.6,7.268l-.708,3.146a.593.593,0,0,0,.227.609.579.579,0,0,0,.643.029L5.5,9.4l2.738,1.653a.58.58,0,0,0,.643-.029.593.593,0,0,0,.227-.609L8.4,7.268l2.4-2.125A.594.594,0,0,0,10.971,4.517ZM5.552,9.368"
              transform="translate(9.576 9.216)" fill="#999"/>
    </g>
</svg>
)
  const BranchIcon = (p) => (<svg className={val_isOrange === 1 ? 'orangeBranchIcon' : null} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="Group_3349" data-name="Group 3349" transform="translate(0 -0.001)">
        <circle id="Ellipse_27" data-name="Ellipse 27" cx="15" cy="15" r="14" transform="translate(0 0.001)"
                fill="#f5f5f5"/>
        <path id="building"
              d="M54.941,10.705h-.728V1.661a.369.369,0,0,0-.369-.369H51.076V.369A.369.369,0,0,0,50.707,0H48.146a.369.369,0,0,0-.369.369v.923h-.3a.369.369,0,0,0-.369.369v9.044H46.38a.376.376,0,0,0-.379.351.369.369,0,0,0,.369.387H49a.185.185,0,0,0,.185-.185V9.136h-.151a.376.376,0,0,1-.379-.351.369.369,0,0,1,.369-.387h3.266a.376.376,0,0,1,.379.351.369.369,0,0,1-.369.387h-.161v2.123a.185.185,0,0,0,.185.185h2.63a.369.369,0,0,0,.369-.387A.376.376,0,0,0,54.941,10.705ZM49.761,7.637h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm2.192,4.153h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm-2.03,7.775V9.321a.185.185,0,0,1,.185-.185h1.107a.185.185,0,0,1,.185.185v1.938a.185.185,0,0,1-.185.185H50.107A.185.185,0,0,1,49.922,11.259Z"
              transform="translate(-35 9.558)" fill="#999"/>
    </g>
</svg>
)
  const LocationIcon = (p) => (<svg className={val_isOrange === 1 ? 'orangeLocationIcon' : null} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 32">
  <g id="Group_3552" transform="translate(1 1)">
    <g id="Group_3549" transform="translate(0 -0.001)">
      <circle id="Ellipse_27-4" cx="15" cy="15" r="14" transform="translate(0 0.001)"  fill="#f5f5f5" stroke="#f5f5f5" strokeMiterlimit="10" strokeWidth="2"/>
      <path id="Path_2511" d="M4.3,12.027a.287.287,0,0,1-.246-.139A25.585,25.585,0,0,0,2.381,9.5c-.344-.453-.668-.881-.97-1.319A6.666,6.666,0,0,1,0,4.295a4.294,4.294,0,1,1,8.589,0A6.663,6.663,0,0,1,7.18,8.18c-.3.439-.626.867-.97,1.321a26.471,26.471,0,0,0-1.669,2.387A.285.285,0,0,1,4.3,12.027Zm0-9.737a2,2,0,1,0,2,2A2.015,2.015,0,0,0,4.295,2.29Z" transform="translate(11.098 9.873)" fill="#999"/>
    </g>
  </g>
</svg>



);
  const DateIcon = (p) => (<svg className={val_isOrange === 1 ? 'orangeDateIcon' : null} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">

    <g transform="translate(0 -0.001)">
        <circle fill="#f5f5f5" cx="15" cy="15" r="14" transform="translate(0 0.001)"/>
        <g transform="translate(9.741 10.101)">
            <path fill="#999"
                  d="M92.8,206.134a.663.663,0,0,0,.114.379c.186.24.482.173.711-.141v-4.791c-.035-.106-.067-.213-.106-.318a1.669,1.669,0,0,0-1.544-1.064h-.543c-.031-.311.084-.666-.295-.829h-.23c-.378.159-.3.5-.31.817H88.722c-.011-.311.078-.656-.3-.817h-.23c-.378.162-.272.513-.3.818H86.03c-.022-.3.078-.644-.283-.818h-.276c-.327.184-.28.5-.276.813a5.71,5.71,0,0,0-.808.05,1.585,1.585,0,0,0-1.319,1.3c0,.019-.025.035-.039.053v7a1.711,1.711,0,0,0,.636,1.019,6.155,6.155,0,0,0,.746.363h7.832a1.717,1.717,0,0,0,1.019-.636,6.293,6.293,0,0,0,.363-.746v-.415c-.128-.167-.267-.331-.507-.263s-.316.248-.329.491a.8.8,0,0,1-.8.738q-3.661,0-7.322,0a.808.808,0,0,1-.807-.8c0-2.164,0-2.707,0-4.872-.011,0,8.943-.019,8.929,0C92.79,204.855,92.793,204.737,92.8,206.134Z"
                  transform="translate(-83.029 -199.37)"/>
            <path  fill="#999" d="M131.873,279.118h-5.966a.346.346,0,1,0,0,.691h5.966a.346.346,0,1,0,0-.691Z"
                  transform="translate(-123.603 -273.293)"/>
            <path  fill="#999" d="M131.873,315.038h-5.966a.346.346,0,0,0,0,.691h5.966a.346.346,0,0,0,0-.691Z"
                  transform="translate(-123.603 -307.559)"/>
        </g>
    </g>
</svg>)
  const TimeIcon = (p) => (<svg className={val_isOrange === 1 ? 'orangeTimeIcon' : null} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">

    <g transform="translate(0 -0.001)">
        <circle  fill="#f5f5f5" cx="15" cy="15" r="14" transform="translate(0 0.001)"/>
        <g transform="translate(9.059 9.788)">
            <path  fill="#999"
                  d="M11.126,5a6.126,6.126,0,1,0,6.126,6.126A6.117,6.117,0,0,0,11.126,5ZM13.6,13.644a.625.625,0,0,1-.871,0l-2.26-2.26V7.709a.613.613,0,1,1,1.225,0v3.172L13.6,12.786A.616.616,0,0,1,13.6,13.644Z"
                  transform="translate(-5 -5)"/>
        </g>
    </g>
</svg>)


/*============================================================================================*/




  let history = useHistory();
  const {t, i18n} = useTranslation();
  const {apiKey} = useParams();
  const goHome = () => {
    //history.push('/')
    window.location.href = '/' + props.rdvState.company?.apikey
  }

  const [rdvCount, setRdvCount] = useState(0);
  const [rdvs, setRdvs] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    const rdvCount = params['rdvcount']
    setRdvCount(rdvCount)
    let items = []

    if(rdvCount > 0){
      for (let i = 1; i <= rdvCount; i++) {
        const rdvIndex = `rdvticket${i}`
        const dateIndex = `rdvdate${i}`
        let item = {
          'rdvticket': params[rdvIndex],
          'rdvdate': params[dateIndex],
        }
        items.push(item)
      }
      setRdvs(items)
    }
    
    console.log(params['rdvcount'], items);
  }, [location]);


  let is_oci_or_sonatel = props.rdvState.company?.apikey === "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09" || props.rdvState.company?.apikey === "amdGdnJsTnRUbVh5aERlWUFLWFFnQT09";
  let is_ambassade_apikey = props.rdvState.company?.apikey === "d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" || props.rdvState.company?.apikey === "cUJYR0dITTFYZUNVSHpLV3U2UzFOQT09";

  return <div className={'Success'} id={'Success'}>
    <div className={'text-center'}>
      <img src={confirmSvg} alt={''}/>
    </div>


    <div className={ val_isOrange === 1 ? 'orange-body confirmLabel text-center m-4' : 'confirmLabel text-center m-4'}>{t('payment_success')}</div>

    <div hidden={is_oci_or_sonatel} >
      <div hidden={val_isOrange === 1} className={val_isOrange === 1 ? 'orange-body confirmCongrat confirmCongratOrange text-center mb-4' : 'confirmCongrat text-center mb-4'}>{t('app_sent_msg')} <br/>{(is_ambassade_apikey) ? t('app_sent_email_msg_ambassade') : t('app_sent_email_msg')}</div>

      <div hidden={val_isOrange !== 1} className={val_isOrange === 1 ? 'orange-body confirmCongrat confirmCongratOrange text-center mb-4' : 'confirmCongrat text-center mb-4'}>{t('app_sent_msg')} <br/>{t('app_sent_email_msg_orange')}</div>
    </div>

  <div hidden={!is_oci_or_sonatel} >
    <div className={val_isOrange === 1 ? 'orange-body confirmCongrat confirmCongratOrange text-center mb-4' : 'confirmCongrat text-center mb-4'}>{t('app_sent_msg')} <br/>{t('app_sent_email_msg_orange')}</div>
  </div>



    <Row className={val_isOrange === 1 ? 'confirmLocation confirmLocationOrange':'confirmLocation'}>
      <Col xs={12}>
        <div className={ val_isOrange === 1 ? 'orange-body summary blackOrange' : 'summary'}>
          {t('rdv_resume')} {rdvCount} {t('rdv_label')}
        </div>
      </Col>

      {rdvs.map((rdv, i) => {
        return (<Col md={6} className={'agencyImage mb-3'}>
        <div className={'d-flex mb-2'}>
          <div className={'pr-3'}><DateIcon/></div>
          <div>
            <div className={ val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{t('rdv')}: {i + 1}</div>
            <div className={ val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{t('rdv_code')}: {rdv.rdvticket}</div>
            <div className={ val_isOrange === 1 ? 'orange-body confirmValue blackOrange' : 'confirmValue'}>
              {moment(rdv.rdvdate).locale(i18n.language).format("dddd Do MMMM YYYY, HH:mm:ss")}
            </div>
          </div>
        </div>
      </Col>)
      })}
      
      
    </Row>
    
    <div className={'text-center'}>
      <button style={{color: props.rdvState.company?.color_scheme.secondary_color, backgroundColor: props.rdvState.company?.color_scheme.primary_color, borderColor: props.rdvState.company?.color_scheme.primary_color}} className={val_isOrange === 1 ? 'buttonActive buttonActiveOrange next':'buttonActive next'} onClick={goHome}>{t('return_home')}</button>
    </div>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
