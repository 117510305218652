import React, {useEffect, useState} from 'react';
import './OperationSelection.css'
import {Col, Row} from "react-bootstrap";
import CovidMessage from "../CovidMessage";
import StartMessage from "../StartMessage";
import OperationSelector from "../OperationSelector";
import nextIcon from '../../assets/next.svg'
import backIcon from '../../assets/back.svg'
import {useHistory, useParams} from "react-router-dom";
import BranchSelector from "../BranchSelector";
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBranches: e => dispatch(rdvActions.getBranches(e))
  }
}
const OperationSelection = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const {apiKey} = useParams();
  const [selectedOp, setSelectedOp] = useState(props.selected ? props.selected : '')
  const [errMsg, setErrMsg] = useState(true)
  localStorage.removeItem('company_data_set_rdv_exist')
  const goBack = () => {
    history.push('/' + sessionStorage.getItem('rdv_apikey'))
  }
  const goNext = () => {
    if ((!props.rdvState.branch) || (!props.rdvState.operation)) {
      setErrMsg(false)
    } else history.push('/' + props.rdvState.company?.apikey + "/service")
  }
  var NextIcon = () => (<svg style={{marginLeft: 10}} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
    <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
      <path id="Path_891" data-name="Path 891" d="M5,0,0,4.674,5,9.335" transform="translate(14 9.335) rotate(180)"
            fill="none" stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(13.809 4.774) rotate(180)" fill="none"
            stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
    </g>
  </svg>)

let OLUX_apikey = "OVEvL2R1eTJqREpWMisrNmFqdDlOdz09";

let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

var BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} style={{marginRight: 10}} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#808080'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#808080'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)

useEffect(() => {
  if (props.rdvState.companyCanByassJoinQueuePage && !props.rdvState.isRdvLoad) {
    history.push('/' + apiKey +'/rdv-start')
  }
}, [props.rdvState.companyCanByassJoinQueuePage])

useEffect(() => {
  if(apiKey === OLUX_apikey) {
      dispatch({type: 'SET_OPERATION', payload: "currentqueue"});
      localStorage.setItem('rdv_prev_op', "currentqueue")
      history.push('/' + OLUX_apikey + '/operation')
  }
  window.scrollTo(0, 0)

}, [])


  useEffect(() => {
    let lop = localStorage.getItem('rdv_prev_op') ? localStorage.getItem('rdv_prev_op').toString() : null;
    let op = props.rdvState.operation ? props.rdvState.operation : null;
    if (op !== null) {
      dispatch({type: 'IS_LOADING', payload: true});
      props.getBranches(props.rdvState.operation)
    } else if (lop !== null) {
      dispatch({type: 'IS_LOADING', payload: true});
      dispatch({type: 'SET_OPERATION', payload: lop});
      props.getBranches(lop)
    }
    window.scrollTo(0, 0)
  }, [])

  
  
  let disableCadre = props.rdvState.company?.apikey == "UEFUVWkvWUxHcmIyMEZJNE1rQ3pkZz09";
  let OLUXCadre = apiKey === "OVEvL2R1eTJqREpWMisrNmFqdDlOdz09"


  return <div className={'OperationSelection'} id={'OperationSelection'}>

    <Row>
      <Col sm={12} lg={4} md={12} className={val_isOrange === 1 ? 'col-covid-message': null} >
        <StartMessage/>
      </Col>
      <Col sm={12} md={{span: 7, offset: 1}}>
        <div className={val_isOrange === 1 ? 'operationCardOrange' : 'operationCard'}>

<div hidden={disableCadre || OLUXCadre}>
          <div className={val_isOrange === 1 ? 'orange-header title' : 'title'}>{t('i_want')}</div>
          <div style={{marginBottom: 40}}>
            <OperationSelector state={selectedOp} setState={setSelectedOp} text={t('join_current')} label={'currentqueue'}  style={{marginRight: 5}}/>

            <OperationSelector state={selectedOp} setState={setSelectedOp} text={t('book_app')} label={'appointment'} style={{marginLeft: 5}}/>
            <div hidden={errMsg}>
              <div className={val_isOrange === 1 ? 'orange-body text-danger-orange' : 'text-danger'} hidden={props.rdvState.operation}>
                <small>{t('please_choose_operation')}</small></div>
            </div>
          </div>
          <hr/>
          </div>

          <div className={val_isOrange === 1 ? 'orange-header title' : 'title'}>{t('select_branch')}</div>
          <div style={{marginBottom: 36}}>
            <BranchSelector customPlaceholder={t('select_branch')}/>
            <div hidden={errMsg}>
              <div className={val_isOrange === 1 ? 'orange-body text-danger-orange' : 'text-danger'} hidden={props.rdvState.branch}><small>{t('please_choose_branch')}</small>
              </div>
            </div>
          </div>
          <div style={{textAlign: "right"}}>
            <button className={val_isOrange === 1 ? 'orange-header orange-no-radius orange-btn-primary orange-btn-notseleted buttonDefault back' : 'buttonDefault back'}
                    onClick={goBack}><BackIcon/>{t('back')}</button>


            &nbsp;
            <button style={{backgroundColor : props.rdvState.company?.color_scheme.primary_color, borderColor: props.rdvState.company?.color_scheme.primary_color, color: props.rdvState.company?.color_scheme.secondary_color}} className={val_isOrange === 1 ? 'orange-header orange-no-radius orange-btn-primary orange-btn-seleted buttonActive next' : 'buttonActive next'}
                    onClick={goNext}>{t('next')}<NextIcon/></button>
          </div>
        </div>
      </Col>
    </Row>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationSelection);
