import React from 'react';
import './CovidMessage.css'
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const CovidMessage = (props) => {
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  const {t} = useTranslation();
  return <div className={val_isOrange === 1 ? 'orange-header CovidMessage CovidMessage-orange' : 'CovidMessage'} id={'CovidMessage'}>
    <span className={val_isOrange === 1 ? 'orange-header' : ''} style ={{color: props.rdvState.company?.color_scheme.secondary_color}}>{t('covid_msg')}</span>
  </div>;
};

export default connect(mapStateToProps)(CovidMessage);
