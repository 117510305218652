import React, {useEffect, useState} from 'react';
import './LoadingAnimation.css'
//import loader from '../../assets/loader.svg'
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
      rdvState: state
    }
}


const LoadingAnimation = (props) => {
    const [loaderColor, setLoaderColor] = useState();
    const [loaderColorExist, setLoaderColorExist] = useState();
    const [hideDescription, setHideDescription] = useState(true);

    const {t} = useTranslation();

//var loaderColor = 'rgb(153,153,153)';

useEffect(() => {
    if(props.rdvState.companyCanByassJoinQueuePage && props.rdvState.waitingAvailability) {
        setHideDescription(false)
    } else {
        setHideDescription(true)
    }
}, [props.rdvState.waitingAvailability])


useEffect(() => {
    //setLoaderColorExist(false);
    let url = window.location.pathname.split('/')
    let apikey = url[1];
    let is_ambassade_apikey = apikey === "d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" || apikey === "cUJYR0dITTFYZUNVSHpLV3U2UzFOQT09"

    if(is_ambassade_apikey){
        setLoaderColor('rgb(71, 196, 49)');
        //setLoaderColorExist(true);
    }else if(props.rdvState.company === null){
        setLoaderColor('#999');
        //setLoaderColorExist(true);
    }else if(props.rdvState.company !== null && props.rdvState.company?.color_scheme.isOrange == 1){
        setLoaderColor('rgb(255,102,0)');
        //setLoaderColorExist(true);
    }else if(props.rdvState.company !== null && props.rdvState.company?.color_scheme.isOrange == 0 && props.rdvState.companyCanByassJoinQueuePage){
        setLoaderColor('rgb(220, 53, 69)');
        //setLoaderColorExist(true);
    }else{
        setLoaderColor('#0089e1');
    }
    
    }, [props.rdvState.company])


/*
    var loaderColor = 'rgb(153,153,153)';


    console.log(loaderColor);
    useEffect(() => {
    }, [])



        if(props.rdvState.company?.color_scheme.isOrange !== undefined && props.rdvState.company?.color_scheme.isOrange == 1){
          loaderColor = 'rgb(255,102,0)';
          console.log(loaderColor);
        }else if(props.rdvState.company !== undefined) {
          loaderColor = '#0089e1';
          console.log(loaderColor);
        }else{
          loaderColor = 'rgb(153,153,153)';
          console.log(loaderColor);
        }
*/

    //loaderColor = "#000";
//{loaderColorExist == true ? {borderLeftColor: loaderColor} : {borderLeftColor: ''}} 


    return <div className={props.isLoading ? 'LoadingAnimation isLoading' : 'LoadingAnimation notLoading'} id={'LoadingAnimation'}>
        <div style={{ borderLeftColor: loaderColor !== undefined ? loaderColor : '#999999' }} className={'loader'}></div>
        <p hidden={hideDescription} className={'loader-description'}>
           {t('waiting_availability')}
        </p>
    </div>;
};

export default connect(mapStateToProps)(LoadingAnimation);
