import React from 'react';
import './BranchAgent.css'
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import NameInitials from "../NameInitials";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const BranchAgent = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const handleSelect = () => {
    dispatch({type: 'SET_AGENT', payload: props.agent})
    //setState(!state)
    history.push('/' + props.rdvState.company?.apikey + '/availability')
  }
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  var divclass = '';
  if(val_isOrange === 1){
    divclass = divclass + ' orange-no-radius';
  }
  if(props.rdvState.agent?.agent === props.agent.agent){
    divclass = divclass + ' agent selected';
  }else{
    divclass = divclass + ' agent';
  }

  return <div className={'BranchAgent'} id={'BranchAgent'}>
    <div className={divclass}
         onClick={handleSelect}>
      <div className={'agentPhoto'}>
        {props.agent.photo !== 'https://manage.right-q.com/upload/company/55/userspic/' ? (
            <img src={props.agent.photo} alt={""}/>) : (<NameInitials data={props.agent}/>)}
      </div>
      <div className={'agentName'}>
        {props.agent.firstname + ' ' + props.agent.lastname}
      </div>
    </div>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchAgent);
