import React, {useEffect, useState} from 'react';
import './DownScreen.css'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch} from 'react-redux';

import {
    Row, Col, Form, Modal, Button
} from "react-bootstrap";

import * as rdvActions from "../../actions";


const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkOrderNumber: (e) => dispatch(rdvActions.checkOrderNumber(e)),
    }
}



const DownScreen = (props) => {

    const {apiKey} = useParams();
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();

let comp_name = decodeURIComponent(escape(props.rdvState.company?.company))
let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

    return <div className={'DownScreen'} id={'DownScreen'}>
        <MetaTags>
            <title>{comp_name === 'undefined' ? 'RightQ RDV':comp_name}</title>
            <meta property="og:image" content='/favicon-og.png' />
            <meta property="og:type" content="website"></meta>
            <meta name="og:site_name" content={comp_name}></meta>
            <meta property="og:title" content={comp_name} />
            <meta name="twitter:title" content={comp_name}></meta>
            <meta property="twitter:title" content={comp_name}></meta>
            <meta name="description" content={t('og_description')} />
            <meta name="og:description" content={t('og_description')}></meta>
        </MetaTags>

        <Row>
            <Col sm={12} lg={12} md={12}>
                <div className={val_isOrange === 1 ? 'orange-header RdvStartMessage RdvStartMessage-orange' : 'RdvStartMessage'} id={'RdvStartMessage'}>
                    <p className={val_isOrange === 1 ? 'orange-header bigText' : 'bigText'} style ={{color: '#212529', fontSize: '3rem'}}>{t('maintenance_text')}</p>
                </div>
            </Col>
        </Row>
        
        <Row>
            <Col sm={12} lg={12} md={12}>
                <div className={val_isOrange === 1 ? 'orange-header RdvStartMessage RdvStartMessage-orange' : 'RdvStartMessage'} id={'RdvStartMessage'}>
                <Link to={"/" + apiKey + "/rdv-launch"}>
                    <p className={val_isOrange === 1 ? 'orange-header bigText' : 'bigText'} style ={{color: '#212529', fontSize: '1rem'}}>{t('maintenance_participation_text')}</p>
                </Link>
                </div>
            </Col>
        </Row>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(DownScreen);
