import React, { useEffect, useState } from "react";
import "./RdvGroupInfosForm.css";
import backIcon from "../../assets/back.svg";
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import serviceSvg from "../../assets/service.svg";
import branchSvg from "../../assets/branch.svg";
import locationSvg from "../../assets/slocation.svg";
import dateSvg from "../../assets/date.svg";
import timeSvg from "../../assets/time.svg";
import countries from "../../datas/countries";
import { connect, useDispatch } from "react-redux";
import i18next from "i18next";
import moment from "moment";
import * as rdvActions from "../../actions";
import NameInitials from "../NameInitials";
import { useTranslation } from "react-i18next";

import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { toHaveAttribute } from "@testing-library/jest-dom";

const mapStateToProps = (state) => {
  return {
    rdvState: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTicket: (e) => dispatch(rdvActions.getTicket(e)),
    getRdv: (e) => dispatch(rdvActions.getRdv(e)),
    getExistingOldRdv: (e) => dispatch(rdvActions.getExistingOldRdv(e)),
  };
};

const RdvGroupInfosForm = (props) => {
  let defaultFirstname = props.rdvState.rdvData?.firstname ? props.rdvState.rdvData?.firstname : null
  let defaultLastname = props.rdvState.rdvData?.lastname ? props.rdvState.rdvData?.lastname : null
  let defaultFullname = defaultFirstname && defaultLastname ? `${defaultFirstname} ${defaultLastname}` : null
  let defaultCountry = props.rdvState.rdvData?.code_country ? props.rdvState.rdvData?.code_country : null
  let defaultMobile = props.rdvState.rdvData?.phone ? props.rdvState.rdvData?.phone : null
  let defaultEmail = props.rdvState.rdvData?.email ? props.rdvState.rdvData?.email : null
  localStorage.removeItem('company_data_set_rdv_exist')
  let is_ambassade_apikey = props.rdvState.company?.apikey === "d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" || props.rdvState.company?.apikey === "cUJYR0dITTFYZUNVSHpLV3U2UzFOQT09";

  const [selectedUserCountry, setSelectedUserCountry] = useState(null);
  const [userCountries, setUserCountries] = useState(props.rdvState.countries);

  const handleUserCountrySelect = (e) => {
    let value = e.target.value
    setSelectedUserCountry(value);
    setUserCountryValid(true)
    setUserCountryInvalid(false)
  };

  let history = useHistory();
  let dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { apiKey } = useParams();
  i18next.on("languageChanged", function (lng) {
    moment.locale(lng);
  });
  const [country, setCountry] = useState(
    countries.filter((c) => {
      //return c.alpha2Code === props.rdvState.company?.Country
      return c.iso2 === props.rdvState.company?.Country.toLowerCase();
    })[0]
  );

  const [phone, setPhone] = useState();
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [phoneValid, setPhoneValid] = useState(null);
  const [phoneIsInValid, setPhoneIsInValid] = useState(null);
  const [phoneErrMsg, setPhoneErrMsg] = useState(t("phone_form_error"));

  const [name, setName] = useState();
  const [nameValid, setNameValid] = useState(null);
  const [nameIsInValid, setNameIsInValid] = useState(null);
  const [nameErrMsg, setNameErrMsg] = useState(t("name_form_error"));
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState(null);
  const [emailIsInValid, setEmailIsInValid] = useState(null);
  const [emailErrMsg, setEmailErrMsg] = useState(t("email_form_error"));
  
  const [userCountryValid, setUserCountryValid] = useState(props.rdvState.companyCanByassJoinQueuePage ? null : true);
  const [userCountryInvalid, setUserCountryInvalid] = useState(props.rdvState.companyCanByassJoinQueuePage ? null : false);
  const [userCountryErrMsg, setUserCountryErrMsg] = useState(t("user_country_form_error_invalid"));

  const [displayRdvExistForThisTimeError, setDisplayRdvExistForThisTimeError] = useState(false);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(props.rdvState.hadOldRdv);

  const [note, setNote] = useState();
  const [noteValid, setNoteValid] = useState(null);
  const [noteIsInValid, setNoteIsInValid] = useState(null);
  const [noteErrMsg, setNoteErrMsg] = useState("");

  const filterCountries = (e) => {
    if (e.target.value.length > 2) {
      let fc = setFilteredCountries(
        countries.filter((c) => {
          return (
            c && c.name.toLowerCase().includes(e.target.value.toLowerCase())
          );
        })
      );
    } else setFilteredCountries(countries);
  };

  const countryFlag = (
    <span>
      <img
        style={{ height: 14 }}
        src={"/countries/" + country?.iso2.toLowerCase() + ".svg"}
        alt={""}
      />
      <small style={{ fontSize: 14, paddingLeft: 10 }}>
        {" "}
        +{country?.callingCodes[0]}
      </small>
    </span>
  );
  const goBack = () => {
    dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: false});

    if (props.rdvState.operation === "currentqueue") {
      history.push("/" + sessionStorage.getItem("rdv_apikey") + "/service");
    } else
      if(props.rdvState.companyCanByassJoinQueuePage) {
        dispatch({type: 'WAITING_AVAILABILITY', payload: true});
      }
      history.push(
        "/" + sessionStorage.getItem("rdv_apikey") + "/rdv-group-availability"
      );
  };
  const handleConfirm = () => {
    let condition = phoneValid && nameValid && emailValid && userCountryValid;
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = phoneValid && nameValid && emailValid && userCountryValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = phoneValid && nameValid && userCountryValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = nameValid && emailValid && userCountryValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = nameValid && userCountryValid;
    }


    if (condition) {
      let phone_num = "";
      let email_add = "";
      if (phone != undefined && phone != "" && phone != null) {
        phone_num = phone.replace("+", "");
      } else {
        phone_num = "null";
      }
      if (email == undefined || email == null) {
        email_add = "";
      } else {
        email_add = email;
      }

      dispatch({ type: "IS_LOADING", payload: true });
      let visitor = {
        country: country,
        /*"phone": '+' + country?.callingCodes[0] + '' + phone,*/
        phone: phone_num,
        name: name,
        email: email_add,
        language: i18next.language,
      };
      dispatch({ type: "SET_VISITOR", payload: visitor });

      if (props.rdvState.operation === "currentqueue") {
        let data = {
          ApiKey: props.rdvState.company?.apikey,
          StoreId: props.rdvState.branch.id,
          ServiceId: props.rdvState.service.id,
          Fullname: name,
          /*"Mobile": country.callingCodes + '' + phone,*/
          Mobile: phone_num,
          Email: email_add,
          language: i18next.language,
          branch: props.rdvState.branch.id,
          service: props.rdvState.service.id,
        };
        if (note != undefined && note != "" && note != null) {
          data["Note"] = note;
        }
        props.getTicket(data);
      }
      var agent = "0";
      if (props.rdvState.company?.AllowSelectAgents != 0) {
        agent = props.rdvState.agent?.agent;
      }
      if (props.rdvState.operation === "appointment") {
        let book_date_data = props.rdvState.date + " " + props.rdvState.slot + ":00"
        let data = {
          fullname: name,
          mobile: phone_num,
          code_country: country.callingCodes[0],
          email: email_add,
          comment: "",
          language: i18next.language,
          branch: props.rdvState.branch.office,
          service: props.rdvState.service.service,
          agent: agent,
          user_country: selectedUserCountry,
          bookdate: [book_date_data],
          allrdvbyoneagent: 0,
          numberofrdv: props.rdvState.userNumber
        };
        if (note != undefined && note != "" && note != null) {
          data["note"] = note;
        }
        dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: false});
        setDisplayRdvExistForThisTimeError(false)
        props.getRdv(data);
      } else {
        let data = {};
      }
    } else {
      if (props.rdvState.company?.rdv_phone_required == 1) {
        if (!phoneValid) {
          setPhoneValid(false);
          setPhoneIsInValid(true);
        }
      }
      if (!nameValid) {
        setNameValid(false);
        setNameIsInValid(true);
      }
      if (!userCountryValid) {
        setUserCountryValid(false);
        setUserCountryInvalid(true);
      }
      if (props.rdvState.company?.rdv_email_required == 1) {
        if (!emailValid) {
          setEmailValid(false);
          setEmailIsInValid(true);
        }
      }
    }
  };

  const handleConfirmHadOldRdv = () => {
    if (phone == undefined || phone == "" || phone == null) {
      if(props.rdvState.hadOldRdv){
        setPhone("")
        setPhoneValid(true);
        setPhoneIsInValid(false);
      }
    }

    let condition = phoneValid && nameValid && emailValid;
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = phoneValid && nameValid && emailValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 1 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = phoneValid && nameValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 1
    ) {
      condition = nameValid && emailValid;
    }
    if (
      props.rdvState.company?.rdv_phone_required == 0 &&
      props.rdvState.company?.rdv_email_required == 0
    ) {
      condition = nameValid;
    }

    if (condition) {
      let phone_num = "";
      let email_add = "";
      if (phone != undefined && phone != "" && phone != null) {
        phone_num = phone.replace("+", "");
      } else {
        phone_num = "null";
      }
      if (email == undefined || email == null) {
        email_add = "";
      } else {
        email_add = email;
      }

      dispatch({ type: "IS_LOADING", payload: true });
      let visitor = {
        country: country,
        /*"phone": '+' + country?.callingCodes[0] + '' + phone,*/
        phone: phone_num,
        name: name,
        email: email_add,
        language: i18next.language,
      };
      dispatch({ type: "SET_VISITOR", payload: visitor });

      if (props.rdvState.operation === "currentqueue") {
        let data = {
          ApiKey: props.rdvState.company?.apikey,
          StoreId: props.rdvState.branch.id,
          ServiceId: props.rdvState.service.id,
          Fullname: name,
          /*"Mobile": country.callingCodes + '' + phone,*/
          Mobile: phone_num,
          Email: email_add,
          language: i18next.language,
          branch: props.rdvState.branch.id,
          service: props.rdvState.service.id,
        };
        if (note != undefined && note != "" && note != null) {
          data["Note"] = note;
        }
        props.getTicket(data);
      }
      var agent = "0";
      if (props.rdvState.company?.AllowSelectAgents != 0) {
        agent = props.rdvState.agent?.agent;
      }
      if (props.rdvState.operation === "appointment") {
        let data = {
          fullname: name,
          mobile: phone_num,
          code_country: country.callingCodes[0],
          email: email_add,
          bookdate: props.rdvState.date + " " + props.rdvState.slot + ":00",
          comment: "",
          language: i18next.language,
          note: undefined,
          branch: props.rdvState.rdvData?.branch.office,
          service: props.rdvState.rdvData?.service.service,
          agent: agent,
          order_number: props.rdvState.rdvData?.commandnumber,
          quantity: props.rdvState.rdvData?.quantity,
          user_country: selectedUserCountry
        };
        if (note != undefined && note != "" && note != null) {
          data["note"] = note;
        }
        dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: false});
        setDisplayRdvExistForThisTimeError(false)
        props.getExistingOldRdv(data);
      } else {
        let data = {};
      }
    } else {
      if (props.rdvState.company?.rdv_phone_required == 1) {
        if (!phoneValid) {
          setPhoneValid(false);
          setPhoneIsInValid(true);
        }
      }
      if (!nameValid) {
        setNameValid(false);
        setNameIsInValid(true);
      }
      if (props.rdvState.company?.rdv_email_required == 1) {
        if (!emailValid) {
          setEmailValid(false);
          setEmailIsInValid(true);
        }
      }
    }
  };
  
  const selectCountry = (c) => {
    setCountry(c);
  };
  useEffect(() => {
    
    if (props.rdvState.service !== null) {
      //console.log(apiKey)
    } else goBack();

    window.scrollTo(0, 0);

    if(props.rdvState.hadOldRdv){
      setName(defaultFullname)
      setNameValid(true);
      setNameIsInValid(false);

      setEmail(defaultEmail)
      setEmailValid(true);
      setEmailIsInValid(false);

      /*setPhone(defaultMobile)
      setPhoneValid(true);
      setPhoneIsInValid(false);*/

      setCountry(countries.filter((c) => {
        return c.iso2 === defaultCountry.toLowerCase();
      })[0])
    }
  }, []);


  useEffect(() => {
    if(props.rdvState.companyCanByassJoinQueuePage) {
      if(nameValid && emailValid && phoneValid && userCountryValid &&
        !nameIsInValid && !emailIsInValid && !phoneIsInValid && !userCountryInvalid) {
          setDisabledSubmitBtn(false)
      } else {
        setDisabledSubmitBtn(true)
      }
    }
    
  }, [nameValid, emailValid, phoneValid, userCountryValid]);

  useEffect(() => {
    if (props.rdvState.ticket !== null) {
      history.push("/" + props.rdvState.company?.apikey + "/success");
    } else if (props.rdvState.rdv !== null && props.rdvState.paymentUrl !== null) {
      window.location.href = props.rdvState.paymentUrl
    } else if (props.rdvState.rdv !== null && props.rdvState.paymentUrl === null) {
      history.push("/" + props.rdvState.company?.apikey + "/sent");
    }
  }, [props.rdvState.ticket, props.rdvState.rdv, props.rdvState.paymentUrl]);

  useEffect(() => {
    if(props.rdvState.hasRdvForThisSlotTime) {
      setDisplayRdvExistForThisTimeError(true)
    }
  }, [props.rdvState.hasRdvForThisSlotTime]);

  const handlePhoneInter = (e) => {
    //console.log(e)
    let tphone1 = e;
    if (e?.length > 0) {
      dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: false});
      setDisplayRdvExistForThisTimeError(false)
      if (isValidPhoneNumber(tphone1)) {
        setPhone(e);
        setPhoneValid(true);
        setPhoneIsInValid(false);
      } else {
        setPhoneErrMsg(t("phone_form_error_invalid"));
        setPhoneValid(false);
        setPhoneIsInValid(true);
      }
    } else {
      setPhone("");
      setPhoneErrMsg(t("phone_form_error"));
      setPhoneValid(null);
      setPhoneIsInValid(null);
    }
  };

  /*
    const handlePhone = (e) => {
        let tphone = e.target.value;
        if (e.target.value.length > 0) {
            if (tphone.match('^[0-9]+$') !== null) {
                setPhone(e.target.value)
                setPhoneValid(true)
                setPhoneIsInValid(false)
            } else {
                setPhoneErrMsg(t('phone_form_error_invalid'))
                setPhoneValid(false)
                setPhoneIsInValid(true)
            }
        } else {
            setPhoneErrMsg(t('phone_form_error'))
            setPhoneValid(null)
            setPhoneIsInValid(null)
        }

    }

*/

  const handleName = (e) => {
    e.target.value = e.target.value.replace(/  +/g, " ").replace(/^\s+/g, "");
    //setName(e.target.value)
    if (e.target.value.length > 0) {
      if (e.target.value.match("^[a-zA-Z\\d]+(?: [a-zA-Z\\d]+)*$") !== null) {
        setName(e.target.value);
        setNameValid(true);
        setNameIsInValid(false);
      } else {
        setNameErrMsg(t("name_form_error_invalid"));
        setNameValid(null);
        setNameIsInValid(null);
      }
    } else {
      setNameErrMsg(t("name_form_error"));
      setNameValid(null);
      setNameIsInValid(null);
    }
  };
  const handleNote = (e) => {
    e.target.value = e.target.value.replace(/  +/g, " ").replace(/^\s+/g, "");
    //setName(e.target.value)
    setNote(e.target.value);
    setNoteValid(true);
    setNoteIsInValid(false);
  };
  const handleEmail = (e) => {
    let temail = e.target.value;
    if (e.target.value.length > 0) {
      dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: false});
      setDisplayRdvExistForThisTimeError(false)
      if (
        temail
          .toLowerCase()
          .match(
            "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"
          ) !== null
      ) {
        setEmail(e.target.value);
        setEmailValid(true);
        setEmailIsInValid(false);
      } else {
        setEmailErrMsg(t("email_form_error_invalid"));
        setEmailValid(false);
        setEmailIsInValid(true);
      }
    } else {
      setEmail("");
      setEmailErrMsg(t("email_form_error"));
      setEmailValid(null);
      setEmailIsInValid(null);
    }
  };
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  let rdv_phone_required = props.rdvState.company?.rdv_phone_required;
  let rdv_email_required = props.rdvState.company?.rdv_email_required;
  const BackIcon = () => (
    <svg
      className={val_isOrange === 1 ? "orangeSvg_1" : ""}
      style={{ marginRight: 10 }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12.162"
      viewBox="0 0 16 12.162"
    >
      <g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
        <path
          id="Path_891"
          data-name="Path 891"
          d="M5,9.335,0,4.661,5,0"
          fill="none"
          stroke={
            props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color
              ? "#FFF"
              : "#808080"
          }
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_262"
          data-name="Line 262"
          x2="13.809"
          transform="translate(0.191 4.774)"
          fill="none"
          stroke={
            props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color
              ? "#FFF"
              : "#808080"
          }
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );

  /*============================================================================================*/

  const ServiceIcon = (p) => (
    <svg
      className={val_isOrange === 1 ? "orangeServiceIcon" : null}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_3565" data-name="Group 3565" transform="translate(0 -0.001)">
        <circle
          id="Ellipse_27"
          data-name="Ellipse 27"
          cx="15"
          cy="15"
          r="14"
          transform="translate(0 0.001)"
          fill="#f5f5f5"
        />
        <path
          id="star"
          d="M10.971,4.517a.585.585,0,0,0-.5-.405L7.293,3.82,6.038.855a.583.583,0,0,0-1.076,0L3.707,3.82.531,4.111a.587.587,0,0,0-.5.405A.6.6,0,0,0,.2,5.144L2.6,7.268l-.708,3.146a.593.593,0,0,0,.227.609.579.579,0,0,0,.643.029L5.5,9.4l2.738,1.653a.58.58,0,0,0,.643-.029.593.593,0,0,0,.227-.609L8.4,7.268l2.4-2.125A.594.594,0,0,0,10.971,4.517ZM5.552,9.368"
          transform="translate(9.576 9.216)"
          fill="#999"
        />
      </g>
    </svg>
  );
  const BranchIcon = (p) => (
    <svg
      className={val_isOrange === 1 ? "orangeBranchIcon" : null}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_3349" data-name="Group 3349" transform="translate(0 -0.001)">
        <circle
          id="Ellipse_27"
          data-name="Ellipse 27"
          cx="15"
          cy="15"
          r="14"
          transform="translate(0 0.001)"
          fill="#f5f5f5"
        />
        <path
          id="building"
          d="M54.941,10.705h-.728V1.661a.369.369,0,0,0-.369-.369H51.076V.369A.369.369,0,0,0,50.707,0H48.146a.369.369,0,0,0-.369.369v.923h-.3a.369.369,0,0,0-.369.369v9.044H46.38a.376.376,0,0,0-.379.351.369.369,0,0,0,.369.387H49a.185.185,0,0,0,.185-.185V9.136h-.151a.376.376,0,0,1-.379-.351.369.369,0,0,1,.369-.387h3.266a.376.376,0,0,1,.379.351.369.369,0,0,1-.369.387h-.161v2.123a.185.185,0,0,0,.185.185h2.63a.369.369,0,0,0,.369-.387A.376.376,0,0,0,54.941,10.705ZM49.761,7.637h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm2.192,4.153h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm0-1.384h-.415a.369.369,0,0,1,0-.738h.415a.369.369,0,0,1,0,.738Zm-2.03,7.775V9.321a.185.185,0,0,1,.185-.185h1.107a.185.185,0,0,1,.185.185v1.938a.185.185,0,0,1-.185.185H50.107A.185.185,0,0,1,49.922,11.259Z"
          transform="translate(-35 9.558)"
          fill="#999"
        />
      </g>
    </svg>
  );
  const LocationIcon = (p) => (
    <svg
      className={val_isOrange === 1 ? "orangeLocationIcon" : null}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 32 32"
    >
      <g id="Group_3552" transform="translate(1 1)">
        <g id="Group_3549" transform="translate(0 -0.001)">
          <circle
            id="Ellipse_27-4"
            cx="15"
            cy="15"
            r="14"
            transform="translate(0 0.001)"
            fill="#f5f5f5"
            stroke="#f5f5f5"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Path_2511"
            d="M4.3,12.027a.287.287,0,0,1-.246-.139A25.585,25.585,0,0,0,2.381,9.5c-.344-.453-.668-.881-.97-1.319A6.666,6.666,0,0,1,0,4.295a4.294,4.294,0,1,1,8.589,0A6.663,6.663,0,0,1,7.18,8.18c-.3.439-.626.867-.97,1.321a26.471,26.471,0,0,0-1.669,2.387A.285.285,0,0,1,4.3,12.027Zm0-9.737a2,2,0,1,0,2,2A2.015,2.015,0,0,0,4.295,2.29Z"
            transform="translate(11.098 9.873)"
            fill="#999"
          />
        </g>
      </g>
    </svg>
  );
  const DateIcon = (p) => (
    <svg
      className={val_isOrange === 1 ? "orangeDateIcon" : null}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g transform="translate(0 -0.001)">
        <circle
          fill="#f5f5f5"
          cx="15"
          cy="15"
          r="14"
          transform="translate(0 0.001)"
        />
        <g transform="translate(9.741 10.101)">
          <path
            fill="#999"
            d="M92.8,206.134a.663.663,0,0,0,.114.379c.186.24.482.173.711-.141v-4.791c-.035-.106-.067-.213-.106-.318a1.669,1.669,0,0,0-1.544-1.064h-.543c-.031-.311.084-.666-.295-.829h-.23c-.378.159-.3.5-.31.817H88.722c-.011-.311.078-.656-.3-.817h-.23c-.378.162-.272.513-.3.818H86.03c-.022-.3.078-.644-.283-.818h-.276c-.327.184-.28.5-.276.813a5.71,5.71,0,0,0-.808.05,1.585,1.585,0,0,0-1.319,1.3c0,.019-.025.035-.039.053v7a1.711,1.711,0,0,0,.636,1.019,6.155,6.155,0,0,0,.746.363h7.832a1.717,1.717,0,0,0,1.019-.636,6.293,6.293,0,0,0,.363-.746v-.415c-.128-.167-.267-.331-.507-.263s-.316.248-.329.491a.8.8,0,0,1-.8.738q-3.661,0-7.322,0a.808.808,0,0,1-.807-.8c0-2.164,0-2.707,0-4.872-.011,0,8.943-.019,8.929,0C92.79,204.855,92.793,204.737,92.8,206.134Z"
            transform="translate(-83.029 -199.37)"
          />
          <path
            fill="#999"
            d="M131.873,279.118h-5.966a.346.346,0,1,0,0,.691h5.966a.346.346,0,1,0,0-.691Z"
            transform="translate(-123.603 -273.293)"
          />
          <path
            fill="#999"
            d="M131.873,315.038h-5.966a.346.346,0,0,0,0,.691h5.966a.346.346,0,0,0,0-.691Z"
            transform="translate(-123.603 -307.559)"
          />
        </g>
      </g>
    </svg>
  );
  const TimeIcon = (p) => (
    <svg
      className={val_isOrange === 1 ? "orangeTimeIcon" : null}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g transform="translate(0 -0.001)">
        <circle
          fill="#f5f5f5"
          cx="15"
          cy="15"
          r="14"
          transform="translate(0 0.001)"
        />
        <g transform="translate(9.059 9.788)">
          <path
            fill="#999"
            d="M11.126,5a6.126,6.126,0,1,0,6.126,6.126A6.117,6.117,0,0,0,11.126,5ZM13.6,13.644a.625.625,0,0,1-.871,0l-2.26-2.26V7.709a.613.613,0,1,1,1.225,0v3.172L13.6,12.786A.616.616,0,0,1,13.6,13.644Z"
            transform="translate(-5 -5)"
          />
        </g>
      </g>
    </svg>
  );

  /*============================================================================================*/

  const [phoneValue, setValue] = useState(defaultMobile);

  return (
    <div className={"RdvGroupInfosForm"} id={"RdvGroupInfosForm"}>
      <Row>
        <Col
          className={
            val_isOrange === 1
              ? "serviceTop orange-service-header"
              : "serviceTop"
          }
        >
          <button
            className={
              val_isOrange === 1
                ? "orange-body orange-btn-white buttonDefault back"
                : "buttonDefault back"
            }
            onClick={goBack}
          >
            <BackIcon />
            {t("back")}
          </button>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12} className={"serviceDetails"}>
          <div
            className={val_isOrange === 1 ? "orange-header summary" : "summary"}
          >
            {t("summary")}
          </div>
          <div>
            <div
              hidden={
                props.rdvState.operation === "currentqueue" ||
                props.rdvState.company?.AllowSelectAgents == 0
              }
            >
              <div className={"d-flex align-items-center mb-4 mt-4"}>
                <div className={"pr-2"}>
                  {props.rdvState.agent?.photo !==
                  "https://manage.right-q.com/upload/company/55/userspic/" ? (
                    <img
                      style={{ borderRadius: "50%" }}
                      src={props.rdvState.agent?.photo}
                      alt={""}
                    />
                  ) : (
                    <NameInitials size={32} data={props.rdvState.agent} />
                  )}
                </div>
                <div>
                  <div
                    className={
                      val_isOrange === 1 ? "orange-body label" : "label"
                    }
                  >
                    {t("agent")}:
                  </div>
                  <div
                    className={
                      val_isOrange === 1 ? "orange-body detail" : "detail"
                    }
                  >
                    {props.rdvState.agent?.firstname +
                      " " +
                      props.rdvState.agent?.lastname}
                  </div>
                </div>
              </div>
            </div>

            <div className={"d-flex align-items-center mb-4 mt-4"}>
              <div className={"pr-2"}>
                <ServiceIcon />
              </div>
              <div>
                <div
                  className={val_isOrange === 1 ? "orange-body label" : "label"}
                >
                  {t("service")}:
                </div>
                <div
                  className={
                    val_isOrange === 1 ? "orange-body detail" : "detail"
                  }
                >
                  {props.rdvState.service?.translate
                    ? props.rdvState.service?.translate[i18next.language]
                    : props.rdvState.service?.name
                    ? props.rdvState.service?.name[i18next.language]
                    : ""}
                </div>
              </div>
            </div>

            {/*<div hidden={props.rdvState.operation === 'appointment'}>*/}
            {/*    <div className={'d-flex align-items-center mb-4 mt-4'}>*/}
            {/*        <div className={'pr-2'}>*/}
            {/*            <img src={ticketSvg} alt={''}/>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <div className={'label'}>Your ticket number is:</div>*/}
            {/*            <div className={'detail'}>####</div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
            <div className={"d-flex align-items-center mb-4 mt-4"}>
              <div className={"pr-2"}>
                <DateIcon />
              </div>
              <div>
                <div
                  className={val_isOrange === 1 ? "orange-body label" : "label"}
                >
                  {t("date")}:
                </div>
                <div
                  className={
                    val_isOrange === 1 ? "orange-body detail" : "detail"
                  }
                >
                  {props.rdvState.date
                    ? moment(props.rdvState.date, "YYYY-MM-DD")
                        .locale(i18n.language)
                        .format("dddd Do, MMMM YYYY")
                    : moment().format("dddd Do, MMMM YYYY")}
                </div>
              </div>
            </div>
            <div hidden={props.rdvState.operation === "currentqueue"}>
              <div className={"d-flex align-items-center mb-4 mt-4"}>
                <div className={"pr-2"}>
                  <TimeIcon />
                </div>
                <div>
                  <div
                    className={
                      val_isOrange === 1 ? "orange-body label" : "label"
                    }
                  >
                    {t("time")}:
                  </div>
                  <div
                    className={
                      val_isOrange === 1 ? "orange-body detail" : "detail"
                    }
                  >
                    {props.rdvState.slot}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={"d-flex align-items-center mb-4 mt-4"}>
                <div className={"pr-2"}>
                  <ServiceIcon />
                </div>
                <div>
                  <div
                    className={
                      val_isOrange === 1 ? "orange-body label" : "label"
                    }
                  >
                  </div>
                  <div
                    className={
                      val_isOrange === 1 ? "orange-body detail" : "detail"
                    }
                  >
                  <p className="mb-1">{t('total_to_pay')}</p>
                  <p className="mb-1">{t('amount')}</p>
                  <p hidden={props.rdvState.userNumber > 1}>{props.rdvState.service?.amount * props.rdvState.userNumber} €</p>
                  <p hidden={props.rdvState.userNumber < 2}>{props.rdvState.service?.amount * props.rdvState.userNumber} € ({props.rdvState.service?.amount} € x {props.rdvState.userNumber})</p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{visibility: props.rdvState.companyCanByassJoinQueuePage ? 'hidden' : 'visible' }} className={"d-flex align-items-center mb-4 mt-4"}>
              <div className={"pr-2"}>
                <BranchIcon />
              </div>
              <div>
                <div
                  className={val_isOrange === 1 ? "orange-body label" : "label"}
                >
                  {props.rdvState.companyCanByassJoinQueuePage ? t('rdv_exist_key_place')  : t('branch')}:
                </div>
                <div className={"detail"}>
                  {props.rdvState.branch?.Name}
                  {props.rdvState.branch?.name}
                </div>
              </div>
            </div>
            <div style={{visibility: props.rdvState.companyCanByassJoinQueuePage ? 'hidden' : 'visible' }} className={"d-flex align-items-center mb-4 mt-4"}>
              <div className={"pr-2"}>
                <LocationIcon />
              </div>
              <div>
                <div
                  className={val_isOrange === 1 ? "orange-body label" : "label"}
                >
                  {t("address")}:
                </div>
                <div
                  className={
                    val_isOrange === 1 ? "orange-body detail" : "detail"
                  }
                >
                  {props.rdvState.branch?.address}
                  {props.rdvState.branch?.Address}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} xs={12} className={"infosForm"}>
          <p className={"font-weight-bold"}>
            {t("infos_payment")}
          </p>
          <p className={""}>
            {t("enter_infos_ravip")}
          </p>
          <div className={"mt-5 mb-3"}>
            {/*
                    <Form.Group controlId="">
                        <Form.Label className={val_isOrange === 1 ? 'orange-body' : ''}>{t('mobile')}</Form.Label>
                        <InputGroup>
                            <DropdownButton
                                style={{borderColor: props.rdvState.company?.color_scheme.primary_color }}
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                title={countryFlag}
                                className={phoneIsInValid ? 'is-invalid' : phoneValid ? 'is-valid' : null, val_isOrange === 1 ? 'orangeDropdownButton' : ''}
                                id="input-group-dropdown-1"
                            >
                                <FormControl
                                    autoFocus
                                    className="mx-3 my-2 w-auto"
                                    style={{width: '89%', borderRadius: val_isOrange === 1 ? 0:'', height: val_isOrange === 1 ? 40:null}}
                                    placeholder={t('type_filter')}
                                    onChange={filterCountries}
                                />
                                {filteredCountries.map((c) => {
                                    return (<Dropdown.Item key={c.name} href="#" onClick={() => {
                                        selectCountry(c)
                                    }}><img style={{height: 14}} src={c.flag}
                                            alt={''}/> {c.name} (+{c.callingCodes})</Dropdown.Item>)
                                })}
                            </DropdownButton>
                            <Form.Control
                                type="tel"
                                style={{height: val_isOrange === 1 ? 40:51, borderColor: props.rdvState.company?.color_scheme.primary_color, borderRadius: val_isOrange === 1 ? 0:''}}
                                placeholder={'981276345'}
                                size={"lg"}
                                isInvalid={phoneIsInValid}
                                isValid={phoneValid}
                                onChange={handlePhone}
                            />
                            <Form.Control.Feedback type="invalid">{phoneErrMsg}</Form.Control.Feedback>
                        </InputGroup>

                        <Form.Text className="text-muted">

                        </Form.Text>
                    </Form.Group>
                    */}
            <Form.Group controlId="">
              <Form.Label className={val_isOrange === 1 ? "orange-body" : ""}>
                {t("fullname")}
                <span style={{ color: "#b30000" }} title={t("required")}>
                  *
                </span>
              </Form.Label>
              <Form.Control
                style={{
                  borderColor:
                    props.rdvState.company?.color_scheme.primary_color,
                  borderRadius: val_isOrange === 1 ? 0 : "",
                  height: val_isOrange === 1 ? 40 : 51,
                }}
                type="text"
                placeholder={t("enter_your_name")}
                size={"lg"}
                isValid={nameValid}
                isInvalid={nameIsInValid}
                onChange={handleName}
                defaultValue={defaultFullname}
                readOnly={props.rdvState.hadOldRdv && defaultFullname}
              />
              <Form.Control.Feedback type="invalid">
                {nameErrMsg}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="">
              <Form.Label className={val_isOrange === 1 ? "orange-body" : ""}>
                {t("mobile")}
                <span
                  hidden={rdv_phone_required === 0}
                  title={t("required")}
                  style={{ color: "#b30000" }}
                >
                  *
                </span>{" "}
              </Form.Label>
              <PhoneInput
                className={
                  (phoneIsInValid
                    ? "is-invalid"
                    : phoneValid
                    ? "is-valid"
                    : null,
                  val_isOrange === 1
                    ? "PhoneInput-orange form-control form-control-lg"
                    : "form-control form-control-lg")
                }
                defaultCountry={defaultCountry ? defaultCountry : props.rdvState.company?.Country}
                value={phoneValue}
                onChange={handlePhoneInter}
                error={
                  phoneValue
                    ? isValidPhoneNumber(phoneValue)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
                readOnly={props.rdvState.hadOldRdv && defaultMobile}
              />
            </Form.Group>
            <div
              className={"invalid-feedback invalid-feedback-me"}
              style={{ display: phoneIsInValid ? "block" : "none" }}
            >
              {phoneErrMsg}
            </div>
            <div
              className={
                phoneIsInValid ? "invalid-feedback-me-e" : "invalid-feedback"
              }
              style={{ display: phoneIsInValid ? "block" : "none" }}
            >
              _
            </div>
            <div
              className={
                phoneValid ? "invalid-feedback-me-s" : "invalid-feedback"
              }
              style={{ display: phoneValid ? "block" : "none" }}
            >
              _
            </div>

            
            <Form.Group controlId="">
              <Form.Label className={val_isOrange === 1 ? "orange-body" : ""}>
                {t("email")}
                <span
                  hidden={rdv_email_required === 0}
                  title={t("required")}
                  style={{ color: "#b30000" }}
                >
                  *
                </span>
              </Form.Label>
              <Form.Control
                style={{
                  borderColor:
                    props.rdvState.company?.color_scheme.primary_color,
                  borderRadius: val_isOrange === 1 ? 0 : "",
                  height: val_isOrange === 1 ? 40 : 51,
                }}
                type="email"
                placeholder={t("enter_your_email")}
                size={"lg"}
                isValid={emailValid}
                isInvalid={emailIsInValid}
                onChange={handleEmail}
                defaultValue={defaultEmail}
                readOnly={false} /*props.rdvState.hadOldRdv && defaultEmail */
              />
              <Form.Control.Feedback type="invalid">
                {emailErrMsg}
              </Form.Control.Feedback>
            </Form.Group>

            {<Form.Group hidden={!props.rdvState.companyCanByassJoinQueuePage} controlId="formCountry">
            <Form.Label>
              {t('user_country')}
              <span
                  title={t("required")}
                  style={{ color: "#b30000" }}
                >
                  *
                </span>
              </Form.Label>
            <Form.Control as="select" size="lg" style={{
              borderColor:
                props.rdvState.company?.color_scheme.primary_color,
              borderRadius: val_isOrange === 1 ? 0 : "",
              height: val_isOrange === 1 ? 40 : 51,
            }}
            isValid={userCountryValid}
            isInvalid={userCountryInvalid}
            onChange={handleUserCountrySelect} value={selectedUserCountry ? selectedUserCountry.code : ''}>
              <option value="" disabled>{t('choose_user_country')}</option>
              {userCountries.map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </Form.Control>

            <Form.Control.Feedback type="invalid">
              {userCountryErrMsg}
            </Form.Control.Feedback>
          </Form.Group>}

            {/*<Form.Group controlId="" hidden={props.rdvState.company?.color_scheme.isDHL !== 1}>
                        <Form.Label className={props.rdvState.company?.color_scheme.isOrange === 1 ? 'orange-body' : ''}>{t('tracking_number')}</Form.Label>
                        <Form.Control style={{borderColor: props.rdvState.company?.color_scheme.primary_color}} type="text" placeholder={t('enter_tracking_number')} size={"lg"} isValid={noteValid}
                                      isInvalid={noteIsInValid} onChange={handleNote}/>
                        <Form.Control.Feedback type="invalid">{noteErrMsg}</Form.Control.Feedback>
                    </Form.Group> */}
            <div className={"p-2 text-muted"}>
              <small className={val_isOrange === 1 ? "orange-body" : ""}>
                <exp>*</exp>
                {t("we_use_for_rdv")}
              </small>
            </div>

            <div hidden={!displayRdvExistForThisTimeError} className={"p-2 text-muted"}>
              <p className="text-danger">
                {t('has_existing_rdv_on_this_slot')}
              </p>
            </div>
          </div>
          <button
            disabled={disabledSubmitBtn}
            className={
              val_isOrange === 1
                ? "orange-body buttonActive buttonActiveOrange next"
                : "buttonActive next"
            }
            style={{
              color: props.rdvState.company?.color_scheme.secondary_color,
              backgroundColor:
                props.rdvState.company?.color_scheme.primary_color,
              borderColor: props.rdvState.company?.color_scheme.primary_color,
              borderRadius: val_isOrange === 1 ? 0 : "",
              height: val_isOrange === 1 ? 40 : 51,
            }}
            onClick={props.rdvState.hadOldRdv ? handleConfirmHadOldRdv : handleConfirm}
          >
            {t("pay")}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvGroupInfosForm);
