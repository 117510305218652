import React, {useEffect} from 'react';
import './ConfirmationScreen.css'
import confirmSvg from '../../assets/confirm.svg'
import {Col, Row, Table} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import clockSvg from '../../assets/clock2.svg'
import locationSvg from '../../assets/location.svg'
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const ConfirmationScreen = (props) => {
  let history = useHistory();
  const {t} = useTranslation();
  const {apiKey} = useParams();
  const goHome = () => {
    //history.push('/')
    window.location.href = '/' + props.rdvState.company?.apikey
  }
  const goBack = () => {
    history.push('/' + sessionStorage.getItem('rdv_apikey') + '/informations')
  }
  useEffect(() => {
    if (props.rdvState.ticket !== null) {
    } else goBack()

    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
console.log("ticket num ==> ", props.rdvState?.ticket)
  }, [props.rdvState])
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;
  return <div className={'ConfirmationScreen'} id={'ConfirmationScreen'}>
    <div className={'text-center'}>
      <img src={confirmSvg} align={''} alt={''}/>
      {/* <span>Ticket 12345 and {props.rdvState.ticket}</span> */}
    </div>
    <div className={val_isOrange === 1 ? 'orange-body confirmLabel text-center m-4' : 'confirmLabel text-center m-4'}>Ticket No. {props.rdvState.ticket?.ticket}</div>
    {/* <div className={val_isOrange === 1 ? 'orange-body confirmLabel text-center m-4' : 'confirmLabel text-center m-4'}>{t('confirmed')}</div> */}
    <div className={val_isOrange === 1 ? 'orange-body confirmCongrat text-center mb-4' : 'confirmCongrat text-center mb-4'}>{t('congrat')}</div>
    {/* <div className={val_isOrange === 1 ? 'orange-body confirmNotice orange-warning' : 'confirmNotice'}>{t('congrat_msg')}</div> */}
    <Row>
      <Col sm={6} lg={3} className={'mb-3'}>
        <div className={val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{t('comin_for')}:</div>
        <div className={val_isOrange === 1 ? 'orange-body confirmValue' : 'confirmValue'}>{props.rdvState.ticket?.service}</div>
      </Col>
      <Col sm={6} lg={3} className={'mb-3'}>
        <div className={val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{t('branch')}:</div>
        <div className={val_isOrange === 1 ? 'orange-body confirmValue' : 'confirmValue'}>{props.rdvState.ticket?.branch}</div>
      </Col>
      <Col sm={6} lg={3} className={'mb-3'}>
        <div className={val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{t('when')}:</div>
        <div className={val_isOrange === 1 ? 'orange-body confirmValue' : 'confirmValue'}>{moment(props.rdvState.ticket?.date).format("DD/MM/YYYY")}</div>
      </Col>
      <Col sm={6} lg={3} className={'mb-3'}>
        <div className={val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{t('your_ticket')}:</div>
        <div className={val_isOrange === 1 ? 'orange-body confirmValue' : 'confirmValue'}>{props.rdvState.ticket?.ticket}</div>
      </Col>
    </Row>
    <div>{null}</div>
    <Row className={val_isOrange === 1 ? 'orange-no-radius confirmLocation' : 'confirmLocation'}>
      <Col md={6} className={'agencyImage text-center mb-3'}>
        <img src={props.rdvState.branch?.Photo} alt={""}/>
      </Col>
      <Col md={6} className={'agencyInfos'}>
        <div className={'d-flex mb-3'}>
          <div className={'pr-2'}><img src={clockSvg} alt={""}/></div>
          <div>
            <div className={'confirmText'}>{t('opening_h')}</div>
            <div className={'confirmValue'}>
              {props.rdvState.branch?.photo}
              <Table size={'sm'} className={val_isOrange === 1 ? 'orange-body' : ''} hidden={!props.rdvState.branch?.opening_hours}>
                <tr>
                  <td>{t('Monday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Monday}</td>
                </tr>
                <tr>
                  <td>{t('Tuesday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Tuesday}</td>
                </tr>
                <tr>
                  <td>{t('Wednesday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Wednesday}</td>
                </tr>
                <tr>
                  <td>{t('Thursday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Thursday}</td>
                </tr>
                <tr>
                  <td>{t('Friday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Friday}</td>
                </tr>
                <tr>
                  <td>{t('Saturday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Saturday}</td>
                </tr>
                <tr>
                  <td>{t('Sunday')}:</td>
                  <td> {props.rdvState.branch?.opening_hours?.Sunday}</td>
                </tr>
              </Table>
            </div>
          </div>
        </div>
        <div className={'d-flex mb-3'}>
          <div className={'pr-2'}><img src={locationSvg} alt={''}/></div>
          <div>
            <div className={val_isOrange === 1 ? 'orange-body confirmText' : 'confirmText'}>{null}</div>
            <div className={val_isOrange === 1 ? 'orange-body confirmValue' : 'confirmValue'}>
              {props.rdvState.branch?.Address}
            </div>
          </div>
        </div>
        <div className={'pl-3'}>
          <a className={val_isOrange === 1 ? 'orange-body mapsLink' : 'mapsLink'}
             href={"https://maps.google.com/?q=" + props.rdvState.branch?.City + ' ' + props.rdvState.branch?.Name + ' ' + props.rdvState.branch?.Address}
             target={'_blank'} rel={'noopener noreferrer'}>{t('google_direction')}</a>
        </div>
      </Col>
    </Row>
    <div className={'text-center'}>
      <button style={{color: props.rdvState.company?.color_scheme.secondary_color, backgroundColor: props.rdvState.company?.color_scheme.primary_color, borderColor: props.rdvState.company?.color_scheme.primary_color}} className={val_isOrange === 1 ? 'orange-body buttonActive orange-no-radius next' : 'buttonActive orange-no-radius next'} onClick={goHome}>{t('return_home')}</button>
    </div>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationScreen);
