import axios from "axios";

export function addArticle(payload) {
    return {type: "ADD_ARTICLE", payload}
}

export function setOperation(payload) {
    return {type: "SET_OPERATION", payload}
}

export function setBranch(payload) {
    return {type: "SET_BRANCH", payload}
}

export function setService(payload) {
    return {type: "SET_SERVICE", payload}
}

export function setServiceClosed(payload) {
    return {type: "SET_SERVICE_CLOSED", payload}
}

export function setVisitor(payload) {
    return {type: "SET_VISITOR", payload}
}

export function getRdvData(rid) {

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "Session_RDVQ=eljhu6va9ibag0aqc0n58ivn12");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return function (dispatch) {
        fetch("https://booking.right-q.com/596045660/5/rt/getrdv/key/" + sessionStorage.getItem('rdv_apikey') + "/rdvnum/" + rid, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch({type: "GET_RDV_DATA", payload: result.data})
                dispatch({type: 'IS_LOADING', payload: false});
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };
}

export function getRdvFetchData(rid) {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    return function (dispatch) {
        let result = {
            'fullname': 'firstname lastname',
            'firstname': 'firstname',
            'lastname': 'lastname',
            'email': 'email',
            'phone': 'phone',
            'service': 'renouvellement de passeport',
            'branch': 'Agence',
            'address': 'Rue du boulevard',
            'time': '10:30',
            'date': 'Wednesday 10th, January 2024',
            'office': 'ambassade',
        }
        dispatch({type: "GET_RDV_DATA", payload: result})
        dispatch({type: 'IS_LOADING', payload: false});
        /*fetch("https://jsonplaceholder.typicode.com/posts/1" , requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch({type: "GET_RDV_DATA", payload: result})
                dispatch({type: 'IS_LOADING', payload: false});
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });*/
    };
}

export function checkOrderNumber(order_number) {
    return function (dispatch) {
        var myHeaders = new Headers();
        //myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let url = "https://booking.right-q.com/596045669/5/rt/getuuidbycommandnumber/key/" + sessionStorage.getItem('rdv_apikey') + "/commandnumber/" + order_number
        //let url = "https://booking.right-q.com/596045669/5/rt/getuuidbycommandnumber/key/d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" + "/commandnumber/" + order_number

        fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
            dispatch({type: "ORDER_NUMBER_CHECKED", payload: result.data?.status})
            dispatch({type: "SET_ORDER_NUMBER", payload: order_number})
            dispatch({type: "SET_ORDER_NUMBER_UUID", payload: result.data?.uuid})
            dispatch({type: 'IS_LOADING', payload: false});
            dispatch({type: 'SHOW_ORDER_NUMBER_MODAL', payload: false});

            if(result.data?.status) {
                dispatch({type: 'CHECK_ORDER_NUMBER_REQUEST_OK', payload: true});
            } else if(result?.code == 400 && result?.error == "EXISTING_RDV_FOR_THIS_COMMAND_NUMBER") {
                dispatch({type: 'ORDER_NUMBER_HAS_ALREADY_RDV', payload: true});
            } else {
                dispatch({type: 'ORDER_NUMBER_NOT_FOUND', payload: true});
            }
        })
        .catch(error => {
            console.log('error', error)
            dispatch({type: 'IS_LOADING', payload: false});
        });

        
    };
}

export function getUserRdvData(externalRdvKey) {

    return function (dispatch) {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let url = "https://booking.right-q.com/596045669/5/rt/getrdvbyuuid/key/" + sessionStorage.getItem('rdv_apikey') + "/uuidnum/" + externalRdvKey
        //let url = "https://booking.right-q.com/596045669/5/rt/getrdvbyuuid/key/d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09" + "/uuidnum/" + externalRdvKey

        fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
            const results = {
                'data': {
                    'firstname': 'firstname',
                    'lastname': 'lastname',
                    'email': 'email@test.com',
                    'code_country': 'CI',
                    'phone': '96563784',
                    'branch': {
                        "office": "Wmg4T2FZTkNhVjFIMGlrU1ZDUkhMZz09",
                        "name": "Agence Principale Support",
                        "city": "Cotonou",
                        "address": "Benin",
                        "allow_select_agent": "Not active",
                        "allow_only_vip": "Not active",
                        "photo": "https://manage.right-q.com/upload/company/101/",
                        "opening_hours": {
                          "Monday": "9:00-13:00,14:00-16:30",
                          "Tuesday": "9:00-13:00,14:00-16:30",
                          "Wednesday": "9:00-13:00,14:00-16:30",
                          "Thursday": "9:00-13:00,14:00-16:30",
                          "Friday": "9:00-13:00,14:00-16:30",
                          "Saturday": "9:00-14:00",
                          "Sunday": ""
                        }
                    },
                    'service': {
                        "service": "aC9WNHRvL3dVYUFvNUh3QW1Cb21ydz09",
                        "name": "Renouvellement Passeport",
                        "color": "bg-red-intense",
                        "icon": "",
                        "colorcode": "#E32323",
                        "code": "RPP",
                        "translate": {
                          "fr": "Renouvellement Passeport",
                          "en": "Renouvellement Passeport",
                          "ar": "Renouvellement Passeport",
                          "pt": "Renouvellement Passeport",
                          "lb": "Renouvellement Passeport"
                        }
                    },
                    'operation': 'appointment',
                    'company': {
                        apikey: 'cUJYR0dITTFYZUNVSHpLV3U2UzFOQT09',
                        company: 'RC SUPPORT',
                        Language: 'fr',
                        companylogo: 'https://manage.right-q.com/upload/company/101/659bd18942c0b.png',
                        Phone: '96131608',
                        AllowSelectAgents: 0,
                        Country: 'BJ',
                        color_scheme: [],
                        rdv_phone_required: 0,
                        rdv_email_required: 0,
                        showOtherService: 1
                      },
                    'address': 'Benin',
                }
            }

            dispatch({type: "SET_COMPANY", payload: result.data?.company})
            dispatch({type: "SET_OPERATION", payload: 'appointment'})
            dispatch({type: "SET_BRANCH", payload: result.data?.branch})
            dispatch({type: "SET_SERVICE", payload: result.data?.service})
            dispatch({type: "GET_RDV_DATA", payload: result.data})
            //dispatch({type: 'IS_LOADING', payload: false});
            //localStorage.setItem('company_data_set_rdv_exist', true);
        })
        .catch(error => {
            console.log('error', error)
            dispatch({type: 'IS_LOADING', payload: false});
        });
    };
}

export function getUserRdvLoadData() {

    return function (dispatch) {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let url = "https://booking.right-q.com/596045669/5/rt/getsettings/" + sessionStorage.getItem('rdv_apikey')

        fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
            
            dispatch({type: "SET_COMPANY", payload: result.data?.company})
            dispatch({type: "SET_OPERATION", payload: 'appointment'})
            dispatch({type: "SET_BRANCH", payload: result.data?.branch})
            dispatch({type: "IS_RDV_LOAD", payload: true})
            dispatch({type: 'IS_LOADING', payload: false});
        })
        .catch(error => {
            console.log('error', error)
            dispatch({type: 'IS_LOADING', payload: false});
        });

    };
}

export function getCompanyBypassJoinQueueData(apiKey) {

    return function (dispatch) {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let url = "https://booking.right-q.com/596045669/5/rt/getsettings/" + apiKey

        fetch(url, requestOptions)
        .then(response => response.json())
        .then(result => {
            
            dispatch({type: "COMPANY_CAN_BYPASS_JOIN_QUEUE_PAGE", payload: result.data?.company?.company_can_by_pass_join_queue_page})
        })
        .catch(error => {
            console.log('error', error)
        });

    };
}

export function getTicket(data) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    return function (dispatch) {
        fetch("https://booking.right-q.com/rdv/joinqueuenow", requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch({type: "GET_TICKET", payload: result.data})
                dispatch({type: "IS_LOADING", payload: false})
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };
}

export function getRdv(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("fullname", data.fullname);
    urlencoded.append("mobile", data.mobile);
    urlencoded.append("code_country", data.code_country);
    urlencoded.append("email", data.email);
    urlencoded.append("comment", data.comment);
    urlencoded.append("language", data.language);
    urlencoded.append("note", data.note);
    urlencoded.append("user_country", data.user_country);
    urlencoded.append("allrdvbyoneagent", data.allrdvbyoneagent);
    urlencoded.append("numberofrdv", data.numberofrdv);

    if (Array.isArray(data.bookdate)) {
      data.bookdate.forEach(date => {
        urlencoded.append('bookdate[]', date);
      });
    } else {
      urlencoded.append("bookdate", data.bookdate);
    }

    

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return function (dispatch) {
        fetch("https://booking.right-q.com/596045660/5/rt/save/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + data.branch + "/service/" + data.service + "/agent/" + data.agent + "/language/" + data.language, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                  if(result.paymenturl) {
                    dispatch({type: 'SET_PAYMENT_URL', payload: result.paymenturl});
                  }
                  dispatch({type: 'GET_RDV', payload: result.data});
                } else if(result.code === 400 && result.message.includes("Time not available")) {
                    dispatch({type: 'SHOW_TIME_SLOT_OUTDATED', payload: true});
                } else if(result.code === 400 && result.message.includes("Time(s) not available")) {
                  dispatch({type: 'SHOW_TIME_SLOT_BUSY', payload: true});
                } else {
                    console.log(result.message)
                }
                if(!result.paymenturl) {
                  dispatch({type: 'IS_LOADING', payload: false});
                }
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };

}

export function getExistingOldRdv(data) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("fullname", data.fullname);
    urlencoded.append("mobile", data.mobile);
    urlencoded.append("code_country", data.code_country);
    urlencoded.append("email", data.email);
    urlencoded.append("bookdate", data.bookdate);
    urlencoded.append("comment", data.comment);
    urlencoded.append("language", data.language);
    urlencoded.append("note", data.note);
    urlencoded.append("user_country", data.user_country);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return function (dispatch) {
        //const url = "https://booking.right-q.com/596045660/5/rt/saverdv/key/d05ONDJ0Q0t4NlhXNmV0TDZhRGRKZz09/branch/Wmg4T2FZTkNhVjFIMGlrU1ZDUkhMZz09/service/Wmg4T2FZTkNhVjFIMGlrU1ZDUkhMZz09/agent/0/language/fr/commandnumber/22222/quantity/3"
        const url = "https://booking.right-q.com/596045660/5/rt/saverdv/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + data.branch + "/service/" + data.service + "/agent/" + data.agent + "/language/" + data.language + "/commandnumber/" + data.order_number + "/quantity/" + data.quantity
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 200) {
                    dispatch({type: 'GET_RDV', payload: result.data});
                } else if(result.code === 400 && result.error === "EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT") {
                    dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: true});
                } else {
                    console.log(result.message)
                }
                dispatch({type: 'IS_LOADING', payload: false});
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };

}

export function getAvailabilities(branch, service, agent, rdvnumber = null) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Cookie", "Session_RDVQ=eljhu6va9ibag0aqc0n58ivn12");

    var urlencoded = new URLSearchParams();
    // Getting current date
    var today = new Date();
    var todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' 00:00:00';
    // Getting end date (Current date + 2 months)
    var twomonths = new Date();
    twomonths.setMonth(twomonths.getMonth() + 2);
    var twoMonthsDate = twomonths.getFullYear()+'-'+(twomonths.getMonth()+1)+'-'+twomonths.getDate() + ' 00:00:00';
    urlencoded.append("date1", todayDate);
    urlencoded.append("date2", twoMonthsDate);
    urlencoded.append("numberofrdv", rdvnumber);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return function (dispatch) {
        fetch("https://booking.right-q.com/596045660/5/rt/hours/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + branch.office + "/service/" + service.service + "/agent/" + agent.agent, requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch({type: "SET_AVAILABILITIES", payload: result.data})
                dispatch({type: "AVAILABILITIES_FETCH", payload: true})
                dispatch({type: 'IS_LOADING', payload: false})
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    };
}

export function setAgent(payload) {
    return {type: "SET_AGENT", payload}
}

export function getAgents(service, branch) {

    if (service.service !== undefined) {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        return function (dispatch) {
            fetch("https://booking.right-q.com/596045660/5/rt/agents/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + branch.office + "/service/" + service.service, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 404) {
                        dispatch({type: "SET_AGENTS", payload: []});
                        dispatch({type: "SET_NO_AGENTS", payload: false});
                    } else {
                        dispatch({type: "SET_NO_AGENTS", payload: true});
                        dispatch({type: "SET_AGENTS", payload: result.data});
                    }

                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        };
    }

}

export function setSDay(payload) {
    return {type: "SET_DATE", payload}
}

export function setSlot(payload) {
    return {type: "SET_SLOT", payload}
}

export function getServices(branch) {

    if (branch.office !== undefined) {
        let xmyHeaders = new Headers();

        let xrequestOptions = {
            method: 'GET',
            headers: xmyHeaders,
            redirect: 'follow'
        };

        return function (dispatch) {
            fetch("https://booking.right-q.com/596045660/5/rt/services/key/" + sessionStorage.getItem('rdv_apikey') + "/branch/" + branch.office, xrequestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.length <= 0) {
                        dispatch({type: "SET_SERVICES", payload: []});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: result.data});
                    }
                    if (result.data.length > 0) {

                      const services = result.data.map(function(s) {
                        const s_api_attributes = {
                          "rightqrdv_service_condition": {
                            "inter_condition": 0,
                            "title": "RAVIP",
                            "label": "Êtes-vous inscrit au registre national d’identification des personnes (RAVIP) ?",
                            "redirect_to_service": "DPP",
                            "description": "L’inscription au Registre national des personnes physiques (RAVIP) est obligatoire pour toute demande de passeport. Elle permet aux Béninois de la diaspora de bénéficier, dans les mêmes conditions qu’au Bénin, de multiples avantages et d’obtenir les documents d’identification essentiels à toutes vos démarches :\n • l’acte de naissance sécurisé ;\n• le certificat d’identification personnelle (CIP) ; \n• la carte d’identité nationale biométrique ;\n• le certificat de célibat et de coutume."
                          }
                        }

                        return Object.assign({}, s, s_api_attributes)
                      })

                      dispatch({type: "SET_SERVICES", payload: result.data});
                      //dispatch({type: "SET_SERVICES", payload: services});
                      dispatch({type: "SET_SERVICES_CLOSED", payload: null});
                        
                    }

                    //dispatch({type: "SET_SERVICES", payload: result.data});
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        };
    }
    if (branch.id !== undefined) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return function (dispatch) {
            fetch("https://booking.right-q.com/rdv/services?apikey=" + sessionStorage.getItem('rdv_apikey') + "&office=" + branch.id, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "OFFICE_CLOSED") {
                        dispatch({type: "SET_SERVICES", payload: []});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: result.data});
                    }
                    if (result.message === "SERVICES_FOUND") {
                        dispatch({type: "SET_SERVICES", payload: result.data});
                        dispatch({type: "SET_SERVICES_CLOSED", payload: null});
                    }
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        }
    }
}

export function getCompany(apiKey) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return function (dispatch) {
        fetch("https://booking.right-q.com/rdv/settings?apikey=" + apiKey, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (apiKey !== undefined && result.status === 200) {
                    dispatch({type: "SET_COMPANY", payload: result.data.setting});
                    dispatch({type: 'IS_LOADING', payload: false});
                    dispatch({type: 'APIKEY', payload: apiKey});
                    dispatch({type: "COMPANY_CAN_BYPASS_JOIN_QUEUE_PAGE", payload: result.data.setting.company_can_by_pass_join_queue_page})
                    dispatch({type: "SET_CAN_TAKE_GROUP_AVAILABILITY", payload: result.data.setting.can_take_several_appointments_successively})
                    dispatch({type: "SET_USER_NUMBER_LIMIT", payload: result.data.setting?.multiple_rdv_number_limit})
                    sessionStorage.setItem('rdv_apikey', apiKey)
                } else {
                    dispatch({type: 'APIKEY', payload: false});
                    dispatch({type: 'IS_LOADING', payload: false});
                }
            })
            .catch(error => {
                console.log('error', error)
                //dispatch({type: 'IS_LOADING', payload: false});
            });
    }
}

export function getBranches(o) {

    function getABranches() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        return function (dispatch) {
            fetch("https://booking.right-q.com/596045660/5/rt/branch/" + sessionStorage.getItem('rdv_apikey') + "", requestOptions)
                .then(response => response.json())
                .then(result => {
                    dispatch({type: "SET_BRANCHES", payload: result.data});
                    dispatch({type: "SET_BRANCH", payload: null});
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        }
    }

    function getQBranches() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer c3VwcG9ydEByaWdodC1jb20uY29tOlJpZ2h0Q29tQDIwMTgxOTcyNjM=");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        return function (dispatch) {
            fetch("https://booking.right-q.com/rdv/offices?apikey=" + sessionStorage.getItem('rdv_apikey') + "", requestOptions)
                .then(response => response.json())
                .then(result => {
                    dispatch({type: "SET_BRANCHES", payload: result.data.office});
                    //dispatch({type: "SET_COMPANY", payload: result.data.setting});
                    dispatch({type: "SET_BRANCH", payload: null});
                    dispatch({type: 'IS_LOADING', payload: false});
                })
                .catch(error => {
                    console.log('error', error)
                    dispatch({type: 'IS_LOADING', payload: false});
                });
        }
    }

    if (o === 'currentqueue') {
        return getQBranches()
    }
    if (o === 'appointment') {
        return getABranches()
    }
}

export function getBranchesFetch() {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    return function (dispatch) {
        fetch("https://booking.right-q.com/596045660/5/rt/branch/" + sessionStorage.getItem('rdv_apikey') + "", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result.data)
                dispatch({type: "SET_BRANCHES", payload: result.data});
                dispatch({type: "SET_BRANCH", payload: result.data[0]});
                localStorage.setItem('rdv_prev_brach', JSON.stringify({o: "appointment", b: result.data[0]}))
                dispatch({type: 'IS_LOADING', payload: false});
            })
            .catch(error => {
                console.log('error', error)
                dispatch({type: 'IS_LOADING', payload: false});
            });
    }
}

export function getData() {
    return function (dispatch) {
        return axios.get('https://jsonplaceholder.typicode.com/posts')
            .then(json => {
                dispatch({type: "DATA_LOADED", payload: json.data});
                document.getElementById("loadingContainer").classList.remove("showLoading");
                //window.location = 'https://google.com';
            });
    };
}

export function fetchCountries() {

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    return function (dispatch) {
        /*fetch("https://restcountries.com/v2/all", requestOptions)
            .then(response => response.json())
            .then(result => {
                const countries = result.map(country => {
                    return {
                      name: country.translations.en,
                      code: country.alpha2Code,
                    };
                  });
                console.log(countries)
                dispatch({type: "SET_COUNTRIES", payload: countries});
            })
            .catch(error => {
                console.log('error', error)
        });*/
        const countries = [
            {
              name: 'Afghanistan',
              code: 'AF'
            },
            {
              name: 'Åland Islands',
              code: 'AX'
            },
            {
              name: 'Albania',
              code: 'AL'
            },
            {
              name: 'Algeria',
              code: 'DZ'
            },
            {
              name: 'American Samoa',
              code: 'AS'
            },
            {
              name: 'Andorra',
              code: 'AD'
            },
            {
              name: 'Angola',
              code: 'AO'
            },
            {
              name: 'Anguilla',
              code: 'AI'
            },
            {
              name: 'Antarctica',
              code: 'AQ'
            },
            {
              name: 'Antigua and Barbuda',
              code: 'AG'
            },
            {
              name: 'Argentina',
              code: 'AR'
            },
            {
              name: 'Armenia',
              code: 'AM'
            },
            {
              name: 'Aruba',
              code: 'AW'
            },
            {
              name: 'Australia',
              code: 'AU'
            },
            {
              name: 'Austria',
              code: 'AT'
            },
            {
              name: 'Azerbaijan',
              code: 'AZ'
            },
            {
              name: 'Bahamas',
              code: 'BS'
            },
            {
              name: 'Bahrain',
              code: 'BH'
            },
            {
              name: 'Bangladesh',
              code: 'BD'
            },
            {
              name: 'Barbados',
              code: 'BB'
            },
            {
              name: 'Belarus',
              code: 'BY'
            },
            {
              name: 'Belgium',
              code: 'BE'
            },
            {
              name: 'Belize',
              code: 'BZ'
            },
            {
              name: 'Benin',
              code: 'BJ'
            },
            {
              name: 'Bermuda',
              code: 'BM'
            },
            {
              name: 'Bhutan',
              code: 'BT'
            },
            {
              name: 'Bolivia (Plurinational State of)',
              code: 'BO'
            },
            {
              name: 'Bonaire, Sint Eustatius and Saba',
              code: 'BQ'
            },
            {
              name: 'Bosnia and Herzegovina',
              code: 'BA'
            },
            {
              name: 'Botswana',
              code: 'BW'
            },
            {
              name: 'Bouvet Island',
              code: 'BV'
            },
            {
              name: 'Brazil',
              code: 'BR'
            },
            {
              name: 'British Indian Ocean Territory',
              code: 'IO'
            },
            {
              name: 'United States Minor Outlying Islands',
              code: 'UM'
            },
            {
              name: 'Virgin Islands (British)',
              code: 'VG'
            },
            {
              name: 'Virgin Islands (U.S.)',
              code: 'VI'
            },
            {
              name: 'Brunei Darussalam',
              code: 'BN'
            },
            {
              name: 'Bulgaria',
              code: 'BG'
            },
            {
              name: 'Burkina Faso',
              code: 'BF'
            },
            {
              name: 'Burundi',
              code: 'BI'
            },
            {
              name: 'Cambodia',
              code: 'KH'
            },
            {
              name: 'Cameroon',
              code: 'CM'
            },
            {
              name: 'Canada',
              code: 'CA'
            },
            {
              name: 'Cabo Verde',
              code: 'CV'
            },
            {
              name: 'Cayman Islands',
              code: 'KY'
            },
            {
              name: 'Central African Republic',
              code: 'CF'
            },
            {
              name: 'Chad',
              code: 'TD'
            },
            {
              name: 'Chile',
              code: 'CL'
            },
            {
              name: 'China',
              code: 'CN'
            },
            {
              name: 'Christmas Island',
              code: 'CX'
            },
            {
              name: 'Cocos (Keeling) Islands',
              code: 'CC'
            },
            {
              name: 'Colombia',
              code: 'CO'
            },
            {
              name: 'Comoros',
              code: 'KM'
            },
            {
              name: 'Congo',
              code: 'CG'
            },
            {
              name: 'Congo (Democratic Republic of the)',
              code: 'CD'
            },
            {
              name: 'Cook Islands',
              code: 'CK'
            },
            {
              name: 'Costa Rica',
              code: 'CR'
            },
            {
              name: 'Croatia',
              code: 'HR'
            },
            {
              name: 'Cuba',
              code: 'CU'
            },
            {
              name: 'Curaçao',
              code: 'CW'
            },
            {
              name: 'Cyprus',
              code: 'CY'
            },
            {
              name: 'Czech Republic',
              code: 'CZ'
            },
            {
              name: 'Denmark',
              code: 'DK'
            },
            {
              name: 'Djibouti',
              code: 'DJ'
            },
            {
              name: 'Dominica',
              code: 'DM'
            },
            {
              name: 'Dominican Republic',
              code: 'DO'
            },
            {
              name: 'Ecuador',
              code: 'EC'
            },
            {
              name: 'Egypt',
              code: 'EG'
            },
            {
              name: 'El Salvador',
              code: 'SV'
            },
            {
              name: 'Equatorial Guinea',
              code: 'GQ'
            },
            {
              name: 'Eritrea',
              code: 'ER'
            },
            {
              name: 'Estonia',
              code: 'EE'
            },
            {
              name: 'Ethiopia',
              code: 'ET'
            },
            {
              name: 'Falkland Islands (Malvinas)',
              code: 'FK'
            },
            {
              name: 'Faroe Islands',
              code: 'FO'
            },
            {
              name: 'Fiji',
              code: 'FJ'
            },
            {
              name: 'Finland',
              code: 'FI'
            },
            {
              name: 'France',
              code: 'FR'
            },
            {
              name: 'French Guiana',
              code: 'GF'
            },
            {
              name: 'French Polynesia',
              code: 'PF'
            },
            {
              name: 'French Southern Territories',
              code: 'TF'
            },
            {
              name: 'Gabon',
              code: 'GA'
            },
            {
              name: 'Gambia',
              code: 'GM'
            },
            {
              name: 'Georgia',
              code: 'GE'
            },
            {
              name: 'Germany',
              code: 'DE'
            },
            {
              name: 'Ghana',
              code: 'GH'
            },
            {
              name: 'Gibraltar',
              code: 'GI'
            },
            {
              name: 'Greece',
              code: 'GR'
            },
            {
              name: 'Greenland',
              code: 'GL'
            },
            {
              name: 'Grenada',
              code: 'GD'
            },
            {
              name: 'Guadeloupe',
              code: 'GP'
            },
            {
              name: 'Guam',
              code: 'GU'
            },
            {
              name: 'Guatemala',
              code: 'GT'
            },
            {
              name: 'Guernsey',
              code: 'GG'
            },
            {
              name: 'Guinea',
              code: 'GN'
            },
            {
              name: 'Guinea-Bissau',
              code: 'GW'
            },
            {
              name: 'Guyana',
              code: 'GY'
            },
            {
              name: 'Haiti',
              code: 'HT'
            },
            {
              name: 'Heard Island and McDonald Islands',
              code: 'HM'
            },
            {
              name: 'Vatican City',
              code: 'VA'
            },
            {
              name: 'Honduras',
              code: 'HN'
            },
            {
              name: 'Hungary',
              code: 'HU'
            },
            {
              name: 'Hong Kong',
              code: 'HK'
            },
            {
              name: 'Iceland',
              code: 'IS'
            },
            {
              name: 'India',
              code: 'IN'
            },
            {
              name: 'Indonesia',
              code: 'ID'
            },
            {
              name: 'Ivory Coast',
              code: 'CI'
            },
            {
              name: 'Iran (Islamic Republic of)',
              code: 'IR'
            },
            {
              name: 'Iraq',
              code: 'IQ'
            },
            {
              name: 'Ireland',
              code: 'IE'
            },
            {
              name: 'Isle of Man',
              code: 'IM'
            },
            {
              name: 'Israel',
              code: 'IL'
            },
            {
              name: 'Italy',
              code: 'IT'
            },
            {
              name: 'Jamaica',
              code: 'JM'
            },
            {
              name: 'Japan',
              code: 'JP'
            },
            {
              name: 'Jersey',
              code: 'JE'
            },
            {
              name: 'Jordan',
              code: 'JO'
            },
            {
              name: 'Kazakhstan',
              code: 'KZ'
            },
            {
              name: 'Kenya',
              code: 'KE'
            },
            {
              name: 'Kiribati',
              code: 'KI'
            },
            {
              name: 'Kuwait',
              code: 'KW'
            },
            {
              name: 'Kyrgyzstan',
              code: 'KG'
            },
            {
              name: 'Lao People\'s Democratic Republic',
              code: 'LA'
            },
            {
              name: 'Latvia',
              code: 'LV'
            },
            {
              name: 'Lebanon',
              code: 'LB'
            },
            {
              name: 'Lesotho',
              code: 'LS'
            },
            {
              name: 'Liberia',
              code: 'LR'
            },
            {
              name: 'Libya',
              code: 'LY'
            },
            {
              name: 'Liechtenstein',
              code: 'LI'
            },
            {
              name: 'Lithuania',
              code: 'LT'
            },
            {
              name: 'Luxembourg',
              code: 'LU'
            },
            {
              name: 'Macao',
              code: 'MO'
            },
            {
              name: 'North Macedonia',
              code: 'MK'
            },
            {
              name: 'Madagascar',
              code: 'MG'
            },
            {
              name: 'Malawi',
              code: 'MW'
            },
            {
              name: 'Malaysia',
              code: 'MY'
            },
            {
              name: 'Maldives',
              code: 'MV'
            },
            {
              name: 'Mali',
              code: 'ML'
            },
            {
              name: 'Malta',
              code: 'MT'
            },
            {
              name: 'Marshall Islands',
              code: 'MH'
            },
            {
              name: 'Martinique',
              code: 'MQ'
            },
            {
              name: 'Mauritania',
              code: 'MR'
            },
            {
              name: 'Mauritius',
              code: 'MU'
            },
            {
              name: 'Mayotte',
              code: 'YT'
            },
            {
              name: 'Mexico',
              code: 'MX'
            },
            {
              name: 'Micronesia (Federated States of)',
              code: 'FM'
            },
            {
              name: 'Moldova (Republic of)',
              code: 'MD'
            },
            {
              name: 'Monaco',
              code: 'MC'
            },
            {
              name: 'Mongolia',
              code: 'MN'
            },
            {
              name: 'Montenegro',
              code: 'ME'
            },
            {
              name: 'Montserrat',
              code: 'MS'
            },
            {
              name: 'Morocco',
              code: 'MA'
            },
            {
              name: 'Mozambique',
              code: 'MZ'
            },
            {
              name: 'Myanmar',
              code: 'MM'
            },
            {
              name: 'Namibia',
              code: 'NA'
            },
            {
              name: 'Nauru',
              code: 'NR'
            },
            {
              name: 'Nepal',
              code: 'NP'
            },
            {
              name: 'Netherlands',
              code: 'NL'
            },
            {
              name: 'New Caledonia',
              code: 'NC'
            },
            {
              name: 'New Zealand',
              code: 'NZ'
            },
            {
              name: 'Nicaragua',
              code: 'NI'
            },
            {
              name: 'Niger',
              code: 'NE'
            },
            {
              name: 'Nigeria',
              code: 'NG'
            },
            {
              name: 'Niue',
              code: 'NU'
            },
            {
              name: 'Norfolk Island',
              code: 'NF'
            },
            {
              name: 'Korea (Democratic People\'s Republic of)',
              code: 'KP'
            },
            {
              name: 'Northern Mariana Islands',
              code: 'MP'
            },
            {
              name: 'Norway',
              code: 'NO'
            },
            {
              name: 'Oman',
              code: 'OM'
            },
            {
              name: 'Pakistan',
              code: 'PK'
            },
            {
              name: 'Palau',
              code: 'PW'
            },
            {
              name: 'Palestine, State of',
              code: 'PS'
            },
            {
              name: 'Panama',
              code: 'PA'
            },
            {
              name: 'Papua New Guinea',
              code: 'PG'
            },
            {
              name: 'Paraguay',
              code: 'PY'
            },
            {
              name: 'Peru',
              code: 'PE'
            },
            {
              name: 'Philippines',
              code: 'PH'
            },
            {
              name: 'Pitcairn',
              code: 'PN'
            },
            {
              name: 'Poland',
              code: 'PL'
            },
            {
              name: 'Portugal',
              code: 'PT'
            },
            {
              name: 'Puerto Rico',
              code: 'PR'
            },
            {
              name: 'Qatar',
              code: 'QA'
            },
            {
              name: 'Republic of Kosovo',
              code: 'XK'
            },
            {
              name: 'Réunion',
              code: 'RE'
            },
            {
              name: 'Romania',
              code: 'RO'
            },
            {
              name: 'Russian Federation',
              code: 'RU'
            },
            {
              name: 'Rwanda',
              code: 'RW'
            },
            {
              name: 'Saint Barthélemy',
              code: 'BL'
            },
            {
              name: 'Saint Helena, Ascension and Tristan da Cunha',
              code: 'SH'
            },
            {
              name: 'Saint Kitts and Nevis',
              code: 'KN'
            },
            {
              name: 'Saint Lucia',
              code: 'LC'
            },
            {
              name: 'Saint Martin (French part)',
              code: 'MF'
            },
            {
              name: 'Saint Pierre and Miquelon',
              code: 'PM'
            },
            {
              name: 'Saint Vincent and the Grenadines',
              code: 'VC'
            },
            {
              name: 'Samoa',
              code: 'WS'
            },
            {
              name: 'San Marino',
              code: 'SM'
            },
            {
              name: 'Sao Tome and Principe',
              code: 'ST'
            },
            {
              name: 'Saudi Arabia',
              code: 'SA'
            },
            {
              name: 'Senegal',
              code: 'SN'
            },
            {
              name: 'Serbia',
              code: 'RS'
            },
            {
              name: 'Seychelles',
              code: 'SC'
            },
            {
              name: 'Sierra Leone',
              code: 'SL'
            },
            {
              name: 'Singapore',
              code: 'SG'
            },
            {
              name: 'Sint Maarten (Dutch part)',
              code: 'SX'
            },
            {
              name: 'Slovakia',
              code: 'SK'
            },
            {
              name: 'Slovenia',
              code: 'SI'
            },
            {
              name: 'Solomon Islands',
              code: 'SB'
            },
            {
              name: 'Somalia',
              code: 'SO'
            },
            {
              name: 'South Africa',
              code: 'ZA'
            },
            {
              name: 'South Georgia and the South Sandwich Islands',
              code: 'GS'
            },
            {
              name: 'Korea (Republic of)',
              code: 'KR'
            },
            {
              name: 'Spain',
              code: 'ES'
            },
            {
              name: 'Sri Lanka',
              code: 'LK'
            },
            {
              name: 'Sudan',
              code: 'SD'
            },
            {
              name: 'South Sudan',
              code: 'SS'
            },
            {
              name: 'Suriname',
              code: 'SR'
            },
            {
              name: 'Svalbard and Jan Mayen',
              code: 'SJ'
            },
            {
              name: 'Swaziland',
              code: 'SZ'
            },
            {
              name: 'Sweden',
              code: 'SE'
            },
            {
              name: 'Switzerland',
              code: 'CH'
            },
            {
              name: 'Syrian Arab Republic',
              code: 'SY'
            },
            {
              name: 'Taiwan',
              code: 'TW'
            },
            {
              name: 'Tajikistan',
              code: 'TJ'
            },
            {
              name: 'Tanzania, United Republic of',
              code: 'TZ'
            },
            {
              name: 'Thailand',
              code: 'TH'
            },
            {
              name: 'Timor-Leste',
              code: 'TL'
            },
            {
              name: 'Togo',
              code: 'TG'
            },
            {
              name: 'Tokelau',
              code: 'TK'
            },
            {
              name: 'Tonga',
              code: 'TO'
            },
            {
              name: 'Trinidad and Tobago',
              code: 'TT'
            },
            {
              name: 'Tunisia',
              code: 'TN'
            },
            {
              name: 'Turkey',
              code: 'TR'
            },
            {
              name: 'Turkmenistan',
              code: 'TM'
            },
            {
              name: 'Turks and Caicos Islands',
              code: 'TC'
            },
            {
              name: 'Tuvalu',
              code: 'TV'
            },
            {
              name: 'Uganda',
              code: 'UG'
            },
            {
              name: 'Ukraine',
              code: 'UA'
            },
            {
              name: 'United Arab Emirates',
              code: 'AE'
            },
            {
              name: 'United Kingdom of Great Britain and Northern Ireland',
              code: 'GB'
            },
            {
              name: 'United States of America',
              code: 'US'
            },
            {
              name: 'Uruguay',
              code: 'UY'
            },
            {
              name: 'Uzbekistan',
              code: 'UZ'
            },
            {
              name: 'Vanuatu',
              code: 'VU'
            },
            {
              name: 'Venezuela (Bolivarian Republic of)',
              code: 'VE'
            },
            {
              name: 'Vietnam',
              code: 'VN'
            },
            {
              name: 'Wallis and Futuna',
              code: 'WF'
            },
            {
              name: 'Western Sahara',
              code: 'EH'
            },
            {
              name: 'Yemen',
              code: 'YE'
            },
            {
              name: 'Zambia',
              code: 'ZM'
            },
            {
              name: 'Zimbabwe',
              code: 'ZW'
            }
          ];
        dispatch({type: "SET_COUNTRIES", payload: countries});
    }
}

export function clearCountries() {
    return function (dispatch) {
        dispatch({type: "SET_COUNTRIES", payload: []});
    }
}

export function get() {
    return function (dispatch) {

    };
}
