import React, {useEffect, useState} from 'react';
import './RdvStartScreen.css'
import CovidMessage from "../../stepsComponents/CovidMessage";
import RdvStartMessage from "../../stepsComponents/RdvStartMessage";
import RdvStartScreenCard from "../../stepsComponents/RdvStartScreenCard";
import joinSVG from '../../assets/join-the-current-queue.svg'
import bookSVG from '../../assets/book-an-appointment.svg'
import bookSVGNew from '../../assets/book-an-appointment-new.svg'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch} from 'react-redux';

import {
    Row, Col, Form, Modal, Button
} from "react-bootstrap";

import * as rdvActions from "../../actions";


const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkOrderNumber: (e) => dispatch(rdvActions.checkOrderNumber(e)),
    }
}

const RdvStartScreen = (props) => {

    const {apiKey} = useParams();
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();
    let classBookApp = 'order-last';
    sessionStorage.setItem('rdv_apikey', apiKey)
    localStorage.removeItem('company_data_set_rdv_exist')

    const [showModal, setShowModal] = useState(false);
    const [orderNumber, setOrderNumber] = useState(null);
    const [orderNumberValid, setOrderNumberValid] = useState(false);
    const [orderNumberInvalid, setOrderNumberInvalid] = useState(null);
    const [orderNumberErrMsg, setOrderNumberErrMsg] = useState(null);
    const [orderNumberNotFound, setOrderNumberNotFound] = useState(false);
    const [orderNumberHasAlreadyRdv, setOrderNumberHasAlreadyRdv] = useState(false);
    const [defaultOrderNumber, setDefaultOrderNumber] = useState(props.rdvState.orderNumber ? props.rdvState.orderNumber : null);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [showBadlinkModal, setShowBadlinkModal] = useState(false);

    useEffect(() => {
        if(props.rdvState.paymentBadlink && !props.rdvState.isLoading) {
            setShowBadlinkModal(true)
        }
    }, [props.rdvState.paymentBadlink, props.rdvState.isLoading])

    const handleCloseBadlinkModal = () => {
        setShowBadlinkModal(false)
        dispatch({type: 'SET_PAYMENT_BADLINK', payload: false});
    };

    useEffect(() => {
        if(props.rdvState.paymentFailed && !props.rdvState.isLoading) {
            setShowFailureModal(true)
        }
    }, [props.rdvState.paymentFailed, props.rdvState.isLoading])

    const handleCloseFailureModal = () => {
        setShowFailureModal(false)
        dispatch({type: 'SET_PAYMENT_FAILED', payload: false});
    };

    const handleCloseModal = () => {
        setOrderNumberErrMsg(null)
        setOrderNumberValid(false)
        setOrderNumberInvalid(null)
        setOrderNumberNotFound(false)
        setOrderNumberHasAlreadyRdv(false)
        setOrderNumber(null)
        setShowModal(false)
        dispatch({type: 'SHOW_ORDER_NUMBER_MODAL', payload: false});
    };
    
    const handleShowModal = () => setShowModal(true);

    const handleSubmitModal = () => {
        dispatch({type: 'IS_LOADING', payload: true});
        props.checkOrderNumber(orderNumber)

        setShowModal(false)
        setOrderNumberValid(false);
        setOrderNumberInvalid(null);
        setOrderNumber(null)
    };

    const handleOrderNumber = (e) => {
    //setName(e.target.value)
    if (e.target.value.length > 0) {
        //check is five (5) digits number
        if(e.target.value.match(/^\d{5}$/) !== null ) {
        setOrderNumberValid(true);
        setOrderNumberInvalid(false);
        setOrderNumber(e.target.value)
        setOrderNumberErrMsg(null)
        } else {
        setOrderNumberErrMsg(t("rdv_exist_key_invalid"));
        setOrderNumberValid(false);
        setOrderNumberInvalid(true);
        setOrderNumber(null)
        setOrderNumberNotFound(false)
        setOrderNumberHasAlreadyRdv(false)
        }
    } else {
        setOrderNumberErrMsg(t("rdv_exist_key_invalid"));
        setOrderNumberValid(false);
        setOrderNumberInvalid(true);
        setOrderNumber(null)
        setOrderNumberNotFound(false)
        setOrderNumberHasAlreadyRdv(false)
    }
    };

    useEffect(() => {
      dispatch({type: 'HAD_OLD_RDV', payload: false});
      dispatch({type: 'SET_AVAILABILITIES', payload: []});
      dispatch({type: 'SET_ORDER_NUMBER', payload: null});
      dispatch({type: 'SET_OPERATION', payload: null});
      dispatch({type: 'SET_BRANCH', payload: null});
      dispatch({type: 'SET_ORDER_NUMBER_UUID', payload: null});
      dispatch({type: 'EXISTING_RDV_FOR_SENT_NUMBER_AND_TIME_SLOT', payload: false});
      dispatch({type: 'SHOW_TIME_SLOT_BUSY', payload: false});
      dispatch({type: 'SHOW_TIME_SLOT_OUTDATED', payload: false});
      dispatch({type: 'GET_RDV_DATA', payload: null});
      dispatch({type: 'WAITING_AVAILABILITY', payload: false});
    }, [])
    
    useEffect(() => {
        if(props.rdvState.showOrderNumberModal) {
            handleShowModal()
        }
    }, [props.rdvState.showOrderNumberModal])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(props.rdvState.orderNumberNotFound) {
            setOrderNumberValid(false);
            setOrderNumberInvalid(true);
            setOrderNumber(props.rdvState?.orderNumber)
            setDefaultOrderNumber(props.rdvState?.orderNumber)
            setOrderNumberNotFound(true)
            setShowModal(true);
            dispatch({type: 'ORDER_NUMBER_NOT_FOUND', payload: false});
        }

        if(props.rdvState.orderNumberHasAlreadyRdv) {
            setOrderNumberValid(false);
            setOrderNumberInvalid(true);
            setOrderNumber(props.rdvState?.orderNumber)
            setDefaultOrderNumber(props.rdvState?.orderNumber)
            setOrderNumberNotFound(false)
            setOrderNumberHasAlreadyRdv(true)
            setShowModal(true);
            dispatch({type: 'ORDER_NUMBER_HAS_ALREADY_RDV', payload: false});
        }

        window.scrollTo(0, 0)
    }, [props.rdvState.orderNumberNotFound, props.rdvState.orderNumberHasAlreadyRdv])

    useEffect(() => {
        if(props.rdvState.orderNumberRequestOk && props.rdvState.isLoading == false) {
            history.push('/' + props.rdvState.company?.apikey + '/rdv-exist-check')
        }
    }, [props.rdvState.orderNumberRequestOk, props.rdvState.isLoading])

let comp_name = decodeURIComponent(escape(props.rdvState.company?.company))
  let val_isOrange = props.rdvState.company?.color_scheme.isOrange;

    return <div className={'RdvStartScreen'} id={'RdvStartScreen'}>
        <MetaTags>
            <title>{comp_name === 'undefined' ? 'RightQ RDV':comp_name}</title>
            <meta property="og:image" content='/favicon-og.png' />
            <meta property="og:type" content="website"></meta>
            <meta name="og:site_name" content={comp_name}></meta>
            <meta property="og:title" content={comp_name} />
            <meta name="twitter:title" content={comp_name}></meta>
            <meta property="twitter:title" content={comp_name}></meta>
            <meta name="description" content={t('og_description')} />
            <meta name="og:description" content={t('og_description')}></meta>
        </MetaTags>

        <Row>
            <Col sm={12} lg={2} md={12}></Col>
            <Col sm={12} lg={8} md={12}>
                <RdvStartMessage/>
                <div className={'row justify-content-center startScreenCards'}>
                    {/* 
                    {props.rdvState.company?.hide_existing_appointment_choice == false && <Col xs={12} md={6} className={classBookApp}>
                        <RdvStartScreenCard img={bookSVG} text={t('plan_msg')} btnText={t('book_existing_app')} to={'rdv-exist'} descText={t('book_existing_app_desc')}/>
                    </Col>} 
                    */}
                    <Col xs={12} md={6} className={classBookApp}>
                        <RdvStartScreenCard img={bookSVGNew} text={t('plan_msg')} btnText={t('book_app')} to={'rdv-load'} descText={t('book_app_desc')}/>
                    </Col>
                </div>
            </Col>
        </Row>

        <Modal size="sm" show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
            <Modal.Title as='h6'>{t('rdv_exist_key')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Control type="text" size={"lg"} id="order_number"
                isValid={orderNumberValid}
                isInvalid={orderNumberInvalid} 
                onChange={handleOrderNumber} 
                defaultValue={defaultOrderNumber}
                placeholder={t('please_enter_rdv_exist_key')} 
            />
            <Form.Control.Feedback type="invalid">
                {orderNumberErrMsg}
            </Form.Control.Feedback>

            <small hidden={!orderNumberNotFound} className='info'>{t('rdv_exist_key_not_found')} <Link to={'/'+apiKey+'/rdv-load'}>{t('rdv_exist_key_not_found_here')}</Link> {t('rdv_exist_key_not_found_end_text')}</small>
            <small hidden={!orderNumberHasAlreadyRdv} className='text-danger'>{t('rdv_exist_key_has_already_rdv')}</small>
            </Modal.Body>
            <Modal.Footer>
            <Button className='buttonDefaultRdv'
            onClick={handleCloseModal}>
                {t('close_order_number_modal')}
            </Button>
            <Button className='buttonActiveRdv'
            disabled={!orderNumberValid} onClick={handleSubmitModal}>
                {t('submit_order_number_modal')}
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal size="sm" show={showFailureModal} onHide={handleCloseFailureModal}>
            <Modal.Header>
            <Modal.Title as='h6'>{t('payment_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-danger'>{t('payment_info')}</p>
            </Modal.Body>
            <Modal.Footer>
            <Button className='buttonDefaultRdv'
            onClick={handleCloseFailureModal}>
                {t('close')}
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal size="sm" show={showBadlinkModal} onHide={handleCloseBadlinkModal}>
            <Modal.Header>
            <Modal.Title as='h6'>{t('payment_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-danger'>{t('payment_badlink')}</p>
            </Modal.Body>
            <Modal.Footer>
            <Button className='buttonDefaultRdv'
            onClick={handleCloseBadlinkModal}>
                {t('close')}
            </Button>
            </Modal.Footer>
        </Modal>
    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvStartScreen);
