import React from 'react';
import './CompanyLoading.css'
import bg from '../../assets/background.jpg'
import {Router} from "react-router-dom";
import {Container} from "react-bootstrap";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
  return {
    rdvState: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}




const CompanyLoading = (props) => {


  /*let setBgOrange = "backgroundColor: '#FFF'";
  if(props.rdvState.company?.color_scheme.isOrange != undefined && props.rdvState.company?.color_scheme.isOrange == 1){
    setBgOrange = "backgroundColor: '#F4F5F6', backgroundImage: 'url(" + bg + ")'";
  }*/
  


  return <div className={'CompanyLoading'} id={'CompanyLoading'}
              style={{backgroundColor: '#FFF'}}>
    <Container>
      <div className={'noroute'}>
        <div hidden={props.rdvState.apiKey !== false}>
          <h1>404</h1>
        </div>
        <div hidden={props.rdvState.company !== null}>
        </div>

      </div>
    </Container>
  </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLoading);
