import React, {useEffect, useState} from 'react';
import './RdvServiceSelection.css'
import backIcon from "../../assets/back.svg";
import {useHistory, useParams} from "react-router-dom";
import BranchService from "../BranchService";
import RdvBranchService from "../RdvBranchService";
import BranchSelector from "../BranchSelector";
import {connect, useDispatch} from "react-redux";
import * as rdvActions from "../../actions";
import ServiceClosed from "../ServiceClosed";
import {useTranslation} from "react-i18next";
import NoAgent from "../NoAgent";
import i18next from "i18next";

import {
    Row, Col, Form, Modal, Button
} from "react-bootstrap";

const mapStateToProps = (state) => {
    return {
        rdvState: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: e => dispatch(rdvActions.getServices(e),)
    }
}
const RdvServiceSelection = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();
    const {t} = useTranslation();
    const {apiKey} = useParams();
    localStorage.removeItem('company_data_set_rdv_exist')
    const [canShowNoAgentPage, setCanShowNoAgentPage] = useState(false)
    const goBack = () => {
        if(props.rdvState.companyCanByassJoinQueuePage) {
            dispatch({type: 'WAITING_AVAILABILITY', payload: false});
            history.push('/' + apiKey +'/rdv-start')
        } else {
            history.push('/' + sessionStorage.getItem('rdv_apikey') + "/operation")
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [selectedUserNumber, setSelectedUserNumber] = useState(null);
    const [userNumberValid, setUserNumberValid] = useState(false);
    const [userNumberInvalid, setUserNumberInvalid] = useState(null);
    const [userNumberErrMsg, setUserNumberErrMsg] = useState(null);
    const [userNumbers, setUserNumbers] = useState([]);

    const [showRavipModal, setShowRavipModal] = useState(false);
    const [selectedRavip, setSelectedRavip] = useState(null);
    const [ravipValid, setRavipValid] = useState(false);
    const [ravipInvalid, setRavipInvalid] = useState(null);
    const [ravipErrMsg, setRavipErrMsg] = useState(null);

    const [ravipModalTitle, setRavipModalTitle] = useState(null);
    const [ravipModalLabel, setRavipModalLabel] = useState(null);
    const [ravipModalQuestion, setRavipModalQuestion] = useState(null);
    const [ravipModalDescription, setRavipModalDescription] = useState(null);

    const [services, setServices] = useState([]);

    const reorderServices = () => {
        let data = props.rdvState.services
        data.sort((a, b) => {
            if (a.order < b.order) {
              return -1;
            }
            if (a.order > b.order) {
              return 1;
            }
            return 0;
        });

        return data
    }

    const handleCloseRavipModal = () => {
        setRavipErrMsg(null)
        setRavipValid(false)
        setRavipInvalid(null)
        setSelectedRavip(null)
        setShowRavipModal(false)

        setUserNumberErrMsg(null)
        setUserNumberValid(false)
        setUserNumberInvalid(null)
        setSelectedUserNumber(null)
        setShowModal(false)

        dispatch({type: 'SET_USER_NUMBER', payload: null});
        dispatch({type: 'SHOW_USER_NUMBER_MODAL', payload: false});
        dispatch({type: 'SET_USER_HAS_RAVIP', payload: null});
        dispatch({type: 'SHOW_RAVIP_MODAL', payload: false});

        dispatch({type: 'SET_RAVIP_MODAL_TITLE', payload: null});
        dispatch({type: 'SET_RAVIP_MODAL_LABEL', payload: null});
        dispatch({type: 'SET_RAVIP_MODAL_QUESTION', payload: null});
        dispatch({type: 'SET_RAVIP_MODAL_DESCRIPTION', payload: null});
    };
    
    const handleShowRavipModal = () => setShowRavipModal(true);

    const handleSubmitRavipModal = () => {
        setShowRavipModal(false)
        setRavipValid(false);
        setRavipInvalid(null);
        dispatch({type: 'SET_USER_HAS_RAVIP', payload: selectedRavip});
        dispatch({type: 'SHOW_USER_NUMBER_MODAL', payload: false});
        dispatch({type: 'SHOW_RAVIP_MODAL', payload: false});

        if(selectedRavip == props.rdvState.service?.rightqrdv_service_condition?.inter_condition){
            const service_to_redirect_found = props.rdvState.services.filter((s) => {
                return s.code == props.rdvState.service?.rightqrdv_service_condition?.redirect_to_service;
            })
            if(service_to_redirect_found) {
                dispatch({type: 'SET_SERVICE', payload: service_to_redirect_found[0]})
            }
        }

        dispatch({type: 'WAITING_AVAILABILITY', payload: true});
        if(props.rdvState.companyCanByassJoinQueuePage && props.rdvState.canTakeGroupAvailability) {
            history.push('/' + props.rdvState.company?.apikey + '/rdv-group-availability')
        } else if(props.rdvState.companyCanByassJoinQueuePage && !props.rdvState.canTakeGroupAvailability) {
            history.push('/' + props.rdvState.company?.apikey + '/rdv-availability')
        }
    };


    const handleRavipSelect = (e) => {
        if (e.target.value.length > 0) {
            //check is number
            if(e.target.value.match(/^\d+$/) !== null ) {
            setRavipValid(true);
            setRavipInvalid(false);
            setSelectedRavip(e.target.value)
            setRavipErrMsg(null)
            }
        } else {
            setRavipErrMsg(t("ravip_invalid"));
            setRavipValid(false);
            setRavipInvalid(true);
            setSelectedRavip(null)
        }
    };
    
    const [ravipItems, setRavipItems] = useState(localStorage.getItem("rdv_lang") == 'fr' ? [{'code': 1, 'val': 'Oui'}, {'code': 0, 'val': 'Non'}] : [{'code': 1, 'val': 'Yes'}, {'code': 0, 'val': 'No'}]);

    const handleCloseModal = () => {
        setUserNumberErrMsg(null)
        setUserNumberValid(false)
        setUserNumberInvalid(null)
        setSelectedUserNumber(null)
        setShowModal(false)
        dispatch({type: 'SET_USER_NUMBER', payload: null});
        dispatch({type: 'SHOW_USER_NUMBER_MODAL', payload: false});
        dispatch({type: 'SHOW_RAVIP_MODAL', payload: false});
    };
    
    const handleShowModal = () => setShowModal(true);

    const handleSubmitModal = () => {
        setShowModal(false)
        setUserNumberValid(false);
        setUserNumberInvalid(null);
        dispatch({type: 'SET_USER_NUMBER', payload: selectedUserNumber});
        dispatch({type: 'SHOW_USER_NUMBER_MODAL', payload: false});

        if(props.rdvState.service?.rightqrdv_service_condition?.redirect_to_service) {
            dispatch({type: 'SHOW_RAVIP_MODAL', payload: true});
        } else {

            dispatch({type: 'WAITING_AVAILABILITY', payload: true});
            if(props.rdvState.companyCanByassJoinQueuePage && props.rdvState.canTakeGroupAvailability) {
                history.push('/' + props.rdvState.company?.apikey + '/rdv-group-availability')
            } else if(props.rdvState.companyCanByassJoinQueuePage && !props.rdvState.canTakeGroupAvailability) {
                history.push('/' + props.rdvState.company?.apikey + '/rdv-availability')
            }
        }
        
    };

    const handleUserNumberSelect = (e) => {
        if (e.target.value.length > 0) {
            //check is number
            if(e.target.value.match(/^\d+$/) !== null ) {
            setUserNumberValid(true);
            setUserNumberInvalid(false);
            setSelectedUserNumber(e.target.value)
            setUserNumberErrMsg(null)
            } else {
            setUserNumberErrMsg(t("user_number_invalid"));
            setUserNumberValid(false);
            setUserNumberInvalid(true);
            setSelectedUserNumber(null)
            }
        } else {
            setUserNumberErrMsg(t("user_number_invalid"));
            setUserNumberValid(false);
            setUserNumberInvalid(true);
            setSelectedUserNumber(null)
        }
    };

    useEffect(() => {
        const en_user_numbers = []
        const fr_user_numbers = []

        let en_item = {}
        let fr_item = {}

        for (let i = 1; i <= props.rdvState.userNumberLimit; i++) {
            if(i == 1) {
                en_item = {'val': i, 'name': `${i} person`}
                fr_item = {'val': i, 'name': `${i} personne`}
            } else {
                en_item = {'val': i, 'name': `${i} people`}
                fr_item = {'val': i, 'name': `${i} personnes`}
            }
            en_user_numbers.push(en_item)
            fr_user_numbers.push(fr_item)
        }

        props.rdvState.currentLang == 'en' ? setUserNumbers(en_user_numbers) : setUserNumbers(fr_user_numbers) 

    }, [props.rdvState.currentLang])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && props.rdvState.ravipModalTitle) {
            setRavipModalTitle(props.rdvState.ravipModalTitle)
        }
    }, [props.rdvState.ravipModalTitle])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && props.rdvState.ravipModalLabel) {
            setRavipModalLabel(props.rdvState.ravipModalLabel)
        }
    }, [props.rdvState.ravipModalLabel])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && props.rdvState.ravipModalDescription) {
            setRavipModalDescription(props.rdvState.ravipModalDescription)
        }
    }, [props.rdvState.ravipModalDescription])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && props.rdvState.showUserNumberModal) {
            handleShowModal()
        }
      }, [props.rdvState.companyCanByassJoinQueuePage, props.rdvState.showUserNumberModal])

    useEffect(() => {
    if (props.rdvState.companyCanByassJoinQueuePage && 
        props.rdvState.showRavipModal && 
        props.rdvState.userNumber) {
        handleShowRavipModal()
    }
    }, [props.rdvState.companyCanByassJoinQueuePage, 
        props.rdvState.showRavipModal])

    useEffect(() => {
        if (props.rdvState.companyCanByassJoinQueuePage && !props.rdvState.isRdvLoad) {
          history.push('/' + apiKey +'/rdv-start')
        }
      }, [props.rdvState.companyCanByassJoinQueuePage])

    useEffect(() => {
        if (props.rdvState.branch !== null) {
            dispatch({type: 'IS_LOADING', payload: true});
            props.getServices(props.rdvState.branch)
        } else goBack()

        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (props.rdvState.services == [] && props.rdvState.serviceClosed === null) {
            setCanShowNoAgentPage(true)
        }
      }, [props.rdvState.services])

    const compare = (a, b) => {
        if(a.order){
            return a.order - b.order;
        }
            
        
        if (a.translate) {
            if (a.translate[i18next.language] < b.translate[i18next.language]) {
                return -1;
            }
            if (a.translate[i18next.language] > b.translate[i18next.language]) {
                return 1;
            }
        }
        if (a.name) {
            if (a.name[i18next.language] < b.name[i18next.language]) {
                return -1;
            }
            if (a.name[i18next.language] > b.name[i18next.language]) {
                return 1;
            }
        }
        //return 0;
    }
    let val_isOrange = props.rdvState.company?.color_scheme.isOrange;


//props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : '#808080'



const BackIcon = () => (<svg className={val_isOrange === 1 ? 'orangeSvg_1' : ''} xmlns="http://www.w3.org/2000/svg" width="16" height="12.162" viewBox="0 0 16 12.162">
<g id="Group_1866" data-name="Group 1866" transform="translate(1 1.413)">
    <path id="Path_891" data-name="Path 891" d="M5,9.335,0,4.661,5,0" fill="none" 
    stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : props.rdvState.company?.apikey === "Z2tKcTlRYnNOMlNmY0M2VWM0TGNsZz09" ? '#808080': '#FFFFFF'}
          strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <line id="Line_262" data-name="Line 262" x2="13.809" transform="translate(0.191 4.774)" fill="none"
          stroke={props.rdvState.companyCanByassJoinQueuePage ? '#808080' : props.rdvState.company?.color_scheme.secondary_color ? props.rdvState.company?.color_scheme.secondary_color : props.rdvState.company?.apikey === "Z2tKcTlRYnNOMlNmY0M2VWM0TGNsZz09" ? '#808080': '#FFFFFF'} strokeLinecap="round" strokeWidth="2"/>
</g>
</svg>)



    return <div className={'ServiceSelection'} id={'ServiceSelection'}>
        <div>
            <div hidden={val_isOrange === 1 } className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
                <Row className={'r'}>
                    <Col sm={12} lg={2} md={2}>
                        <button hidden={props.rdvState?.back_button == 1 } className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/> &nbsp;{t('back')}
                        </button>
                    </Col>
                    <Col sm={12} lg={8} md={8} className={'p-2'}>
                        <span hidden={props.rdvState.operation === 'appointment'}
                              className={val_isOrange === 1 ? 'orange-body agenceNameOrange agenceName' : 'agenceName'}>{t('welcome_to')} <strong>{props.rdvState.branch?.Name}</strong></span>
                    </Col>
                </Row>
            </div>

    <Row hidden={val_isOrange !== 1 } className={val_isOrange === 1 ? 'serviceTop orange-service-header':'serviceTop'}>
      <Col sm={2} lg={2} md={2}>
        <button className={val_isOrange === 1 ? 'orange-body orange-btn-white buttonDefault back' : 'buttonDefault back'} onClick={goBack}><BackIcon/>&nbsp;{t('back')}</button>
      </Col>
                    <Col sm={12} lg={8} md={8} className={'p-2'}>
                        <span hidden={props.rdvState.operation === 'appointment'}
                              className={val_isOrange === 1 ? 'orange-body agenceNameOrange agenceName' : 'agenceName'}>{t('welcome_to')} <strong>{props.rdvState.branch?.Name}</strong></span>
                    </Col>
    </Row>







            <div className={'serviceBox align-items-center justify-content-between'}>
                <Row>
                    <Col sm={5} className={'pt-4'}>
                        <div hidden={props.rdvState.operation === 'appointment'}>
                            {props.rdvState.serviceClosed === null ? (
                                <span hidden={props.rdvState.services.length <= 0}
                                className={val_isOrange === 1 ? 'orange-body orange-black clickMessage' : 'clickMessage'}>{t('click_sevice')}</span>
                            ) : ('')}
                        </div>
                        <div hidden={props.rdvState.operation === 'currentqueue'}>
                            <span className={val_isOrange === 1 ? 'orange-body orange-black clickMessage' : 'clickMessage'}>{t('our_services')}</span>
                            <p className={val_isOrange === 1 ? 'orange-body orange-black appointReason' : 'appointReason'}>{t('our_services_msg')}</p>
                        </div>
                    </Col>
                    <Col></Col>
                    <Col sm={7} md={4}>
                        <div className={'ml-auto'}
                             hidden={props.rdvState.operation === 'appointment' || props.rdvState.services.length <= 0}>
                            {props.rdvState.serviceClosed === null ? (
                                <span className={val_isOrange === 1 ? 'orange-body orange-black classMessage' : 'classMessage'}>{t('queue_long_msg')}</span>
                            ) : (
                                <span className={val_isOrange === 1 ? 'orange-body orange-black classMessage' : 'classMessage'}>{t('please_try_branch')}</span>
                            )}

                            <BranchSelector fetchServices={props.getServices} customPlaceholder={t('select_another_branch')}/>
                        </div>
                        <div hidden={!props.rdvState.serviceClosed}>
                            <span className={val_isOrange === 1 ? 'orange-body orange-black classMessage' : 'classMessage'}>{t('please_try_branch')}</span>
                            <BranchSelector fetchServices={props.getServices} customPlaceholder={t('select_another_branch')}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <div className={val_isOrange === 1 ? 'serviceList serviceListOrange':'serviceList'}>
            <Row>
                {!props.rdvState.companyCanByassJoinQueuePage && props.rdvState.services.sort(compare).map((s) => (
                    <Col key={s.id} sm={6} lg={4}><BranchService service={s}/></Col>))}

                {
                    props.rdvState.companyCanByassJoinQueuePage && props.rdvState.services.sort(compare).map((s) => {
                        return (<Col key={s.id} sm={6} lg={4}>
                            <RdvBranchService service={s}/>
                        </Col>) 
                    })
                }  
                
                {props.rdvState.serviceClosed !== null ? (<ServiceClosed/>) : ('')}
                {(canShowNoAgentPage) ? (<NoAgent/>) : ('')}
            </Row>
        </div>

        <Modal size="md" show={showModal} onHide={handleCloseModal}>
            <Modal.Header>
            <Modal.Title as='h6'>{t('user_number_modal_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='usernumbermodal'>
                {<Form.Group controlId="formCountry">
                <Form.Label>
                {t('user_number_modal_subtitle')}
                <span
                    title={t("required")}
                    style={{ color: "#b30000" }}
                    >
                    *
                    </span>
                </Form.Label>
                <Form.Control as="select" size="lg" style={{
                    borderRadius: val_isOrange === 1 ? 0 : "",
                    height: val_isOrange === 1 ? 40 : 51,
                }}
                isValid={userNumberValid}
                isInvalid={userNumberInvalid}
                onChange={handleUserNumberSelect} value={selectedUserNumber ? selectedUserNumber : ''}>
                <option value="" disabled>{t('select_placeholder')}</option>
                {userNumbers.map((item, index) => (
                    <option key={index} value={item.val}>
                    {item.name}
                    </option>
                ))}
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                {userNumberErrMsg}
                </Form.Control.Feedback>
            </Form.Group>}

            <small className='text-info text-italic'>{t('user_number_modal_info')}</small>

            </Modal.Body>
            <Modal.Footer>
            <Button className='buttonDefaultRdv'
            onClick={handleCloseModal}>
                {t('close_order_number_modal')}
            </Button>
            <Button className='buttonActiveRdv'
            disabled={!userNumberValid} onClick={handleSubmitModal}>
                {t('next')}
            </Button>
            </Modal.Footer>
        </Modal>


        <Modal size="md" show={showRavipModal} onHide={handleCloseRavipModal}>
            <Modal.Header>
            <Modal.Title as='h6'>{ravipModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {<Form.Group controlId="formCountry">
                <Form.Label>
                {ravipModalLabel}
                <span
                    title={t("required")}
                    style={{ color: "#b30000" }}
                    >
                    *
                    </span>
                </Form.Label>
                <Form.Control as="select" size="lg" style={{
                borderRadius: val_isOrange === 1 ? 0 : "",
                height: val_isOrange === 1 ? 40 : 51,
                }}
                isValid={userNumberValid}
                isInvalid={userNumberInvalid}
                onChange={handleRavipSelect} value={selectedRavip ? selectedRavip.code : ''}>
                <option value="" disabled>{t('ravip_modal_placeholder')}</option>
                {ravipItems.map((item, index) => (
                    <option key={index} value={item.code}>
                    {item.val}
                    </option>
                ))}
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                {userNumberErrMsg}
                </Form.Control.Feedback>
            </Form.Group>}

            <small className='text-info text-italic'>{ravipModalDescription}</small>

            </Modal.Body>
            <Modal.Footer>
            <Button className='buttonDefaultRdv'
            onClick={handleCloseRavipModal}>
                {t('close_order_number_modal')}
            </Button>
            <Button className='buttonActiveRdv'
            disabled={!ravipValid} onClick={handleSubmitRavipModal}>
                {t('next')}
            </Button>
            </Modal.Footer>
        </Modal>
        
        

    </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RdvServiceSelection);
